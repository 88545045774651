import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import { accountManagerMenuItems } from '../../../utils/constants';
import { fetchClientDetailsRequest, listClientsRequest, resetClientUserPasswordRequest, updateClientGeneralInformationRequest, updateClientInvestorListRequest, updateClientProxyAdvisorsRequest, updateClientSubscriptionDetailsRequest, updateClientUserManagementRequest, uploadClientProfilePictureRequest } from '../../../requests/client-requests';
import { listSquareWellInvestorsRequest, listSquareWellProxyAdvisorsRequest } from '../../../requests/squarewell-requests';
import { getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { handleRequestError } from '../../../utils/request-error-helpers';
import { getAuthUserProfilePictureUrl } from '../../../utils/auth';
import useHttp from '../../../hooks/use-http';

import Breadcrumbs from '../../../components/UI/Breadcrumbs/Breadcrumbs';
import SearchBox from '../../../components/UI/SearchBox/SearchBox';
import SideNavigationBar from '../../../components/UI/SideNavigationBar/SideNavigationBar';
import Tab from '../../../components/UI/Tab/Tab';

import ClientDetailsGeneralInfo from './ClientDetailsGeneralInfo/ClientDetailsGeneralInfo';
import ClientDetailsSubscriptionDetails from './ClientDetailsSubscriptionDetails/ClientDetailsSubscriptionDetails';
import ClientDetailsProxyAdvisors from './ClientDetailsProxyAdvisors/ClientDetailsProxyAdvisors';
import ClientDetailsInvestorList from './ClientDetailsInvestorList/ClientDetailsInvestorList';
import ClientDetailsUserManagement from './ClientDetailsUserManagement/ClientDetailsUserManagement';

import SquareWellProfilePicture from '../../../assets/other/squarewell-profile-picture.png';
import styles from './ClientDetailsPage.module.css';

const ClientDetailsPage = (props) => {
  const { clientId } = useParams();

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  /* eslint-disable no-unused-vars */

  const userProfilePictureUrl = getAuthUserProfilePictureUrl();

  const tabItems = [
    {
      'id': 'tab-general-info',
      'text': 'General Info'
    },
    {
      'id': 'tab-subscription-details',
      'text': 'Subscription Details'
    },
    {
      'id': 'tab-user-management',
      'text': 'User Management'
    },
    {
      'id': 'tab-proxy-advisors',
      'text': 'Proxy Advisors & Ratings'
    },
    {
      'id': 'tab-investor-list',
      'text': 'Investor List'
    }
  ];

  const [isLoading, error, sendRequest] = useHttp();
  const [squareWellInvestors, setSquareWellInvestors] = useState([]);
  const [squareWellProxyAdvisors, setSquareWellProxyAdvisors] = useState([]);
  const [searchClientItems, setSearchClientItems] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [selectedTab, setSelectedTab] = useState('tab-general-info');
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const [clientData, setClientData] = useState(null);

  const clientDetailsTabChangeHandler = (selectedTabId) => {
    setSelectedTab(selectedTabId)
  }

  useEffect(() => {
    const handleListSquareWellInvestorsResponse = (data) => {
      setSquareWellInvestors(data['items']);
    }
    sendRequest(
      listSquareWellInvestorsRequest(),
      handleListSquareWellInvestorsResponse
    );

    const handleListSquareWellProxyAdvisorsResponse = (data) => {
      setSquareWellProxyAdvisors(data['items']);
    }
    sendRequest(
      listSquareWellProxyAdvisorsRequest(),
      handleListSquareWellProxyAdvisorsResponse
    );    

    sendRequest(
      listClientsRequest(),
      (data) => {
        const newSearchItems = data['items'].map(item => {
          return {
            'id': `${item['id']}`,
            'text': item['company_information']['name']
          }
        });
        setSearchClientItems(newSearchItems);
      }
    );
  }, [sendRequest]);
  

  useEffect(() => {
    const queryParams = {
      'include_details': true
    };
    const handleFetchClientDetailsResponse = (data) => {
      setClientData(data);
      setBreadcrumbItems([
        {
          'text': 'My Clients',
          'href': ''
        },
        {
          'text': data['company_information']['name'],
          'href': ''
        }
      ]);
    }
    sendRequest(
      fetchClientDetailsRequest(clientId, queryParams),
      handleFetchClientDetailsResponse
    );
  }, [sendRequest, clientId]);

  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const selectClientSearchResultHandler = (selectedItemId) => {
    window.location.href = `./${selectedItemId}`;
  }


  /* Upload profile picture for the client. */
  const uploadClienProfilePictureHandler = (profilePictureData, setProfilePictureUrl) => {
    sendRequest(
      uploadClientProfilePictureRequest(clientId, profilePictureData),
      (data) => { setProfilePictureUrl(data['profile_picture_url']); }
    );
  }


  /* Save updated general information and main contact information for the client. */
  const generalInformationSaveHandler = (updatedCompanyInformationData, updatedMainContactData, updatedModuleManagementData) => {
    const body = {
      'company_information': updatedCompanyInformationData,
      'main_contact': updatedMainContactData,
      'module_management': updatedModuleManagementData
    };
    sendRequest(
      updateClientGeneralInformationRequest(clientId, body),
      (data) => { setClientData(data); }
    );
  }

  /* Save updated subscription details for the client. */
  const subscriptionDetailsSaveHandler = (updatedSubscriptionDetailsData) => {
    const body = {
      'subscription_details': updatedSubscriptionDetailsData
    };
    sendRequest(
      updateClientSubscriptionDetailsRequest(clientId, body),
      (data) => { setClientData(data); }
    );
  }

  /* Update user list for the client. */
  const userManagementSaveHandler = (updatedUserManagementData) => {
    const body = {
      'users': updatedUserManagementData
    };
    sendRequest(
      updateClientUserManagementRequest(clientId, body),
      (data) => { setClientData(data); }
    );
  }

   /* Send a new password reset email. */
   const sendResetPasswordEmailHandler = (email) => {
    const body = {
      'email': email
    };
    sendRequest(
      resetClientUserPasswordRequest(clientId, 1, body),
      () => {}
    );
  }


  /* Update proxy advisors wip list for the client. */
  const proxyAdvisorsSaveHandler = (updatedProxyAdvisorsData, isActivated) => {
    const proxyAdvisorsObject = {
      'items': updatedProxyAdvisorsData,
      'is_activated': isActivated
    };
    const body = {
      'proxy_advisors': proxyAdvisorsObject
    };
    sendRequest(
      updateClientProxyAdvisorsRequest(clientId, body),
      (data) => { setClientData(data); }
    );
  }


  /* Update proxy advisors wip list for the client. */
  const investorListSaveHandler = (updatedInvestorListData, isActivated) => {
    const investorListObject = {
      'items': updatedInvestorListData,
      'is_activated': isActivated
    };
    const body = {
      'investors': investorListObject
    };
    sendRequest(
      updateClientInvestorListRequest(clientId, body),
      (data) => { setClientData(data); }
    );
  }


  return (
    <div className={styles['page']}>
      <SideNavigationBar
        menuItems={accountManagerMenuItems}
        profilePictureUrl={userProfilePictureUrl === '' ? SquareWellProfilePicture : userProfilePictureUrl}
        selected='my-clients'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div className={`${styles['container']} ${styles[pageStyle]}`} style={{height: 'calc(100% - 96px)', overflowY: 'auto', overflowX: 'hidden'}} ref={ref}>
        <Breadcrumbs items={breadcrumbItems} />

        { clientData !== null &&
          <div className={styles['client-details-container']}>
            <div className={styles['client-details-header']}>
              <div
                className={styles['logo']}
                style={{backgroundImage:`url("${clientData['company_information']['profile_picture_url']}")`}}
              ></div>
              <div className={styles['title']}>
                <h1>{clientData['company_information']['name']}</h1>
              </div>
              <div className={styles['search-box']}>
                <SearchBox
                  items={searchClientItems}
                  value={searchValue}
                  placeholder="Search Clients"
                  onChange={setSearchValue}
                  onItemSelect={selectClientSearchResultHandler}
                  size={"xsmall"}
                />
              </div>
            </div>

            <Tab items={tabItems} selectedTab={selectedTab} onTabChange={clientDetailsTabChangeHandler} />

            <div className={styles['client-details-body']}>
              {selectedTab === 'tab-general-info' &&
                <ClientDetailsGeneralInfo
                  generalInformationData={clientData['company_information']}
                  subscriptionDetailsData={clientData['subscription_details']}
                  moduleManagementData={clientData['module_management']}
                  mainContactData={clientData['main_contact']}
                  onSaveClick={generalInformationSaveHandler}
                  onProfilePictureUploadClick={uploadClienProfilePictureHandler}
                />
              }
              {selectedTab === 'tab-subscription-details' &&
                <ClientDetailsSubscriptionDetails
                  subscriptionDetailsData={clientData['subscription_details']}
                  onSaveClick={subscriptionDetailsSaveHandler}
                />
              }
              {selectedTab === 'tab-user-management' &&
                <ClientDetailsUserManagement
                  pageWidth={width}
                  userListData={clientData['users']}
                  numberOfUsers={clientData['subscription_details']['number_of_users']}
                  onSaveClick={userManagementSaveHandler}
                  onResetPasswordClick={sendResetPasswordEmailHandler}
                />
              }
              {selectedTab === 'tab-proxy-advisors' &&
                <ClientDetailsProxyAdvisors
                  pageWidth={width}
                  clientType={clientData['company_information']['client_type']}
                  proxyAdvisorsData={clientData['proxy_advisors_wip']}
                  squareWellProxyAdvisors={squareWellProxyAdvisors}
                  onSaveClick={proxyAdvisorsSaveHandler}
                />
              }
              {selectedTab === 'tab-investor-list' &&
                <ClientDetailsInvestorList
                  pageWidth={width}
                  clientName={clientData['company_information']['name']}
                  clientType={clientData['company_information']['client_type']}
                  investorListData={clientData['investors_wip']}
                  squareWellInvestors={squareWellInvestors}
                  totalNumOfShares={clientData['company_information']['total_num_of_shares']}
                  numberOfInvestors={clientData['subscription_details']['number_of_investors']}
                  onSaveClick={investorListSaveHandler}
                />
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default ClientDetailsPage;

import { useCallback, useEffect, useState } from "react";
import { generateClientInvestorContactListTableItems } from "../../../../utils/client-investor-helpers";
import { createClientInvestorContactsRequest, deleteClientInvestorContactRequest, listClientInvestorContactsRequest, updateClientInvestorContactRequest, uploadClientInvestorContactProfilePictureRequest } from "../../../../requests/client-requests";
import { getAuthClientId } from "../../../../utils/auth";
import { handleRequestError } from "../../../../utils/request-error-helpers";
import useHttp from "../../../../hooks/use-http";
import sortArray from 'sort-array';

import Button from "../../../../components/UI/Button/Button";
import Table from "../../../../components/UI/Table/Table";
import InvestorContactForm from "../../../../components/InvestorContactForm/InvestorContactForm";

import styles from './InvestorContactList.module.css';

const InvestorContactList = (props) => {
  const [isLoading, error, sendRequest] = useHttp();

  const [tableSortConfig, setTableSortConfig] = useState({
    'by': ['fullname'],
    'order': ['asc']
  });

  const [investorContactListData, setInvestorContactListData] = useState([]);
  const [tableItems, setTableItems] = useState(null);

  const [showContactForm, setShowContactForm] = useState(false);
  const [contactFormType, setContactFormType] = useState('new');
  const [selectedContact, setSelectedContact] = useState(null);

  const clientId = getAuthClientId();
  const investorId = props.investorId;
  const isViewOnly = props.isViewOnly;
  const isPrimaryEditor = props.isPrimaryEditor;

  const investorContactListSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  const newContactFormHandler = () => {
    setShowContactForm(true);
    setContactFormType('new');
  }


  const contactClickHandler = useCallback((clickedItemId) => {
    const selectedContactId = clickedItemId.split('client-investor-contact-')[1].split('#')[0].toString();
    setSelectedContact(investorContactListData.filter(item => item['id'].toString() === selectedContactId)[0]);
    setShowContactForm(true);
    setContactFormType('edit');
  }, [investorContactListData])


  const pinClickHandler = useCallback((clickedItemId) => {
    const selectedContactId = clickedItemId.split('client-investor-contact-')[1].split('#')[0].toString();
      setInvestorContactListData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        const clickedItem = items.filter(item => item['id'].toString() === selectedContactId)[0];
        if (clickedItem !== undefined) {
          const body = {
            'aqtion_information': {
              'is_starred': !clickedItem['is_starred']
            }
          };
          sendRequest(
            updateClientInvestorContactRequest(clientId, investorId, selectedContactId, body),
            (_) => { }
          );
          clickedItem['is_starred'] = !clickedItem['is_starred'];
        }
        return items;
      });
  }, [clientId, investorId, sendRequest]);


   /* Upload profile picture for the investor contact. */
   const uploadClientInvestorContactProfilePictureHandler = (profilePictureData, setProfilePictureUrl) => {
    let selectedContactId;
    if(selectedContact !== null) {
      selectedContactId = selectedContact['id'];
    }
    else {
      selectedContactId = 'temp'
    }
    sendRequest(
      uploadClientInvestorContactProfilePictureRequest(clientId, investorId, selectedContactId, profilePictureData),
      (data) => { setProfilePictureUrl(data['profile_picture_url']); }
    );
  }


  const clientInvestorContactCreateHandler = (contactGeneralInformationData, contactAqtionInformationData) => {
    contactAqtionInformationData['is_starred'] = false;
    const body = {
      'general_information': contactGeneralInformationData,
      'aqtion_information': contactAqtionInformationData
    };
    const createNewClientInvestorContactResponse = (data) => {
      setInvestorContactListData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        items.push(data)
        return items;
      });
      setShowContactForm(false);
    }
    sendRequest(
      createClientInvestorContactsRequest(clientId, investorId, body),
      createNewClientInvestorContactResponse
    );
  }

  const clientInvestorContactEditHandler = (contactGeneralInformationData, contactAqtionInformationData) => {
    const selectedContactId = selectedContact['id'];
    const body = {
      'general_information': contactGeneralInformationData,
      'aqtion_information': contactAqtionInformationData
    };

    const updateClientInvestorContactResponse = (data) => {
      setInvestorContactListData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        if(selectedContact !== null && selectedContact['is_custom']) {
          const contactIndex = items.findIndex(item => item['id'] === selectedContact['id']);
          items[contactIndex] = data;
        }
        else if(selectedContact !== null && !selectedContact['is_custom']) {
          const contactIndex = items.findIndex(item => item['id'] === selectedContact['id']);
          items[contactIndex]['relationship_status'] = data['relationship_status'];
          items[contactIndex]['notes'] = data['notes'];
          items[contactIndex]['is_starred'] = data['is_starred'];
        }
        return items;
      });
      setShowContactForm(false);
    }
    sendRequest(
      updateClientInvestorContactRequest(clientId, investorId, selectedContactId, body),
      updateClientInvestorContactResponse
    );
  }


  const clientInvestorContactDeleteHandler = () => {
    const selectedContactId = selectedContact['id'];

    const deleteClientInvestorContactResponse = (res) => {
      setInvestorContactListData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        if(selectedContact !== null && selectedContact['is_custom']) {
          const contactIndex = items.findIndex(item => item['custom_investor_contact_id'] === selectedContact['custom_investor_contact_id']);
          items.splice(contactIndex, 1);
        }
        return items;
      });
      setShowContactForm(false);
    }
    sendRequest(
      deleteClientInvestorContactRequest(clientId, investorId, selectedContactId),
      deleteClientInvestorContactResponse
    );
  }


  useEffect(() => {
    sendRequest(
      listClientInvestorContactsRequest(clientId, investorId),
      (data) => { setInvestorContactListData(data['items']); }
    );
  }, [sendRequest, clientId, investorId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  useEffect(() => {
    setInvestorContactListData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, tableSortConfig);
    });
  }, [tableSortConfig]);

  useEffect(() => {
    setTableItems(generateClientInvestorContactListTableItems(
      investorContactListData, tableSortConfig, pinClickHandler, 
      contactClickHandler, investorContactListSortHandler, isViewOnly
    ));
  }, [investorContactListData, tableSortConfig, pinClickHandler, contactClickHandler, isViewOnly]);

  return (
    <div className={styles['investor-contact-list']}>
      { isPrimaryEditor && 
        <div className={styles['investor-contact-list-buttons']}>
          <div className={styles['right-buttons']}>
            <Button type={'primary'} size={'xsmall'} leftIcon={'plus'} onClick={newContactFormHandler}>Add New Contact</Button>
          </div>
        </div>
      }
      { !isPrimaryEditor &&
        <div className={styles['investor-contact-list-empty-button-div']}></div>
      }

      { tableItems &&
        <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={tableItems} isScrollable={true} hasDynamicScroll={true} />
      }

      { showContactForm && contactFormType === 'new' &&
        <InvestorContactForm
          type='new'
          onSaveClick={clientInvestorContactCreateHandler}
          onProfilePictureUploadClick={uploadClientInvestorContactProfilePictureHandler}
          onCancelClick={() => setShowContactForm(false)}
          isViewOnly={isViewOnly}
        />
      }

      { showContactForm && contactFormType === 'edit' &&
        <InvestorContactForm
          type='edit'
          contactInfo={selectedContact}
          onSaveClick={clientInvestorContactEditHandler}
          onProfilePictureUploadClick={uploadClientInvestorContactProfilePictureHandler}
          onDeleteClick={clientInvestorContactDeleteHandler}
          onCancelClick={() => setShowContactForm(false)}
          isViewOnly={isViewOnly}
        />
      }
    </div>
  );
}

export default InvestorContactList;

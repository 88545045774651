import { Fragment, useCallback, useEffect, useState } from "react";
import { generateClientInvestorsOtherNotesTableItems, generateClientProxyAdvisorsOtherNotesTableItems } from "../../../../utils/client-engagement-helpers";
import { createClientEngagementsOtherNoteRequest, listClientEngagementsOtherNotesRequest, updateClientEngagementsOtherNoteRequest, uploadClientEngagementsOtherNoteAttachmentRequest } from "../../../../requests/client-engagement-requests";
import { getAuthClientId } from "../../../../utils/auth";
import { useSearchParams } from "react-router-dom";
import { handleRequestError } from "../../../../utils/request-error-helpers";
import useHttp from "../../../../hooks/use-http";
import sortArray from 'sort-array';

import Button from "../../../../components/UI/Button/Button";
import Table from "../../../../components/UI/Table/Table";

import EngagementOtherNoteCreateForm from "../../../../components/EngagementForm/EngagementOtherNoteForm/EngagementOtherNoteCreateForm";
import EngagementOtherNoteUpdateForm from "../../../../components/EngagementForm/EngagementOtherNoteForm/EngagementOtherNoteUpdateForm";

import styles from '../EngagementListPage.module.css';

const EngagementsOtherNotes = (props) => {

  const clientId = getAuthClientId();
  const pageType = props.pageType;
  const setPageType = props.setPageType;
  const isViewOnly = props.isViewOnly;
  const isPrimaryEditor = props.isPrimaryEditor;
  
  const [isLoading, error, sendRequest] = useHttp();
  const [searchParams, setSearchParams] = useSearchParams();

  const [investorsOtherNotesData, setInvestorsOtherNotesData] = useState([]);
  const [investorsOtherNotesTableItems, setInvestorsOtherNotesTableItems] = useState(null);

  const [proxyAdvisorsOtherNotesData, setProxyAdvisorsOtherNotesData] = useState([]);
  const [proxyAdvisorsOtherNotesTableItems, setProxyAdvisorsOtherNotesTableItems] = useState(null);

  const [selectedOtherNoteId, setSelectedOtherNoteId] = useState(searchParams.get('other_note_id'));

  let searchParamsType = null;
  if (searchParams.get('type') === 'investor') {
    searchParamsType = 'type-investor'
  }
  else if (searchParams.get('type') === 'proxy-advisor') {
    searchParamsType = 'type-proxy-advisor'
  }
  const [selectedOtherNoteInvestorType, setSelectedOtherNoteInvestorType] = useState(searchParamsType);

  const [investorEngagementsOtherNotesTableSortConfig, setInvestorEngagementsOtherNotesTableSortConfig] = useState({
    'by': ['note_header'],
    'order': ['asc'],
    'computed': {
      'investor_name': other_note => other_note['investor']['name']
    }
  });

  const investorEngagementsOtherNotesSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setInvestorEngagementsOtherNotesTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setInvestorsOtherNotesData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, investorEngagementsOtherNotesTableSortConfig);
    });
  }, [investorEngagementsOtherNotesTableSortConfig]);

  const [proxyAdvisorEngagementsOtherNotesTableSortConfig, setProxyAdvisorEngagementsOtherNotesTableSortConfig] = useState({
    'by': ['note_header'],
    'order': ['asc'],
    'computed': {
      'proxy_advisor_name': other_note => other_note['proxy_advisor']['name']
    }
  });

  const proxyAdvisorEngagementsOtherNotesSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setProxyAdvisorEngagementsOtherNotesTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setProxyAdvisorsOtherNotesData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, proxyAdvisorEngagementsOtherNotesTableSortConfig);
    });
  }, [proxyAdvisorEngagementsOtherNotesTableSortConfig]);

  
  const investorClickHandler = (investorId) => {
    window.location.href = `../investors/${investorId}`;
  }

  const proxyAdvisorClickHandler = (proxyAdvisorId) => {
    window.location.href = `../proxy-advisors/${proxyAdvisorId}`;
  }

  /* Show other note details. */
  const otherNoteClickHandler = useCallback((otherNoteId, otherNoteInvestorType) => {
    let searchParamsType = '';
    if (otherNoteInvestorType === 'type-investor') {
      searchParamsType = 'investor'
    }
    else if (otherNoteInvestorType === 'type-proxy-advisor') {
      searchParamsType = 'proxy-advisor'
    }
    setSearchParams({'other_note_id' : otherNoteId, 'type': searchParamsType});
    setPageType('edit');
    setSelectedOtherNoteId(otherNoteId);
    setSelectedOtherNoteInvestorType(otherNoteInvestorType);
  }, [setPageType, setSearchParams]);

  /* Upload attachment for the other note. */
  const uploadClientOtherNoteAttachmentHandler = (attachmentData, setAttachments) => {
    const otherNoteId = selectedOtherNoteId !== null ? selectedOtherNoteId : 'temp';
    sendRequest(
      uploadClientEngagementsOtherNoteAttachmentRequest(clientId, otherNoteId, attachmentData),
      (data) => { 
        setAttachments(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          items.push(data)
          return items;
        });
      }
    );
  }

  /* Create new other note. */
  const clientOtherNoteCreateHandler = (otherNoteData) => {
    const createClientEngagementsOtherNoteResponse = (data) => {
      if (data['investor_type'] === 'Investor') {
        setInvestorsOtherNotesData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          items.push(data)
          return items;
        });
      }
      else if (data['investor_type'] === 'Proxy Advisor') {
        setProxyAdvisorsOtherNotesData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          items.push(data)
          return items;
        });
      }
      setPageType('list');
    }
    sendRequest(
      createClientEngagementsOtherNoteRequest(clientId, otherNoteData),
      createClientEngagementsOtherNoteResponse
    );
  }

  /* Update selected other note. */
  const clientOtherNoteEditHandler = (otherNoteData, setOtherNoteData) => {
    const updateClientEngagementsOtherNoteResponse = (data) => {
      if (data['investor_type'] === 'Investor') {
        setInvestorsOtherNotesData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          const otherNoteIndex = items.findIndex(item => item['id'] === parseInt(selectedOtherNoteId));
          items[otherNoteIndex] = data;
          return items;
        });
      }
      else if (data['investor_type'] === 'Proxy Advisor') {
        setProxyAdvisorsOtherNotesData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          const otherNoteIndex = items.findIndex(item => item['id'] === parseInt(selectedOtherNoteId));
          items[otherNoteIndex] = data;
          return items;
        });
      }
      setOtherNoteData(data);
    }
    sendRequest(
      updateClientEngagementsOtherNoteRequest(clientId, selectedOtherNoteId, otherNoteData),
      updateClientEngagementsOtherNoteResponse
    );
  }

  /* Return back to other notes table. */
  const returnToOtherNotesClickHandler = () => {
    setSelectedOtherNoteId(null);
    setSelectedOtherNoteInvestorType(null);
    setPageType('list');
  }

  
  /* Send a request to fetch other note items and set the list with the response. */
  useEffect(() => {
    sendRequest(
      listClientEngagementsOtherNotesRequest(clientId),
      (data) => { 
        setInvestorsOtherNotesData(data['items'].filter(item => item['investor_type'] === 'Investor'));
        setProxyAdvisorsOtherNotesData(data['items'].filter(item => item['investor_type'] === 'Proxy Advisor'));
      }
    );
  }, [sendRequest, clientId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  /* Generate investor other notes table and proxy advisors other notes table. */
  useEffect(() => {
    setInvestorsOtherNotesTableItems(
      generateClientInvestorsOtherNotesTableItems(
        investorsOtherNotesData, otherNoteClickHandler, investorClickHandler, investorEngagementsOtherNotesTableSortConfig, investorEngagementsOtherNotesSortHandler
      )
    );
  }, [investorsOtherNotesData, investorEngagementsOtherNotesTableSortConfig, otherNoteClickHandler]);

  /* */
  useEffect(() => {
    setProxyAdvisorsOtherNotesTableItems(
      generateClientProxyAdvisorsOtherNotesTableItems(
        proxyAdvisorsOtherNotesData, otherNoteClickHandler, proxyAdvisorClickHandler , proxyAdvisorEngagementsOtherNotesTableSortConfig, proxyAdvisorEngagementsOtherNotesSortHandler
      )
    );
  }, [proxyAdvisorsOtherNotesData, proxyAdvisorEngagementsOtherNotesTableSortConfig, otherNoteClickHandler]);


  return (
    <Fragment>
      { !isLoading && pageType === 'list' && proxyAdvisorsOtherNotesTableItems && proxyAdvisorsOtherNotesTableItems['middle']['body']['items'].length > 0 &&
        <div className={styles['engagement-other-note-list-container']}>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={proxyAdvisorsOtherNotesTableItems} isScrollable={false} />
        </div>
      }

      { !isLoading && pageType === 'list' && investorsOtherNotesTableItems && investorsOtherNotesTableItems['middle']['body']['items'].length > 0 &&
        <div className={styles['engagement-other-note-list-container']}>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={investorsOtherNotesTableItems} isScrollable={false} />
        </div>
      }

      { !isLoading && pageType === 'list' && proxyAdvisorsOtherNotesTableItems && proxyAdvisorsOtherNotesTableItems['middle']['body']['items'].length === 0 && investorsOtherNotesTableItems && investorsOtherNotesTableItems['middle']['body']['items'].length === 0 &&
        <div className={styles['no-enagements-div']}>
          <div className={styles['icon-container']}>
            <div className={styles['icon']}></div>
          </div>
          {isPrimaryEditor &&
            <Fragment>
              <div className={styles['text-container']}>
                <h4>You Have No Other Notes</h4>
                <p>Use below button to Create an Other Note</p>
              </div>
              <div className={styles['button-container']}>
                <Button
                  type={"primary"}
                  leftIcon={"plus"}
                  size={"xsmall"}
                  onClick={props.onCreateEngagementClick}>
                    Create Other Note
                </Button>
              </div>
            </Fragment>
          }
          {!isPrimaryEditor &&
            <div className={styles['text-container']}>
              <h4>You Have No Other Notes</h4>
            </div>
          }
        </div>
      }

      { pageType === 'new' &&
        <EngagementOtherNoteCreateForm
          investorType='type-investor'
          isInvestorTypeEditable={true}
          hasFormTitle={true}
          onAttachmentUploadClick={uploadClientOtherNoteAttachmentHandler}
          onSaveClick={clientOtherNoteCreateHandler}
          onCancel={returnToOtherNotesClickHandler}
        />
      }

      { pageType === 'edit' && selectedOtherNoteId &&
        <EngagementOtherNoteUpdateForm
          otherNoteId={selectedOtherNoteId}
          investorType={selectedOtherNoteInvestorType}
          isInvestorTypeEditable={true}
          hasBackButton={false}
          onAttachmentUploadClick={uploadClientOtherNoteAttachmentHandler}
          onSaveClick={clientOtherNoteEditHandler}
          onBackClick={returnToOtherNotesClickHandler}
          setBreadcrumbItems={props.setBreadcrumbItems}
          isViewOnly={isViewOnly}
        />
      }
    </Fragment>
  );
}

export default EngagementsOtherNotes;

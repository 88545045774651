import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { createId, getValueFromDropdown, getValueFromTextInput } from '../../../../utils/helpers';
import { generateClientDetailsShowProxyAdvisorTableItems, generateClientDetailsEditProxyAdvisorTableItems } from '../../../../utils/account-manager-client-details-helpers';

import Table from '../../../../components/UI/Table/Table';
import Button from '../../../../components/UI/Button/Button';

import styles from './ClientDetailsProxyAdvisors.module.css';
import Modal from '../../../../components/UI/Modal/Modal';

const ClientDetailsProxyAdvisors = (props) => {
  const clientType = props.clientType;

  const [isEditMode, setIsEditMode] = useState(false);
  const [tableItems, setTableItems] = useState(generateClientDetailsShowProxyAdvisorTableItems(clientType, props.proxyAdvisorsData['items']));
  const [proxyAdvisorsData, setProxyAdvisorsData] = useState(props.proxyAdvisorsData);
  const [squareWellProxyAdvisors, setSquareWellProxyAdvisors] = useState(props.squareWellProxyAdvisors);
  const [proxyAdvisorsActivateApproval, setProxyAdvisorsActivateApproval] = useState(false);
  const [proxyAdvisorsSaveApproval, setProxyAdvisorsSaveApproval] = useState(false);

  const dropdownProxyAdvisorItems = useMemo(() => squareWellProxyAdvisors.map(item => {
    return {
      'id': item['id'],
      'value': item['name']
    }
  }), [squareWellProxyAdvisors]);


  const createProxyAdvisorRow = useCallback((item, selectProxyAdvisorDropdownItemHandler, deleteRowFunction) => {
    const newItemId = `client-user-${createId(4)}`;
    const proxyAdvisorName = item['proxy_advisor_name'];
    return {
      'id': newItemId,
      'columns': [
        {
          'id': `${newItemId}#proxy-advisor-name`,
          'type': 'dropdown',
          'placeholder': 'Select Proxy Advisor / Rating',
          'selected': proxyAdvisorName,
          'value': proxyAdvisorName,
          'items': dropdownProxyAdvisorItems,
          'onSelected': selectProxyAdvisorDropdownItemHandler,
          'width': 420,
          'stretch': false
        },
        {
          'type': 'empty-cell',
          'stretch': true
        },
        {
          'id': `${newItemId}#delete-button`,
          'icon': 'trash',
          'type': 'icon-button',
          'onClick': deleteRowFunction
        }
      ]
    };
  }, [dropdownProxyAdvisorItems]);


  const updateProxyAdvisorTableItems = useCallback((tempTableItems) => {
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const proxyAdvisorName = getValueFromDropdown(`${itemId}#proxy-advisor-name`);

        const lastAgmRecommendations = '-';
        /*
        if(clientType === 'Corporate') {
          lastAgmRecommendations = getValueFromTextInput(`${itemId}#last-agm-recommendations`);
        }
        */
  
        item['columns'][0]['selected'] = proxyAdvisorName;
        item['columns'][0]['value'] = proxyAdvisorName;
        item['columns'][1]['value'] = lastAgmRecommendations;
      });
    }
    return tempTableItems['middle']['body']['items'];
  }, []);


  const addNewProxyAdvisorRowHandler = useCallback((createProxyAdvisorRow, selectProxyAdvisorDropdownItemHandler, removeProxyAdvisorRowHandler) => () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateProxyAdvisorTableItems(newTableItems);
      newTableItems['middle']['body']['items'].push(
        createProxyAdvisorRow({
          'proxy_advisor_name': '',
          'last_general_meeting_recommendations': ''
        }, selectProxyAdvisorDropdownItemHandler, removeProxyAdvisorRowHandler)
      );
      newTableItems['middle']['header']['items'][0]['columns'][0]['text'] = `PROXY ADVISOR / RATING NAME (${newTableItems['middle']['body']['items'].length})`;
      
      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedDropdownItems = dropdownProxyAdvisorItems.filter(dropdownItem => !selectedItems.includes(dropdownItem['value']) || dropdownItem['value'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedDropdownItems;
      });
      return newTableItems;
    });
  }, [dropdownProxyAdvisorItems, updateProxyAdvisorTableItems]);


  const removeProxyAdvisorRowHandler = useCallback((itemId) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateProxyAdvisorTableItems(newTableItems);

      const itemIndex = newTableItems['middle']['body']['items'].findIndex(item => item['id'] === itemId.split('#')[0]);
      if (itemIndex > -1) {
        newTableItems['middle']['body']['items'].splice(itemIndex, 1);
      }

      newTableItems['middle']['header']['items'][0]['columns'][0]['text'] = `PROXY ADVISOR / RATING NAME (${newTableItems['middle']['body']['items'].length})`;

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedDropdownItems = dropdownProxyAdvisorItems.filter(dropdownItem => !selectedItems.includes(dropdownItem['value']) || dropdownItem['value'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedDropdownItems;
      });
      return newTableItems;
    });
  }, [dropdownProxyAdvisorItems, updateProxyAdvisorTableItems]);


  const selectProxyAdvisorDropdownItemHandler = useCallback((itemId, proxyAdvisorName) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateProxyAdvisorTableItems(newTableItems);

      newTableItems['middle']['body']['items'].filter(item => item['id'] === itemId).forEach(item => {
        item['columns'][0]['value'] = proxyAdvisorName;
        item['columns'][0]['selected'] = proxyAdvisorName;
      });

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedDropdownItems = dropdownProxyAdvisorItems.filter(dropdownItem => !selectedItems.includes(dropdownItem['value']) || dropdownItem['value'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedDropdownItems;
      });

      return newTableItems;
    });
  }, [dropdownProxyAdvisorItems, updateProxyAdvisorTableItems]);


  const fetchProxyAdvisorData = useCallback((tempTableItems) => {
    const data = [];    
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const proxyAdvisorName = getValueFromDropdown(`${itemId}#proxy-advisor-name`);
        
        const lastAgmRecommendations = '';
        /*
        if(clientType === 'Corporate') {
          lastAgmRecommendations = getValueFromTextInput(`${itemId}#last-agm-recommendations`);
        }
        */

        const squareWellItemIndex = squareWellProxyAdvisors.findIndex(item => item['name'] === proxyAdvisorName);
        data.push({
          'squarewell_proxy_advisor_id': squareWellProxyAdvisors[squareWellItemIndex]['id'],
          'proxy_advisor_name': proxyAdvisorName,
          'last_general_meeting_recommendations': lastAgmRecommendations
        });
      });
    }

    return data;
  }, [squareWellProxyAdvisors]);


  const isItemsValid = useCallback((tempTableItems) => {
    let isValid = true;
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const proxyAdvisorName = getValueFromTextInput(`${itemId}#proxy-advisor-name`);

        if(document.getElementById(`${itemId}#proxy-advisor-name`).dataset.selected === '') {
          document.getElementById(`${itemId}#proxy-advisor-name`).style.border = '1px solid var(--color-danger-700)';
        }

        /*
        const lastAgmRecommendations = getValueFromTextInput(`${itemId}#last-agm-recommendations`);
        if(lastAgmRecommendations === '') {
          document.getElementById(`${itemId}#last-agm-recommendations`).style.border = '1px solid var(--color-danger-700)';
        }
        */
        
        isValid = isValid && (proxyAdvisorName !== '');
      });
    }
    return isValid;
  }, []);


  const cancelEditHandler = () => {
    setTableItems(generateClientDetailsShowProxyAdvisorTableItems(clientType, proxyAdvisorsData['items']));
    setIsEditMode(false);
  }


  const showProxyAdvisorsSaveApprovalHandler = () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateProxyAdvisorTableItems(newTableItems);
      return newTableItems;
    });
    setProxyAdvisorsSaveApproval(true);
  }


  const updateProxyAdvisorsHandler = (isActivated) => {
    if(!isItemsValid(tableItems)) {
      return;
    }
    
    if(isActivated) {
      const data = proxyAdvisorsData['items'];
      setProxyAdvisorsData(prevState => {
        prevState['is_activated'] = true;
        return prevState;
      });
      setIsEditMode(false);
      props.onSaveClick(data, true);
      setProxyAdvisorsActivateApproval(false);
    }
    else {
      const data = fetchProxyAdvisorData(tableItems);
      setTableItems(generateClientDetailsShowProxyAdvisorTableItems(clientType, data));
      setProxyAdvisorsData(prevState => {
        prevState['items'] = data;
        prevState['is_activated'] = false;
        return prevState;
      });
      setIsEditMode(false);
      props.onSaveClick(data, false);
      setProxyAdvisorsSaveApproval(false)
    }
  }


  useEffect(() => {
    let newTableItems = null;
    if(!isEditMode) {
      newTableItems = generateClientDetailsShowProxyAdvisorTableItems(clientType, proxyAdvisorsData['items']);
    }
    else {
      newTableItems = generateClientDetailsEditProxyAdvisorTableItems(addNewProxyAdvisorRowHandler(createProxyAdvisorRow, selectProxyAdvisorDropdownItemHandler, removeProxyAdvisorRowHandler));
      proxyAdvisorsData['items'].forEach(item => {
        newTableItems['middle']['body']['items'].push(
          createProxyAdvisorRow(item, selectProxyAdvisorDropdownItemHandler, removeProxyAdvisorRowHandler)
        );
      });
      newTableItems['middle']['header']['items'][0]['columns'][0]['text'] = `PROXY ADVISOR / RATING NAME (${proxyAdvisorsData['items'].length})`;
    }
    setTableItems(newTableItems);
  }, [clientType, proxyAdvisorsData, isEditMode,
      addNewProxyAdvisorRowHandler, createProxyAdvisorRow,
      selectProxyAdvisorDropdownItemHandler, removeProxyAdvisorRowHandler]);


  const propsProxyAdvisorsData = props.proxyAdvisorsData;
  const propsSquareWellProxyAdvisors = props.squareWellProxyAdvisors;
  useEffect(() => {
    setProxyAdvisorsData(propsProxyAdvisorsData);
    setSquareWellProxyAdvisors(propsSquareWellProxyAdvisors);
  }, [propsProxyAdvisorsData, propsSquareWellProxyAdvisors]);


  return (
    <div className={styles['client-details-proxy-advisors']}>
      { proxyAdvisorsSaveApproval &&
        <Modal
            type='critical'
            title='Warning!'
            message={`You will lose previous Proxy Advisors & Ratings list information when you save the changes. Are you sure you want to proceed?`}
            onCloseClick={() => setProxyAdvisorsSaveApproval(false)}
            approveButton = {{
              'text': 'Proceed',
              'onClick': () => updateProxyAdvisorsHandler(false)
            }}
            cancelButton = {{
              'text': 'Cancel',
              'onClick': () => setProxyAdvisorsSaveApproval(false)
            }}
          />
      }

      { proxyAdvisorsActivateApproval &&
        <Modal
            type='info'
            title='Activate Proxy Advisors & Ratings List'
            message='Are you sure you have finished editing the Proxy Advisors & Ratings list?'
            onCloseClick={() => setProxyAdvisorsActivateApproval(false)}
            approveButton = {{
              'text': 'Activate',
              'onClick': () => updateProxyAdvisorsHandler(true)
            }}
            cancelButton = {{
              'text': 'Cancel',
              'onClick': () => setProxyAdvisorsActivateApproval(false)
            }}
          />
      }

      { tableItems !== null &&
        <Fragment>
          <div className={styles['proxy-advisor-table-top-container']}>
            <div className={styles['title']}>
              <h1>Proxy Advisors & Ratings List as of {new Date(proxyAdvisorsData['last_updated_at']).toLocaleDateString('en-GB')}</h1>
            </div>
            <div className={styles['action-buttons-container']}>
              { !isEditMode &&
                <div className={styles['right-buttons']}>
                  <Button type={"primary"} size={"base"} onClick={() => setIsEditMode(true)}>
                    Update Proxy Advisors & Ratings List
                  </Button>
                </div>
              }
              { isEditMode &&
                <div className={styles['right-buttons']}>
                  <Button type={"cancel"} size={"base"} onClick={cancelEditHandler}>Cancel</Button>
                  <Button type={"primary"} size={"base"} onClick={showProxyAdvisorsSaveApprovalHandler}>Save</Button>
                </div>
              }
            </div>
          </div>

          <Table width={props.pageWidth} items={tableItems} />
        
          <div className={styles['proxy-advisor-table-bottom-container']}>
            <div className={styles['action-buttons-container']}>
              {!isEditMode && !proxyAdvisorsData['is_activated'] &&
                <div className={styles['right-buttons']}>
                  <Button type={"primary"} size={"base"} onClick={() => setProxyAdvisorsActivateApproval(true)}>
                    Activate the List
                  </Button>
                </div>
              }
            </div>
          </div>
        </Fragment>
      }
    </div>
  )
}

export default ClientDetailsProxyAdvisors;

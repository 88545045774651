import { useEffect, useMemo, useState } from "react";
import { getClientAvailableModules } from "../../../../utils/auth";

import Tab from "../../../../components/UI/Tab/Tab";

import InvestorEngagementsOtherNotes from "./InvestorEngagementsOtherNotes/InvestorEngagementsOtherNotes";
import InvestorEngagementsMeetingNotes from "./InvestorEngagementsMeetingNotes/InvestorEngagementsMeetingNotes";
import InvestorEngagementsMeetingRequests from "./InvestorEngagementsMeetingRequests/InvestorEngagementsMeetingRequests";
import InvestorEngagementsShareholderCommunications from "./InvestorEngagementsShareholderCommunications/InvestorEngagementsShareholderCommunications";

import styles from './InvestorEngagements.module.css';


const InvestorEngagements = (props) => {
  const [tabItems, setTabItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState('');
  const [showEngagementsTab, setShowEngagementsTab] = useState(true);

  const investorEngagementsTabItems = useMemo(() => [
    {
      'id': 'tab-meeting-requests',
      'text': 'Meeting Requests'
    },
    {
      'id': 'tab-shareholder-communications',
      'text': 'Shareholder Communications'
    },
    {
      'id': 'tab-meeting-notes',
      'text': 'Meeting Notes'
    },
    {
      'id': 'tab-other-notes',
      'text': 'Other Notes'
    }
  ], []);

  const investorEngagementsTabChangeHandler = (selectedTabId) => {
    setSelectedTab(selectedTabId);
  }

  useEffect(() => {
    const filteredTabItems = [];
    const clientAvailableModules = getClientAvailableModules();
    if(!clientAvailableModules['engagement_meeting_requests']) {
      filteredTabItems.push('tab-meeting-requests');
    }
    if(!clientAvailableModules['engagement_shareholder_communications']) {
      filteredTabItems.push('tab-shareholder-communications');
    }
    if(!clientAvailableModules['engagement_meeting_notes']) {
      filteredTabItems.push('tab-meeting-notes');
    }
    if(!clientAvailableModules['engagement_other_notes']) {
      filteredTabItems.push('tab-other-notes');
    }
    setTabItems(investorEngagementsTabItems.filter(item => !filteredTabItems.includes(item['id'])));
  }, [investorEngagementsTabItems]);

  useEffect(() => {
    if(tabItems.length > 0) {
      investorEngagementsTabChangeHandler(tabItems[0]['id']);
    }
  }, [tabItems]);

  return (
    <div>
      {showEngagementsTab &&
        <div className={styles['tab-container']}>
          <Tab items={tabItems} selectedTab={selectedTab} type='second-level' onTabChange={investorEngagementsTabChangeHandler} />
        </div>
      }

      <div className={styles['table-container']}>
        { selectedTab === 'tab-meeting-requests' &&
          <InvestorEngagementsMeetingRequests
            width={props.width}
            investorId={props.investorId}
            isPrimaryEditor={props.isPrimaryEditor}
          />
        }
        { selectedTab === 'tab-shareholder-communications' &&
          <InvestorEngagementsShareholderCommunications
            width={props.width}
            investorId={props.investorId}
            isPrimaryEditor={props.isPrimaryEditor}
          />
        }
        { selectedTab === 'tab-meeting-notes' && 
          <InvestorEngagementsMeetingNotes
            width={props.width}
            investorId={props.investorId}
            isPrimaryEditor={props.isPrimaryEditor}
            setShowEngagementsTab={setShowEngagementsTab}
          />
        }
        { selectedTab === 'tab-other-notes' && 
          <InvestorEngagementsOtherNotes
            width={props.width}
            investorId={props.investorId}
            isPrimaryEditor={props.isPrimaryEditor}
            setShowEngagementsTab={setShowEngagementsTab}
          />
        }
      </div>
    </div>
  );
}

export default InvestorEngagements;

import { useState } from 'react';
import { investorCDPSBTiDropdownItems, investorClimateCoalitionDropdownItems, investorESGFocusDropdownItems, investorESGInFixedIncomeDropdownItems, investorPRIAdvanceDropdownItems, investorShareholderProposalsFiledDropdownItems, investorNature100DropdownItems, investorTNFDDropdownItems, investorUNPRIDropdownItems, investorWDiDropdownItems } from '../../../../../utils/constants';
import { getValueFromVariable } from '../../../../../utils/helpers';

import Button from '../../../../../components/UI/Button/Button';
import SectionInfoBox from '../SectionInfoBox/SectionInfoBox';

import summaryPageStyles from '../InvestorSummary.module.css';

const InvestorSummaryEnvironmentalAndSocialSection = (props) => {
  const isViewOnly = props.isViewOnly;

  const isCustomInvestor = props.isCustomInvestor;
  const [environmentalAndSocialData, setEnvironmentalAndSocialData] = useState(props.environmentalAndSocialData);
  const [isEditMode, setIsEditMode] = useState(false);

  const [esgFocus, setESGFocus] = useState(getValueFromVariable(environmentalAndSocialData['esg_focus']));
  const [unpri, setUNPRI] = useState(getValueFromVariable(environmentalAndSocialData['esg_unpri']));
  const [nature100, setNature100] = useState(getValueFromVariable(environmentalAndSocialData['esg_nature_100']));
  const [tnfd, setTNFD] = useState(getValueFromVariable(environmentalAndSocialData['esg_tnfd']));
  const [climateCoalition, setClimateCoalition] = useState(getValueFromVariable(environmentalAndSocialData['esg_climate_coalition']));
  const [priAdvance, setPRIAdvance] = useState(getValueFromVariable(environmentalAndSocialData['esg_pri_advance']));
  const [cdpSBTi, setCDPSBTi] = useState(getValueFromVariable(environmentalAndSocialData['esg_cdp_sbti']));
  const [wdi, setWDi] = useState(getValueFromVariable(environmentalAndSocialData['esg_wdi']));
  const [esgInFixedIncome, setESGInFixedIncome] = useState(getValueFromVariable(environmentalAndSocialData['esg_in_fixed_income']));
  const [shareholderProposalsFiled, setShareholderProposalsFiled] = useState(getValueFromVariable(environmentalAndSocialData['es_shareholder_proposals_filed']));

  let indicatorLevel = '';
  let indicatorStyle = 'active-reverse';
  if (esgFocus === 'High') { 
    indicatorLevel = 'third-level';
  }
  else if (esgFocus === 'Medium') { 
    indicatorLevel = 'second-level';
  }
  else if (esgFocus === 'Low') { 
    indicatorLevel = 'first-level';
  }
  else { 
    indicatorStyle = 'passive-reverse';
  }

  const esgInitiativesSectionGroupItems = [
    {
      'id': 'investor#unpri',
      'type': !isEditMode ? 'text' : 'dropdown',
      'label': 'UNPRI',
      'dropdownItems': investorUNPRIDropdownItems,
      'value': unpri,
      'onInputChange': setUNPRI,
      'hasTooltip': true,
      'tooltipContent': 'This field indicates as to whether this investor is a signatory to the United Nations Principles for Responsible Investment (UNPRI).'
    },
    {
      'id': 'investor#nature100',
      'type': !isEditMode ? 'text' : 'dropdown',
      'label': 'Nature 100',
      'dropdownItems': investorNature100DropdownItems,
      'value': nature100,
      'onInputChange': setNature100,
      'hasTooltip': true,
      'tooltipContent': 'This field indicates whether this investor participates in Nature 100, which is a global investor engagement initiative focused on biodiversity.'
    },
    {
      'id': 'investor#tnfd',
      'type': !isEditMode ? 'text' : 'dropdown',
      'label': 'TNFD',
      'dropdownItems': investorTNFDDropdownItems,
      'value': tnfd,
      'onInputChange': setTNFD,
      'hasTooltip': true,
      'tooltipContent': 'This field indicates whether this investor supports the Taskforce on Nature-Related Financial Disclosures (TNFD).'
    },
    {
      'id': 'investor#climate-coalition',
      'type': !isEditMode ? 'text' : 'dropdown',
      'label': 'Climate Coalition',
      'dropdownItems': investorClimateCoalitionDropdownItems,
      'value': climateCoalition,
      'onInputChange': setClimateCoalition,
      'hasTooltip': true,
      'tooltipContent': 'This field indicates whether this investor is a member/signatory of one of the main Climate Coalitions (Climate Action 100+, Net Zero Asset Managers Initiative, IIGCC, etc.).'
    },
    {
      'id': 'investor#pri-advance',
      'type': !isEditMode ? 'text' : 'dropdown',
      'label': 'PRI – Advance',
      'dropdownItems': investorPRIAdvanceDropdownItems,
      'value': priAdvance,
      'onInputChange': setPRIAdvance,
      'hasTooltip': true,
      'tooltipContent': 'This field indicates whether this investor is a signatory to the PRI – Advance initiative on Human Rights.'
    },
    {
      'id': 'investor#cdp-sbti',
      'type': !isEditMode ? 'text' : 'dropdown',
      'label': 'CDP - SBTi',
      'dropdownItems': investorCDPSBTiDropdownItems,
      'value': cdpSBTi,
      'onInputChange': setCDPSBTi,
      'hasTooltip': true,
      'tooltipContent': 'This field indicates whether this investor has participated to the most recent Carbon Disclosure Project – Science-based target initiative (CDP -SBTI) campaign, requesting enhanced climate reporting from companies.'
    },
    {
      'id': 'investor#wdi',
      'type': !isEditMode ? 'text' : 'dropdown',
      'label': 'WDi',
      'dropdownItems': investorWDiDropdownItems,
      'value': wdi,
      'onInputChange': setWDi,
      'hasTooltip': true,
      'tooltipContent': 'This field indicates whether this investor supports the Workforce Disclosure Initiative (WDI).'
    },
  ];

  const cancelEditHandler = () => {
    setIsEditMode(false);
    setESGFocus(getValueFromVariable(environmentalAndSocialData['esg_focus']));
    setUNPRI(getValueFromVariable(environmentalAndSocialData['esg_unpri']));
    setNature100(getValueFromVariable(environmentalAndSocialData['esg_nature_100']));
    setTNFD(getValueFromVariable(environmentalAndSocialData['esg_tnfd']));
    setClimateCoalition(getValueFromVariable(environmentalAndSocialData['esg_climate_coalition']));
    setPRIAdvance(getValueFromVariable(environmentalAndSocialData['esg_pri_advance']));
    setCDPSBTi(getValueFromVariable(environmentalAndSocialData['esg_cdp_sbti']));
    setWDi(getValueFromVariable(environmentalAndSocialData['esg_wdi']));
    setESGInFixedIncome(getValueFromVariable(environmentalAndSocialData['esg_in_fixed_income']));
    setShareholderProposalsFiled(getValueFromVariable(environmentalAndSocialData['es_shareholder_proposals_filed']));
  }

  const updateEnvironmentalAndSocialHandler = () => {
    const updatedEnvironmentalAndSocialData = {
      'esg_focus': esgFocus,
      'esg_unpri': unpri,
      'esg_nature_100': nature100,
      'esg_tnfd': tnfd,
      'esg_climate_coalition': climateCoalition,
      'esg_pri_advance': priAdvance,
      'esg_cdp_sbti': cdpSBTi,
      'esg_wdi': wdi,
      'esg_in_fixed_income': esgInFixedIncome,
      'es_shareholder_proposals_filed': shareholderProposalsFiled
    };
    setEnvironmentalAndSocialData(updatedEnvironmentalAndSocialData);
    setIsEditMode(false);
    props.onSaveClick(updatedEnvironmentalAndSocialData);
  }

  return (
    <div className={summaryPageStyles['section']}>
      <div className={summaryPageStyles['section-title']}>
        <h3>Environment & Social</h3>
      </div>
      { isCustomInvestor && !isEditMode && !isViewOnly &&
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='secondary' leftIcon='edit' onClick={() => setIsEditMode(true)}>Edit</Button>
        </div>
      }
      { isCustomInvestor && isEditMode && !isViewOnly &&
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='cancel' onClick={cancelEditHandler}>Cancel</Button>
          <Button size='xsmall' type='primary' onClick={updateEnvironmentalAndSocialHandler}>Save</Button>
        </div>
      }

      <div className={`${summaryPageStyles['content']} ${isEditMode && summaryPageStyles['edit-mode']}`}>
        <SectionInfoBox
          id='investor#esgFocus'
          size='single-col'
          type={!isEditMode ? 'indicator' : 'dropdown'}
          label='ESG Focus'
          value={esgFocus}
          indicatorLevel={indicatorLevel}
          indicatorStyle={indicatorStyle}
          dropdownItems={investorESGFocusDropdownItems}
          onInputChange={setESGFocus}
          hasTooltip={true}
          tooltipContent='This field indicates how sensitive this investor is to Environmental, Social, and Governance (ESG) topics as determined by AQTION. Further information can be found in the glossary.'
        />
        <SectionInfoBox
          id='investor#esg-initiatives'
          size='double-col'
          type={!isEditMode ? 'group' : 'group-edit'}
          label='ESG Initiatives'
          items={esgInitiativesSectionGroupItems}
        />
        <div style={{width: 'calc(calc(calc(calc(100% - 64px) / 5) * 2) + 16px)', float: 'left'}}>
          <SectionInfoBox
            id='investor#esg-in-fixed-income'
            size='full-size-col'
            type={!isEditMode ? 'text' : 'dropdown'}
            label='ESG in Fixed Income'
            dropdownItems={investorESGInFixedIncomeDropdownItems}
            value={esgInFixedIncome}
            onInputChange={setESGInFixedIncome}
            hasTooltip={true}
            tooltipContent='This field indicates whether this investor incorporates Environmental, Social, and Governance (ESG) topics for fixed income assets.'
          />
        </div>
        <div style={{width: 'calc(calc(calc(calc(100% - 64px) / 5) * 2) + 16px)', float: 'left', marginTop: '68px'}}>
          <SectionInfoBox
            id='investor#es-shareholder-proposals-filed'
            size='full-size-col'
            type={!isEditMode ? 'text' : 'dropdown'}
            label='E&S Shareholder Proposals Filed'
            dropdownItems={investorShareholderProposalsFiledDropdownItems}
            value={shareholderProposalsFiled}
            onInputChange={setShareholderProposalsFiled}
            hasTooltip={true}
            tooltipContent='This field indicates whether this investor has filed or co-filed a shareholder proposal related to Environment & Social topics.'
          />
        </div>
      </div>
      <div className={summaryPageStyles['separator']}></div>
    </div>
  );
}

export default InvestorSummaryEnvironmentalAndSocialSection;

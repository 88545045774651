import { Fragment, useState } from 'react';
import { getValueFromVariable } from '../../../../utils/helpers';
import { automaticRenewalDropdownItems, esgDataRatingsDropdownItems } from '../../../../utils/constants';
import useInputValidation from '../../../../hooks/use-input-validation';

import Button from '../../../../components/UI/Button/Button';
import DateInput from '../../../../components/UI/DateInput/DateInput';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import TextInput from '../../../../components/UI/TextInput/TextInput';

import styles from './ClientDetailsSubscriptionDetails.module.css';

const ClientDetailsSubscriptionDetails = (props) => {

  const [subscriptionDetailsData, setSubscriptionDetailsData] = useState(props.subscriptionDetailsData);
  const [isEditMode, setIsEditMode] = useState(false);
  
  const [contractStartDate, setContractStartDate, isContractStartDateValid, hasContractStartDateError, contractStartDateChangeHandler, contractStartDateBlurHandler] =
    useInputValidation(getValueFromVariable(subscriptionDetailsData['contract_start_date'], 'date'), (contractStartDate) => true);
  
  const [contractEndDate, setContractEndDate, isContractEndDateValid, hasContractEndDateError, contractEndDateChangeHandler, contractEndDateBlurHandler] =
    useInputValidation(getValueFromVariable(subscriptionDetailsData['contract_end_date'], 'date'), (contractEndDate) => true);

  const [automaticRenewal, setAutomaticRenewal, isAutomaticRenewalValid, hasAutomaticRenewalError, automaticRenewalChangeHandler, automaticRenewalBlurHandler] =
    useInputValidation(getValueFromVariable(subscriptionDetailsData['automatic_renewal']), (automaticRenewal) => true);
  
  const [numberOfInvestors, setNumberOfInvestors, isNumberOfInvestorsValid, hasNumberOfInvestorsError, numberOfInvestorsChangeHandler, numberOfInvestorsBlurHandler] =
    useInputValidation(getValueFromVariable(subscriptionDetailsData['number_of_investors']), (numberOfInvestors) => numberOfInvestors.toString().trim() !== '');
  
  const [numberOfUsers, setNumberOfUsers, isNumberOfUsersValid, hasNumberOfUsersError, numberOfUsersChangeHandler, numberOfUsersBlurHandler] =
    useInputValidation(getValueFromVariable(subscriptionDetailsData['number_of_users']), (numberOfUsers) => numberOfUsers.toString().trim() !== '');

  const [esgDataAndRatings, setEsgDataAndRatings, isEsgDataAndRatingsValid, hasEsgDataAndRatingsError, esgDataAndRatingsChangeHandler, esgDataAndRatingsBlurHandler] =
    useInputValidation(getValueFromVariable(subscriptionDetailsData['esg_data_and_ratings']), (esgDataAndRatings) => true);

  const cancelEditHandler = () => {
    setIsEditMode(false);
    setContractStartDate(getValueFromVariable(subscriptionDetailsData['contract_start_date'], 'date'));
    setContractEndDate(getValueFromVariable(subscriptionDetailsData['contract_end_date'], 'date'));
    setAutomaticRenewal(getValueFromVariable(subscriptionDetailsData['automatic_renewal']));
    setNumberOfInvestors(getValueFromVariable(subscriptionDetailsData['number_of_investors']));
    setNumberOfUsers(getValueFromVariable(subscriptionDetailsData['number_of_users']));
    setEsgDataAndRatings(getValueFromVariable(subscriptionDetailsData['esg_data_and_ratings']));
  }

  const isSubscriptionDetailsFormValid = () => {
    numberOfInvestorsBlurHandler();
    numberOfUsersBlurHandler();
    return (isNumberOfInvestorsValid && isNumberOfUsersValid) && 
      (isContractStartDateValid && isContractEndDateValid && isAutomaticRenewalValid && isEsgDataAndRatingsValid);
  }

  const updateSubscriptionDetailsHandler = () => {
    if(isSubscriptionDetailsFormValid()) {
      const getContractEndDate = (automaticRenewal, contractEndDate) => {
        if (automaticRenewal === 'Yes' || contractEndDate === '') {
          return null;
        }
        else {
          return contractEndDate.toDateString()
        }
      }

      const updatedSubscriptionDetailsData = {
        'contract_start_date': contractStartDate === '' ? null : contractStartDate.toDateString(),
        'contract_end_date': getContractEndDate(automaticRenewal, contractEndDate),
        'automatic_renewal': automaticRenewal,
        'number_of_investors': numberOfInvestors,
        'number_of_users': numberOfUsers,
        'esg_data_and_ratings': esgDataAndRatings
      };
      setSubscriptionDetailsData(updatedSubscriptionDetailsData);
      setIsEditMode(false);
      props.onSaveClick(updatedSubscriptionDetailsData);
    }
  }


  const automaticRenewalCheckHandler = (selectedValue) => {
    automaticRenewalChangeHandler(selectedValue);
    if (selectedValue === 'Yes') {
      setContractEndDate('-');
    }
    else {
      setContractEndDate(getValueFromVariable(subscriptionDetailsData['contract_end_date'], 'date'));
    }
  }

  return (
    <div className={styles['client-details-subscription-details']}>
      <div className={styles['subscription-details-form']}>
        <div className={styles['row']}>
          <DateInput
            id='subscription-details#contract-start-date'
            width='100%'
            label='Contract Start Date'
            selected={contractStartDate}
            hasError={hasContractStartDateError}
            onBlur={contractStartDateBlurHandler}
            onChange={contractStartDateChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='subscription-details#automatic-renewal'
            width='100%'
            label='Automatic Renewal'
            placeholder='Select an Automatic Renewal Option'
            items={automaticRenewalDropdownItems}
            selected={automaticRenewal}
            hasError={hasAutomaticRenewalError}
            onBlur={automaticRenewalBlurHandler}
            onItemClick={automaticRenewalCheckHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          { automaticRenewal === 'Yes' &&
            <TextInput
              id='subscription-details#contract-end-date'
              width='100%'
              label='Contract End Date'
              value='-'
              isDisabled={true}
            />
          }
          { automaticRenewal !== 'Yes' &&
            <DateInput
              id='subscription-details#contract-end-date'
              width='100%'
              label='Contract End Date'
              selected={contractEndDate}
              hasError={hasContractEndDateError}
              onBlur={contractEndDateBlurHandler}
              onChange={contractEndDateChangeHandler}
              isDisabled={!isEditMode}
            />
          }
        </div>
        <div className={styles['row']}>
          <TextInput
            id='subscription-details#number-of-investors'
            width='100%'
            label='Number of Investors *'
            placeholder='Enter Number of Investors'
            value={numberOfInvestors}
            hasError={hasNumberOfInvestorsError}
            onBlur={numberOfInvestorsBlurHandler}
            onChange={numberOfInvestorsChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='subscription-details#number-of-users'
            width='100%'
            label='Number of Users *'
            placeholder='Enter Number of Users'
            value={numberOfUsers}
            hasError={hasNumberOfUsersError}
            onBlur={numberOfUsersBlurHandler}
            onChange={numberOfUsersChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='subscription-details#esg-data-ratings'
            width='100%'
            // label='ESG Data & Ratings'
            // placeholder='Select ESG Data & Ratings'
            label='Advisory Support'
            placeholder='Select Advisory Support'
            items={esgDataRatingsDropdownItems}
            selected={esgDataAndRatings}
            hasError={hasEsgDataAndRatingsError}
            onBlur={esgDataAndRatingsBlurHandler}
            onItemClick={esgDataAndRatingsChangeHandler}
            isDisabled={!isEditMode}
          />
        </div>
      </div>

      <div className={styles['action-button-container']}>
        {isEditMode && 
          <Fragment>
            <Button size='base' type='cancel' onClick={cancelEditHandler}>Cancel</Button>
            <Button size='base' type='primary' onClick={updateSubscriptionDetailsHandler}>Save</Button>
          </Fragment>
        }
        {!isEditMode && 
          <Fragment>
            <Button size='base' type='secondary' leftIcon='edit' onClick={() => setIsEditMode(true)}>Edit</Button>
          </Fragment>
        }
      </div>
    </div>
  )
}

export default ClientDetailsSubscriptionDetails;


import { useEffect, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { createClientSetupRequest, fetchClientSetupDetailsRequest, updateClientSetupRequest, uploadClientSetupProfilePictureRequest } from '../../../requests/client-setup-requests';
import { listSquareWellInvestorsRequest, listSquareWellProxyAdvisorsRequest } from '../../../requests/squarewell-requests';
import { useParams } from 'react-router-dom';
import { accountManagerMenuItems } from '../../../utils/constants';
import { getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { createClientFromClientSetupRequest } from '../../../requests/client-requests';
import { getAuthUserProfilePictureUrl } from '../../../utils/auth';
import { handleRequestError } from '../../../utils/request-error-helpers';
import useHttp from '../../../hooks/use-http';

import Breadcrumbs from '../../../components/UI/Breadcrumbs/Breadcrumbs';
import ClientSetupInitialStep from './ClientSetupInitialStep/ClientSetupInitialStep';
import ClientSetupGeneralInfo from './ClientSetupGeneralInfo/ClientSetupGeneralInfo';
import ClientSetupSubscriptionDetails from './ClientSetupSubscriptionDetails/ClientSetupSubscriptionDetails';
import ClientSetupUserManagement from './ClientSetupUserManagement/ClientSetupUserManagement';
import ClientSetupProxyAdvisors from './ClientSetupProxyAdvisors/ClientSetupProxyAdvisors';
import ClientSetupInvestorList from './ClientSetupInvestorList/ClientSetupInvestorList';
import ClientSetupFinalStep from './ClientSetupFinalStep/ClientSetupFinalStep';
import SideNavigationBar from '../../../components/UI/SideNavigationBar/SideNavigationBar';
import StepProgressBar from '../../../components/UI/StepProgressBar/StepProgressBar';

import SquareWellProfilePicture from '../../../assets/other/squarewell-profile-picture.png';
import styles from './ClientSetupPage.module.css';

const ClientSetupPage = (props) => {
  const { _clientSetupId } = useParams();

  /* eslint-disable no-unused-vars */
  const { width, pageHeight, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  /* eslint-disable no-unused-vars */

  const userProfilePictureUrl = getAuthUserProfilePictureUrl();

  const progressSteps = useMemo(() => {
    return [
      {
        'id': 'general-info',
        'text': 'General Info',
        'number': 1
      },
      {
        'id': 'subscription-details',
        'text': 'Subscription Details',
        'number': 2
      },
      {
        'id': 'user-management',
        'text': 'User Management',
        'number': 3
      },
      {
        'id': 'proxy-advisors',
        'text': 'Proxy Advisors & Ratings',
        'number': 4
      },
      {
        'id': 'investor-list',
        'text': 'Investor List',
        'number': 5
      }
    ];
  }, []);

  const initialData = useMemo(() => {
    return {
      'general_information': {
        'data': {
          'name': '',
          'region': '',
          'country': '',
          'total_num_of_shares': '',
          'date_num_of_shares': '',
          'profile_picture_url': ''
        },
        'is_completed': false
      },
      'main_contact': {
        'data': {
          'fullname': '',
          'job_title': '',
          'department': '',
          'email': '',
          'phone_number': '',
          'relationship_status': ''
        },
        'is_completed': false
      },
      'subscription_details': {
        'data': {
          'contract_start_date': '',
          'contract_end_date': '',
          'automatic_renewal': '',
          'number_of_investors': '',
          'number_of_users': '',
          'esg_data_and_ratings': ''
        },
        'is_completed': false
      },
      'users': {
        'items': [],
        'is_completed': false
      },
      'proxy_advisors': {
        'items': [],
        'is_completed': false
      },
      'investors': {
        'items': [],
        'is_completed': false
      },
      'progress': 0
    };
  }, []);


  const [isLoading, error, sendRequest] = useHttp();
  const [selectedStep, setSelectedStep] = useState('');
  const [completedSteps, setCompletedSteps] = useState([]);
  const [warningSteps, setWarningSteps] = useState([]);
  const [clickableSteps, setClickableSteps] = useState([]);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const [clientSetupData, setClientSetupData] = useState(initialData);
  const [squareWellInvestors, setSquareWellInvestors] = useState([]);
  const [squareWellProxyAdvisors, setSquareWellProxyAdvisors] = useState([]);

  const [clientSetupId, setClientSetupId] = useState(_clientSetupId);


  const formStepChangeHandler = (selectedStepId) => {
    setSelectedStep(selectedStepId)
  }

  useEffect(() => {
    if (selectedStep === '') {
      return;
    }
    let currentStepText = '';
    if (selectedStep === 'initial') {
      currentStepText = 'Start'
    }
    else if (selectedStep === 'final') {
      currentStepText = 'Complete'
    }
    else {
      currentStepText = progressSteps.filter(item => item['id'] === selectedStep)[0]['text'];
    }
    setBreadcrumbItems([
      {
        'text': 'My Clients',
        'href': ''
      },
      {
        'text': 'Client Setup',
        'href': ''
      },
      {
        'text': currentStepText,
        'href': ''
      },
    ]);
  }, [selectedStep, progressSteps]);


  const clientSetupStartHandler = () => {
    setClientSetupData(initialData);
    formStepChangeHandler('general-info');
  }


  useEffect(() => {
    const handleListSquareWellInvestorsResponse = (data) => {
      setSquareWellInvestors(data['items']);
    }
    sendRequest(
      listSquareWellInvestorsRequest(),
      handleListSquareWellInvestorsResponse
    );

    const handleListSquareWellProxyAdvisorsResponse = (data) => {
      setSquareWellProxyAdvisors(data['items']);
    }
    sendRequest(
      listSquareWellProxyAdvisorsRequest(),
      handleListSquareWellProxyAdvisorsResponse
    );
  }, [sendRequest]);


  useEffect(() => {
    if(clientSetupId !== undefined) {    
      const handleFetchClientSetupDetailsResponse = (data) => {
        setClientSetupData(data);

        let currentStep = 'general-info';
        if((!data['general_information']['is_completed'] && !data['main_contact']['is_completed']) &&
            (data['general_information']['is_started'] && data['main_contact']['is_started'])) {
          currentStep = 'general-info';
        }
        else if(data['general_information']['is_completed'] && data['main_contact']['is_completed']) {
          currentStep = 'subscription-details';
        }

        if(!data['subscription_details']['is_completed'] && data['subscription_details']['is_started']) {
          currentStep = 'subscription-details';
        }
        else if(data['subscription_details']['is_completed']) {
          currentStep = 'user-management';
        }


        if(!data['users']['is_completed'] && data['users']['is_started']) {
          currentStep = 'user-management';
        }
        else if(data['users']['is_completed']) {
          currentStep = 'proxy-advisors';
        }


        if(!data['proxy_advisors']['is_completed'] && data['proxy_advisors']['is_started']) {
          currentStep = 'proxy-advisors';
        }
        else if(data['proxy_advisors']['is_completed']) {
          currentStep = 'investor-list';
        }


        if(!data['investors']['is_completed'] && data['investors']['is_started']) {
          currentStep = 'investor-list';
        }
        else if(data['investors']['is_completed']) {
          currentStep = 'final';
        }

        formStepChangeHandler(currentStep);
      }
      sendRequest(
        fetchClientSetupDetailsRequest(clientSetupId),
        handleFetchClientSetupDetailsResponse
      );
    }
    else {
      setClientSetupData(initialData);
      formStepChangeHandler('initial');
    }
  }, [sendRequest, clientSetupId, initialData]);

  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }


  useEffect(() => {
    const updateStepStatuses = (updatedClientSetupData) => {
      const newCompletedSteps = [];
      const newWarningSteps = [];
      const newClickableSteps = [];
      if((!updatedClientSetupData['general_information']['is_completed'] || !updatedClientSetupData['main_contact']['is_completed']) &&
          (updatedClientSetupData['general_information']['is_started'] || updatedClientSetupData['main_contact']['is_started'])) {
        newWarningSteps.push('general-info');
        newClickableSteps.push('general-info');
      }
      else if(updatedClientSetupData['general_information']['is_completed'] && updatedClientSetupData['main_contact']['is_completed']){
        newCompletedSteps.push('general-info');
        newClickableSteps.push('general-info');
      }
      
      if(!updatedClientSetupData['subscription_details']['is_completed'] && updatedClientSetupData['subscription_details']['is_started']) {
        newWarningSteps.push('subscription-details');
        newClickableSteps.push('subscription-details');
      }
      else if(updatedClientSetupData['subscription_details']['is_completed']){
        newCompletedSteps.push('subscription-details');
        newClickableSteps.push('subscription-details');
      }
  
      if(!updatedClientSetupData['users']['is_completed'] && updatedClientSetupData['users']['is_started']) {
        newWarningSteps.push('user-management');
        newClickableSteps.push('user-management');
      }
      else if(updatedClientSetupData['users']['is_completed']){
        newCompletedSteps.push('user-management');
        newClickableSteps.push('user-management');
      }
  
      if(!updatedClientSetupData['proxy_advisors']['is_completed'] && updatedClientSetupData['proxy_advisors']['is_started']) {
        newWarningSteps.push('proxy-advisors');
        newClickableSteps.push('proxy-advisors');
      }
      else if(updatedClientSetupData['proxy_advisors']['is_completed']){
        newCompletedSteps.push('proxy-advisors');
        newClickableSteps.push('proxy-advisors');
      }
  
      if(!updatedClientSetupData['investors']['is_completed'] && updatedClientSetupData['investors']['is_started']) {
        newWarningSteps.push('investor-list');
        newClickableSteps.push('investor-list');
        newClickableSteps.push('final');
      }
      else if(updatedClientSetupData['investors']['is_completed']) {
        newCompletedSteps.push('investor-list');
        newClickableSteps.push('investor-list');
        newClickableSteps.push('final');
      }
  
      setCompletedSteps(newCompletedSteps);
      setWarningSteps(newWarningSteps);
      setClickableSteps(newClickableSteps);
    }

    updateStepStatuses(clientSetupData);
  }, [clientSetupData]);


   /* Upload profile picture for the client. */
  const uploadClientSetupProfilePictureHandler = (profilePictureData, setProfilePictureUrl) => {
    sendRequest(
      uploadClientSetupProfilePictureRequest(clientSetupId !== undefined ? clientSetupId : 'temp', profilePictureData),
      (data) => { setProfilePictureUrl(data['profile_picture_url']); }
    );
  }


  const generalInformationSaveHandler = (updatedGeneralInformationData, updatedMainContactData, pageDirectedTo) => {
    const generalInformationObject = {
      'data': updatedGeneralInformationData,
      'is_completed': ((updatedGeneralInformationData['name'] !== '') &&
                        (updatedGeneralInformationData['region'] !== '') &&
                        (updatedGeneralInformationData['country'] !== '') &&
                        (updatedGeneralInformationData['total_num_of_shares'] !== '')),
      'is_started': true
    };
    const mainContactObject = {
      'data': updatedMainContactData,
      'is_completed': ((updatedMainContactData['fullname'] !== '') &&
                        (updatedMainContactData['title'] !== '') &&
                        (updatedMainContactData['department'] !== '') &&
                        (updatedMainContactData['email'] !== '')),
      'is_started': true
    };
    const body = {
      'general_information': generalInformationObject,
      'main_contact': mainContactObject,
    };
    if(clientSetupId !== undefined) {
      const updateNewClientSetupResponse = (data) => {
        setClientSetupData(data);
        if(pageDirectedTo === 'next') {
          setSelectedStep('subscription-details');
        }
        else if(pageDirectedTo === 'quit') {
          window.location.href = '../clients';
        }
      }
      sendRequest(
        updateClientSetupRequest(clientSetupId, body),
        updateNewClientSetupResponse
      );
    }
    else {
      const createClientSetupResponse = (data) => {
        setClientSetupData(data);
        setClientSetupId(data['id']);
        if(pageDirectedTo === 'next') {
          setSelectedStep('subscription-details');
        }
        else if(pageDirectedTo === 'quit') {
          window.location.href = '../clients';
        }
      }
      sendRequest(
        createClientSetupRequest(body),
        createClientSetupResponse
      );
    }
  }


  const subscriptionDetailsSaveHandler = (updatedSubscriptionDetailsData, pageDirectedTo) => {
    const subscriptionDetailsObject = {
      'data': updatedSubscriptionDetailsData,
      'is_completed': ((updatedSubscriptionDetailsData['number_of_investors'] !== '') &&
                        (updatedSubscriptionDetailsData['number_of_users'] !== '')),
      'is_started': true
    };
    const body = {
      'subscription_details': subscriptionDetailsObject
    };
    const updateNewClientSetupResponse = (data) => {
      setClientSetupData(data);
      if(pageDirectedTo === 'next') {
        setSelectedStep('user-management')
      }
      else if(pageDirectedTo === 'prev') {
        setSelectedStep('general-info')
      }
      else if(pageDirectedTo === 'quit') {
        window.location.href = '../clients';
      }
    }
    sendRequest(
      updateClientSetupRequest(clientSetupId, body),
      updateNewClientSetupResponse
    );
  }


  const userManagementSaveHandler = (updatedUserManagementData, pageDirectedTo) => {
    const numberOfUsers = clientSetupData['subscription_details']['data']['number_of_users'] === '' ? 0 :
                            clientSetupData['subscription_details']['data']['number_of_users'];
    const userManagementObject = {
      'items': updatedUserManagementData,
      'is_completed': (numberOfUsers !== 0 && updatedUserManagementData.length === numberOfUsers),
      'is_started': true
    };
    const body = {
      'users': userManagementObject
    };
    const updateNewClientSetupResponse = (data) => {
      setClientSetupData(data);
      if(pageDirectedTo === 'next') {
        setSelectedStep('proxy-advisors');
      }
      else if(pageDirectedTo === 'prev') {
        setSelectedStep('subscription-details');
      }
      else if(pageDirectedTo === 'quit') {
        window.location.href = '../clients';
      }
    }
    sendRequest(
      updateClientSetupRequest(clientSetupId, body),
      updateNewClientSetupResponse
    );
  }


  const proxyAdvisorsSaveHandler = (updatedProxyAdvisorsData, isActivated, pageDirectedTo) => {
    const proxyAdvisorsObject = {
      'items': updatedProxyAdvisorsData,
      'is_completed': updatedProxyAdvisorsData.length > 0 && isActivated,
      'is_started': true,
      'is_activated': isActivated
    };
    const body = {
      'proxy_advisors': proxyAdvisorsObject
    };
    const updateNewClientSetupResponse = (data) => {
      setClientSetupData(data);
      if(pageDirectedTo === 'next') {
        setSelectedStep('investor-list');
      }
      else if(pageDirectedTo === 'prev') {
        setSelectedStep('user-management');
      }
      else if(pageDirectedTo === 'quit') {
        window.location.href = '../clients';
      }
    }
    sendRequest(
      updateClientSetupRequest(clientSetupId, body),
      updateNewClientSetupResponse
    );
  }


  const investorListSaveHandler = (updatedInvestorListData, isActivated, pageDirectedTo) => {
    const numberOfInvestors = clientSetupData['subscription_details']['data']['number_of_investors'] === '' ? 0 :
                                clientSetupData['subscription_details']['data']['number_of_investors'];
    const investorsListObject = {
      'items': updatedInvestorListData,
      'is_completed': (numberOfInvestors !== 0 && updatedInvestorListData.length <= numberOfInvestors && isActivated),
      'is_started': true,
      'is_activated': isActivated
    };
    const body = {
      'investors': investorsListObject
    };
    const updateNewClientSetupResponse = (data) => {
      setClientSetupData(data);
      if(pageDirectedTo === 'next'){
        setSelectedStep('final');
      }
      else if(pageDirectedTo === 'prev') {
        setSelectedStep('proxy-advisors');
      }
      else if(pageDirectedTo === 'quit') {
        window.location.href = '../clients';
      }
    }
    sendRequest(
      updateClientSetupRequest(clientSetupId, body),
      updateNewClientSetupResponse
    );
  }


  const createClientFromClientSetupHandler = () => {
    const body = {
      'client_setup_id': parseInt(clientSetupId)
    };
    sendRequest(
      createClientFromClientSetupRequest(body),
      (data) => {
        window.location.href = '../clients';
      }
    );
  }


  return (
    <div className={styles['page']}>
      <SideNavigationBar
        menuItems={accountManagerMenuItems}
        profilePictureUrl={userProfilePictureUrl === '' ? SquareWellProfilePicture : userProfilePictureUrl}
        selected='my-clients'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div className={`${styles['container']} ${styles[pageStyle]}`} style={{height: 'calc(100% - 148px)', paddingBottom: 84, overflowY: 'auto', overflowX: 'hidden'}} ref={ref}>
        <Breadcrumbs items={breadcrumbItems} />

        <div className={styles['client-setup-container']}>
          {selectedStep === 'initial1' && 
          <div className={styles['client-setup-form-title']}>
            <h1>Client Setup</h1>
          </div>
          }
          
          <div className={styles['client-setup-progress']}>
            <StepProgressBar
              items={progressSteps}
              onStepChange={formStepChangeHandler}
              selectedStep={selectedStep}
              completedSteps={completedSteps}
              warningSteps={warningSteps}
              clickableSteps={clickableSteps}
            />
          </div>

          { selectedStep === 'initial' &&
            <ClientSetupInitialStep
              onStart={clientSetupStartHandler}
            />
          }
          { selectedStep === 'general-info' &&
            <ClientSetupGeneralInfo
              pageWidth={width}
              generalInformationData={clientSetupData['general_information']['data']}
              mainContactData={clientSetupData['main_contact']['data']}
              onNextClick={generalInformationSaveHandler}
              onSaveAndQuitClick={generalInformationSaveHandler}
              onProfilePictureUploadClick={uploadClientSetupProfilePictureHandler}
            />
          }
          { selectedStep === 'subscription-details' && 
            <ClientSetupSubscriptionDetails
              pageWidth={width}
              subscriptionDetailsData={clientSetupData['subscription_details']['data']}
              onPrevClick={subscriptionDetailsSaveHandler}
              onNextClick={subscriptionDetailsSaveHandler}
              onSaveAndQuitClick={subscriptionDetailsSaveHandler}
            />
          }
          { selectedStep === 'user-management' && 
            <ClientSetupUserManagement
              pageWidth={width}
              userList={clientSetupData['users']['items']}
              numberOfUsers={
                clientSetupData['subscription_details']['data']['number_of_users'] === '' ? 0 :
                clientSetupData['subscription_details']['data']['number_of_users']
              }
              onPrevClick={userManagementSaveHandler}
              onNextClick={userManagementSaveHandler}
              onSaveAndQuitClick={userManagementSaveHandler}
            /> 
          }
          { selectedStep === 'proxy-advisors' &&
            <ClientSetupProxyAdvisors
              pageWidth={width}
              clientType = {clientSetupData['general_information']['data']['client_type']}
              proxyAdvisorList={clientSetupData['proxy_advisors']['items']}
              proxyAdvisorListActivated={clientSetupData['proxy_advisors']['is_activated']}
              squareWellProxyAdvisors={squareWellProxyAdvisors}
              onPrevClick={proxyAdvisorsSaveHandler}
              onNextClick={proxyAdvisorsSaveHandler}
              onSaveAndQuitClick={proxyAdvisorsSaveHandler}
            />
          }
          { selectedStep === 'investor-list' &&
            <ClientSetupInvestorList
              pageWidth={width}
              clientType = {clientSetupData['general_information']['data']['client_type']}
              investorList={clientSetupData['investors']['items']}
              investorListActivated={clientSetupData['investors']['is_activated']}
              squareWellInvestors={squareWellInvestors}
              totalNumOfShares={
                clientSetupData['general_information']['data']['total_num_of_shares'] === '' ? 0 :
                clientSetupData['general_information']['data']['total_num_of_shares']
              }
              numberOfInvestors={
                clientSetupData['subscription_details']['data']['number_of_investors'] === '' ? 0 :
                clientSetupData['subscription_details']['data']['number_of_investors']
              }
              onPrevClick={investorListSaveHandler}
              onNextClick={investorListSaveHandler}
              onSaveAndQuitClick={investorListSaveHandler}
            />
          }
          { selectedStep === 'final' &&
            <ClientSetupFinalStep
              clientSetupData={clientSetupData}
              onPrevClick={() => formStepChangeHandler('investor-list')}
              onCompleteClick={createClientFromClientSetupHandler}
            />
          }
        </div>
      </div>
    </div>
  );
}

export default ClientSetupPage;

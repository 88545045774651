import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import { clientMenuItems } from '../../../utils/constants';
import { getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { fetchClientBasicProfileRequest } from '../../../requests/client-requests';
import { checkIsAccountManagerViewMode, getAuthClientId, getAuthClientName, getAuthUserProfilePictureUrl, getAuthUserType, getClientAvailableModules, isViewMode, setLocalStorageClientData } from '../../../utils/auth';
import { handleRequestError } from '../../../utils/request-error-helpers';
import useHttp from '../../../hooks/use-http';

import Button from '../../../components/UI/Button/Button';
import Breadcrumbs from '../../../components/UI/Breadcrumbs/Breadcrumbs';
import Tab from '../../../components/UI/Tab/Tab';

import AccountManagerModeHeader from '../../../components/AccountManagerModeHeader/AccountManagerModeHeader';
import SideNavigationBar from '../../../components/UI/SideNavigationBar/SideNavigationBar';

import EngagementsMeetingNotes from './EngagementsMeetingNotes/EngagementsMeetingNotes';
import EngagementsOtherNotes from './EngagementsOtherNotes/EngagementsOtherNotes';
import EngagementsMeetingRequests from './EngagementsMeetingRequests/EngagementsMeetingRequests';
import EngagementsShareholderCommunications from './EngagementsShareholderCommunications/EngagementsShareholderCommunications';

import styles from './EngagementListPage.module.css';

const EngagementListPage = (props) => {
  const navigate = useNavigate();

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const [searchParams, setSearchParams] = useSearchParams();
  const pageHeightMargin = checkIsAccountManagerViewMode() ? '161px' : '95px';
  /* eslint-disable no-unused-vars */

  const clientId = getAuthClientId();
  const clientAvailableModules = getClientAvailableModules();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();
  
  const userType = getAuthUserType();
  const isAccountManagerViewMode = isViewMode();
  const isViewOnly = (userType === 'View Only') || isAccountManagerViewMode;
  const isPrimaryEditor = userType === 'Primary Editor';

  const [isLoading, error, sendRequest] = useHttp();

  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);

  const selectedMeetingRequestId = searchParams.get('meeting_request_id');
  const selectedShareholderCommunicationId = searchParams.get('shareholder_communication_id');
  const selectedMeetingNoteId = searchParams.get('meeting_note_id');
  const selectedOtherNoteId = searchParams.get('other_note_id');
  const formType = searchParams.get('form_type');

  const [engagementPageType, setEngagementPageType] = useState('list');

  const [showNewEngagementOptions, setShowNewEngagementOptions] = useState(false);

  const [tabItems, setTabItems] = useState([]);

  const engagementTabItems = useMemo( () => {
    return [
      {
        'id': 'tab-meeting-requests',
        'text': 'Meeting Requests'
      },
      {
        'id': 'tab-shareholder-communications',
        'text': 'Shareholder Communications'
      },
      {
        'id': 'tab-meeting-notes',
        'text': 'Meeting Notes'
      },
      {
        'id': 'tab-other-notes',
        'text': 'Other Notes'
      }
    ]
  }, []);

  const selectedEngagementType = searchParams.get('type');
  const [selectedTab, setSelectedTab] = useState(selectedEngagementType === null ? 'tab-meeting-requests' : `tab-${selectedEngagementType}`);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  useEffect(() => {
    const filteredTabItems = [];
    const clientAvailableModules_ = getClientAvailableModules();
    if(!clientAvailableModules_['engagement_meeting_requests']) {
      filteredTabItems.push('tab-meeting-requests');
    }
    if(!clientAvailableModules_['engagement_shareholder_communications']) {
      filteredTabItems.push('tab-shareholder-communications');
    }
    if(!clientAvailableModules_['engagement_meeting_notes']) {
      filteredTabItems.push('tab-meeting-notes');
    }
    if(!clientAvailableModules_['engagement_other_notes']) {
      filteredTabItems.push('tab-other-notes');
    }
    setTabItems(engagementTabItems.filter(item => !filteredTabItems.includes(item['id'])));
  }, [engagementTabItems]);

  useEffect(() => {
    const filteredTabItems = engagementTabItems.filter(item => item.id === selectedTab);
    setBreadcrumbItems([{
      'text': 'Engagements',
      'href': '../engagements'
    }, {
      'text': filteredTabItems.length > 0 ? filteredTabItems[0]['text'] : '',
      'href': ''
    }]);
  }, [engagementTabItems, selectedTab]);

  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (dataClient) => {
        setLocalStorageClientData(dataClient);
        setClientProfilePictureUrl(dataClient['profile_picture_url']);
        if(!dataClient['module_management']['engagements']) {
          if(dataClient['module_management']['dashboard']) {
            window.location.href = 'dashboard';
          }
          else {
            window.location.href = 'investors';
          }
        }
      }
    );
  }, [sendRequest, clientId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const investorEngagementsTabChangeHandler = (selectedTabId) => {
    searchParams.set('type', selectedTabId.replace('tab-', ''));
    setSearchParams(searchParams);

    setSelectedTab(selectedTabId);

    let tabTitle = '';
    if(selectedTabId === 'tab-meeting-requests') {
      tabTitle = 'Meeting Requests';
    }
    else if(selectedTabId === 'tab-shareholder-communications') {
      tabTitle = 'Shareholder Communications';
    }
    else if(selectedTabId === 'tab-meeting-notes') {
      tabTitle = 'Meeting Notes';
    }
    else if(selectedTabId === 'tab-other-notes') {
      tabTitle = 'Other Notes';
    }
    setBreadcrumbItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      if(items.length === 1){ 
        items.push({
          'text': tabTitle,
          'href': ''
        });
      }
      else {
        items[1]['text'] = tabTitle;
        items[1]['href'] = '';
      }
      return items;
    });
  }

  /* Open new meeting note creation form. */
  const createNewEngagementClickHandler = () => {
    setEngagementPageType('new');
  };

  useEffect(() => { 
    if (formType !== null && formType === 'new') {
      setEngagementPageType('new');
    }

    else if (selectedMeetingRequestId !== null) {
      setSelectedTab('tab-meeting-requests');
      setEngagementPageType('details');
    }
    else if (selectedShareholderCommunicationId !== null) {
      setSelectedTab('tab-shareholder-communications');
      setEngagementPageType('details');
    }
    else if (selectedMeetingNoteId !== null) {
      setSelectedTab('tab-meeting-notes');
      setEngagementPageType('edit');
    }
    else if (selectedOtherNoteId !== null) {
      setSelectedTab('tab-other-notes');
      setEngagementPageType('edit');
    }

    else {
      setEngagementPageType('list');
    }
  }, [formType, selectedMeetingRequestId, selectedShareholderCommunicationId, selectedMeetingNoteId, selectedOtherNoteId]);

  const newEngagementOptionsRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if(newEngagementOptionsRef.current && !newEngagementOptionsRef.current.contains(event.target)) {
        setShowNewEngagementOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [newEngagementOptionsRef]);

  useEffect(() => {
    if(clientAvailableModules["engagements"] === false) {
      navigate('/login');
    }
  }, [navigate, clientAvailableModules]);

  useEffect(() => {
    if(tabItems.length > 0) {
      setSelectedTab(tabItems[0]['id']);
    }
  }, [tabItems]);

  return (
    <div className={styles['page']}>
      {checkIsAccountManagerViewMode() &&
        <AccountManagerModeHeader
          type={pageStyle === 'wide' ? 'wide' : 'narrow'}
          clientName={getAuthClientName()}
          exitRedirecUrl='../clients'
        />
      }

      <SideNavigationBar
        menuItems={clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='engagements'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div
        className={`${styles['container']} ${styles[checkIsAccountManagerViewMode() ? 'with-account-manager-warning' : '']} ${styles[pageStyle]}`}
        style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
        ref={ref}
      >
        <Breadcrumbs items={breadcrumbItems} />

        { engagementPageType === 'list' &&
          <div className={styles['page-header']}>
            <div className={styles['section']}>
              <div className={styles['row']}>
                <div className={styles['title']}>
                  <h1>Engagements</h1>
                </div>
              </div>
            </div>
          </div>
        }

        <div className={`${styles['page-body']} ${!isPrimaryEditor ? styles['additonal-top-margin'] : ''}`}>
          { engagementPageType === 'list' &&
            <div className={styles['investor-list-buttons']}>  
              <div className={styles['right-buttons']}>
                {isPrimaryEditor && 
                  <Button type={'primary'} size={'base'} leftIcon={'plus'} onClick={() => setShowNewEngagementOptions(true)}>Create New</Button>
                }

                {false && 
                  <Button type={"cancel"} size={"base"} onClick={() => console.log("Not Implemented")}>Deactivated Engagements</Button>
                }
              </div>
              {showNewEngagementOptions &&
                <div className={styles['new-engagement-options']} ref={newEngagementOptionsRef}>
                  <div className={styles['option-container']}>
                    <div className={styles['avaliable-options']}>
                      {clientAvailableModules["engagement_meeting_requests"] === true &&
                        <div
                          className={styles['option']}
                          onClick={() => {
                            searchParams.delete('type');
                            setSearchParams(searchParams);

                            setSelectedTab('tab-meeting-requests');
                            setEngagementPageType('new');
                            setShowNewEngagementOptions(false);
                          }}
                        >
                          <p>Meeting Request</p>
                        </div>
                      }
                      {clientAvailableModules["engagement_shareholder_communications"] === true &&
                        <div
                          className={styles['option']}
                          onClick={() => {
                            searchParams.delete('type');
                            setSearchParams(searchParams);

                            setSelectedTab('tab-shareholder-communications');
                            setEngagementPageType('new');
                            setShowNewEngagementOptions(false);
                          }}
                        >
                          <p>Shareholder Communication</p>
                        </div>
                      }
                      {clientAvailableModules["engagement_meeting_notes"] === true &&
                        <div
                          className={styles['option']}
                          onClick={() => {
                            searchParams.delete('type');
                            setSearchParams(searchParams);

                            setSelectedTab('tab-meeting-notes');
                            setEngagementPageType('new');
                            setShowNewEngagementOptions(false);
                          }}
                        >
                          <p>Meeting Note</p>
                        </div>
                      }
                      {clientAvailableModules["engagement_other_notes"] === true &&
                        <div
                          className={styles['option']}
                          onClick={() => {
                            searchParams.delete('type');
                            setSearchParams(searchParams);

                            setSelectedTab('tab-other-notes');
                            setEngagementPageType('new');
                            setShowNewEngagementOptions(false);
                          }}
                        >
                          <p>Other Note</p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
          }

          { engagementPageType === 'list' &&
            <div className={styles['tab-container']}>
              <Tab items={tabItems} selectedTab={selectedTab} onTabChange={investorEngagementsTabChangeHandler} />
            </div>
          }

          { selectedTab === 'tab-meeting-requests' && 
            <EngagementsMeetingRequests
              width={width}
              pageType={engagementPageType}
              setPageType={setEngagementPageType}
              setBreadcrumbItems={setBreadcrumbItems}
              onCreateEngagementClick={createNewEngagementClickHandler}
              isViewOnly={isViewOnly}
              isPrimaryEditor={isPrimaryEditor}
            />
          }

          { selectedTab === 'tab-shareholder-communications' && 
            <EngagementsShareholderCommunications
              width={width}
              pageType={engagementPageType}
              setPageType={setEngagementPageType}
              setBreadcrumbItems={setBreadcrumbItems}
              onCreateEngagementClick={createNewEngagementClickHandler}
              isViewOnly={isViewOnly}
              isPrimaryEditor={isPrimaryEditor}
            />
          }

          { selectedTab === 'tab-meeting-notes' && 
            <EngagementsMeetingNotes
              width={width}
              pageType={engagementPageType}
              setPageType={setEngagementPageType}
              setBreadcrumbItems={setBreadcrumbItems}
              onCreateEngagementClick={createNewEngagementClickHandler}
              isViewOnly={isViewOnly}
              isPrimaryEditor={isPrimaryEditor}
            />
          }

          { selectedTab === 'tab-other-notes' && 
            <EngagementsOtherNotes
              width={width}
              pageType={engagementPageType}
              setPageType={setEngagementPageType}
              setBreadcrumbItems={setBreadcrumbItems}
              onCreateEngagementClick={createNewEngagementClickHandler}
              isViewOnly={isViewOnly}
              isPrimaryEditor={isPrimaryEditor}
            />
          }
        </div>
      </div>
    </div>
  );
}

export default EngagementListPage;

import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getPageStyle, updatePageStyle } from '../../../utils/helpers';
import { clientMenuItems } from '../../../utils/constants';
import { checkIsAccountManagerViewMode, getAuthClientId, getAuthClientName, getAuthUserProfilePictureUrl, getClientAvailableModules, setLocalStorageClientData } from '../../../utils/auth';
import { fetchClientBasicProfileRequest, fetchClientInvestorsRequest } from '../../../requests/client-requests';
import { fetchHyperSearchInvestorList, fetchHyperSearchReportUrl, fetchHyperSearchResults } from '../../../requests/esgds-requets';
import { generateDocuSearchResultSkeletonItems } from '../../../utils/skeleton-helpers';
import { handleRequestError } from '../../../utils/request-error-helpers';
import useInputValidation from '../../../hooks/use-input-validation';
import useHttp from '../../../hooks/use-http';

import Button from '../../../components/UI/Button/Button';
import IconButton from '../../../components/UI/IconButton/IconButton';
import TextInput from '../../../components/UI/TextInput/TextInput';
import MultiSelectComboBox from '../../../components/UI/MultiSelectComboBox/MultiSelectComboBox';

import AccountManagerModeHeader from '../../../components/AccountManagerModeHeader/AccountManagerModeHeader';
import SideNavigationBar from '../../../components/UI/SideNavigationBar/SideNavigationBar';
import DocuSearchResultItem from './DocuSearchResultItem';

import styles from './DocuSearchPage.module.css';

const DocuSearchPage = (props) => {
  const navigate = useNavigate();
  
  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const [searchParams, setSearchParams] = useSearchParams();
  const pageHeightMargin = checkIsAccountManagerViewMode() ? '161px' : '95px';
  /* eslint-disable no-unused-vars */

  const [isLoading, error, sendRequest] = useHttp();
  const [pageViewMode, setPageViewMode] = useState('skeleton');

  const clientId = getAuthClientId();
  const clientAvailableModules = getClientAvailableModules();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();

  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);

  const [searchKeyword, setSearchKeyword, isSearchKeywordValie, hasSearchKeywordError, searchKeywordChangeHandler, searchKeywordBlurHandler] =
    useInputValidation('', (searchKeyword) => searchKeyword.trim() !== '');

  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState(true);
  const [resultsPageNumber, setResultsPageNumber] = useState(1);
  const [highlightSearchKeyword, setHighlightSearchKeyword] = useState('');

  const [filterInvestorSearchValue, setFilterInvestorSearchValue] = useState('');
  const [selectedFilterInvestorItems, setSelectedFilterInvestorItems] = useState([]);
  const [selectedFilterInvestorTiles, setSelectedFilterInvestorTiles] = useState([]);
  const [clientAqtionInvestorList, setClientAqtionInvestorList] = useState([]);
  const [clientAqtionInvestorMap, setClientAqtionInvestorMap] = useState([]);
  
  const [isSearchCompleted, setIsSearchCompleted] = useState(false);
  const [docuSearchResults, setDocuSearchResults] = useState([]);
  const [docuSearchResultsTotalCount, setDocuSearchResultsTotalCount] = useState(0);
  const [isShowPagination, setIsShowPagination] = useState(false);

  
  /* Creates combobox values with the using the client's Aqtion investors. */
  const comboBoxClientInvestorGroups = [
    {
      'id': 'client-investors-aqtion',
      'title': 'AQTION Investors',
      'isClickable': false,
      'items': clientAqtionInvestorList.map(item => {
        return {
          'id': item['id'],
          'text': item['title']
        };
      })
    }
  ];

  /* Adds an investors to the `Investor` area table or removes
   * it from there according the value of the clicked checkbox. */
  const filterInvestorItemClickHandler = (investorId) => {
    const operation = selectedFilterInvestorItems.indexOf(investorId) > -1 ? 'remove' : 'add';
    setSelectedFilterInvestorItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const itemIndex = items.indexOf(investorId);
      if (itemIndex > -1) {
        items.splice(itemIndex, 1);
      }
      else {
        items.push(investorId);
      }
      return items;
    });

    if (operation === 'add') {
      setSelectedFilterInvestorTiles(prevSelectedFilterInvestorTiles => {
        const itemIndex = clientAqtionInvestorList.map(item => item['id']).indexOf(investorId);
        const newSelectedFilterInvestorTiles = JSON.parse(JSON.stringify(prevSelectedFilterInvestorTiles));
        newSelectedFilterInvestorTiles.push(clientAqtionInvestorList[itemIndex]);
        return newSelectedFilterInvestorTiles;
      });
    }
    else if (operation === 'remove') {
      setSelectedFilterInvestorTiles(prevSelectedFilterInvestorTiles => {
        const newSelectedFilterInvestorTiles = JSON.parse(JSON.stringify(prevSelectedFilterInvestorTiles));
        const itemIndex = newSelectedFilterInvestorTiles.map(item => item['id']).indexOf(investorId);
        newSelectedFilterInvestorTiles.splice(itemIndex, 1);
        return newSelectedFilterInvestorTiles;
      });
    }
  }
  
  /* Generates selected investor tiles for the `Investor` area. */
  const generateSelectedFilterInvestorTiles = () => {
    return selectedFilterInvestorTiles.map(item => {
      return (
        <div key={`added-filter-investor-${item['id']}`} className={styles['added-filter-investor']}>
          <div className={styles['added-filter-investor-text']}>
            <h3>{item['title']}</h3>
          </div>
          <div className={styles['remove-button']} onClick={() => removeSelectedFilterInvestorItemHandler(item)}></div>
        </div>
      );
    });
  }

  /* Renoves selected investor from the `Investor` area. */
  const removeSelectedFilterInvestorItemHandler = (clickedItem) => { 
    filterInvestorItemClickHandler(clickedItem['id']);
  }

  const generateReportItems = () => {
    return docuSearchResults.map(resultItem => {
      resultItem['investorUrl'] = `./investors/${clientAqtionInvestorMap[resultItem['investorName'].toLowerCase()]}`;
      return (
        <DocuSearchResultItem 
          key={`docusearch-report-${resultItem['fileIdentifier']}`}
          report={resultItem}
          searchKeyword={highlightSearchKeyword}
          onClickDownloadFile={downloadReportFileHandler}
          pageViewMode={pageViewMode}
        />
      );
    })
  }

  const generatePagination = () => {
    const count = docuSearchResultsTotalCount;
    const numberOfPages = parseInt(Math.ceil(count / 50));

    let pageNumbers = [];
    if (numberOfPages < 7) {
      pageNumbers = Array.from({length: numberOfPages}, (_, i) => i + 1);
    }
    else {
      pageNumbers.push(1);
      if (resultsPageNumber < 5) {
        pageNumbers = pageNumbers.concat([2, 3, 4, 5, '...']);
      }
      else if (resultsPageNumber >= 5 && (numberOfPages-resultsPageNumber) > 3){
        pageNumbers = pageNumbers.concat(['...', (resultsPageNumber - 1), resultsPageNumber, (resultsPageNumber + 1), '...']);
      }
      else {
        pageNumbers = pageNumbers.concat(['...', (numberOfPages-4), (numberOfPages-3), (numberOfPages-2), (numberOfPages-1)]);
      }
      pageNumbers.push(numberOfPages);
    }
  
    const paginationPageItems = [];
    paginationPageItems.push(
      <div className={styles['first-page-button']} key='pagination-button-prev'>
        <IconButton type='cancel' size='small' icon='caret-left'
          isDisabled={resultsPageNumber === 1 ? true : false}
          onClick={() => clickSearchButtonHandler(resultsPageNumber - 1)} />
      </div>
    );
    for (let i=0; i<pageNumbers.length; i++) {
      const isSelectedClass = (pageNumbers[i] === resultsPageNumber) ? `${styles['selected']}` : '';
      const isThreeDotsClass = (pageNumbers[i] === '...') ? `${styles['three-dots']}` : '';
      paginationPageItems.push(
        <div 
          key={`pagination-button-${i}-${pageNumbers[i]}`}
          className={`${styles['pagination-number']} ${isSelectedClass} ${isThreeDotsClass}`}
          onClick={(pageNumbers[i] !== '...') ? () => clickSearchButtonHandler(pageNumbers[i]) : () => {}}
        >
          <p>{pageNumbers[i]}</p>
        </div>
      );
    }
    paginationPageItems.push(
      <div className={styles['last-page-button']} key='pagination-button-next'>
        <IconButton type='cancel' size='small' icon='caret-right'
          isDisabled={resultsPageNumber === numberOfPages ? true : false}
          onClick={() => clickSearchButtonHandler(resultsPageNumber + 1)} />
      </div>
    );
    return paginationPageItems;
  }

  const searchKeywordChangeHandlerWrapper = (enteredValue) => {
    setIsSearchButtonDisabled(enteredValue === '');
    searchKeywordChangeHandler(enteredValue);
  }

  const clickSearchButtonHandler = (pageNumber) => {
    const nowDateParts = (new Date()).toLocaleDateString('en-GB').split('/');
    const body = {
      'search_query': searchKeyword,
      'publication_date_start': '2020-01-01',
      'publication_date_end': `${nowDateParts[2]}-${nowDateParts[1]}-${nowDateParts[0]}`,
      'results_page': pageNumber
    }
    
    if(selectedFilterInvestorItems.length > 0) {
      body['filtered_companies'] = selectedFilterInvestorItems;
    }
    else {
      body['filtered_companies'] = clientAqtionInvestorList.map(item => item['id']);
    }

    setDocuSearchResults(generateDocuSearchResultSkeletonItems());
    // setIsSearchCompleted(false);
    setIsSearchCompleted(true);
    setResultsPageNumber(pageNumber);
    setPageViewMode('skeleton');
    
    sendRequest(
      fetchHyperSearchResults(body),
      (data) => {
        setHighlightSearchKeyword(searchKeyword);
        setIsSearchCompleted(true);
        setDocuSearchResults(data['reports']);
        setDocuSearchResultsTotalCount(data['count']);
        setIsShowPagination(data['count'] > 0);

        setTimeout(() => {
          setPageViewMode('normal');
        }, 200);
      }
    );
  }

  const downloadReportFileHandler = (reportId, pageNumber) => {
    const queryParams = (pageNumber !== undefined && pageNumber !== null) ? `page_number=${pageNumber}` : '';
    sendRequest(
      fetchHyperSearchReportUrl(reportId, queryParams),
      (data) => {
        window.open(data['url'], '_blank');
      }
    );
  }

  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (dataClient) => {
        setLocalStorageClientData(dataClient);
        setClientProfilePictureUrl(dataClient['profile_picture_url']);

        if(dataClient['module_management']['docusearch']) {
          sendRequest(
            fetchClientInvestorsRequest(clientId),
            (dataClientInvestors) => {
              sendRequest(
                fetchHyperSearchInvestorList(),
                (dataDocuSearchInvestors) => {
                  const docuSearchInvestorList = dataDocuSearchInvestors['investor_key_id_map'];
                  setClientAqtionInvestorList(
                    dataClientInvestors['items'].filter(item => item['is_custom'] !== true && item['name'].toLowerCase() in docuSearchInvestorList).map(item => {
                      return {
                        'id': docuSearchInvestorList[item['name'].toLowerCase()],
                        'title': item['name']
                      };
                    })
                  );
                  
                  const tempInvestorMap = {};
                  dataClientInvestors['items'].filter(item => item['is_custom'] !== true && item['name'].toLowerCase() in docuSearchInvestorList).forEach(item => {
                    tempInvestorMap[item['name'].toLowerCase()] = item['id'];
                  })
                  setClientAqtionInvestorMap(tempInvestorMap);
                }
              );
            }
          );
        }
        else {
          if(dataClient['module_management']['dashboard']) {
            window.location.href = 'dashboard';
          }
          else {
            window.location.href = 'investors';
          }
        }
      }
    );
  }, [sendRequest, clientId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  useEffect(() => {
    if(clientAvailableModules["docusearch"] === false) {
      navigate('/login');
    }
  }, [navigate, clientAvailableModules]);

  return (
    <div className={styles['page']}>
      {checkIsAccountManagerViewMode() &&
        <AccountManagerModeHeader
          type={pageStyle === 'wide' ? 'wide' : 'narrow'}
          clientName={getAuthClientName()}
          exitRedirecUrl='../clients'
        />
      }

      <SideNavigationBar
        menuItems={clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='docusearch'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div
        className={`${styles['container']} ${styles[checkIsAccountManagerViewMode() ? 'with-account-manager-warning' : '']} ${styles[pageStyle]}`}
        style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
        ref={ref}
      >
        <div className={styles['page-header']}>
          <div className={styles['section']}>
            <div className={styles['row']}>
              <div className={styles['title']}>
                <h1>DocuSearch</h1>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['docusearch-query-container']}>
          <div className={styles['row']}>
            <div className={styles['investor-filter']}>
              <MultiSelectComboBox
                id='docusearch-filter-investor-combobox'
                width={384}
                size='base'
                label='Investor(s)'
                itemGroups={comboBoxClientInvestorGroups}
                alwaysShowItems={false}
                hasSearchIcon={false}
                hasHeaderCover={false}
                value={filterInvestorSearchValue}
                placeholder='Select Investor(s)'
                selected={selectedFilterInvestorItems}
                onChange={setFilterInvestorSearchValue}
                onItemClick={filterInvestorItemClickHandler}
                autoFocus={false}
                hasAddCustomButton={false}
                isDisabled={false}
              />
            </div>
            <div className={styles['search-keyword']}>
              <TextInput
                id='docusearch-search-keyword'
                width={576}
                label='Search'
                placeholder='Please enter a keyword'
                value={searchKeyword}
                hasError={hasSearchKeywordError}
                onBlur={searchKeywordBlurHandler}
                onChange={searchKeywordChangeHandlerWrapper}
                isDisabled={false}
              />
            </div>
            <div className={styles['search-button']}>
              <Button
                type={'primary'}
                size={'base'}
                onClick={() => clickSearchButtonHandler(1)}
                isDisabled={isSearchButtonDisabled}
              >
                Search
              </Button>
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['added-filter-investors-container']}>
              { selectedFilterInvestorTiles.length === 0 &&
                <div className={styles['note']}>
                  <p>Unless specific investors are selected, search results will show content from all.</p>
                </div>
              }
              { selectedFilterInvestorTiles.length > 0 &&
                generateSelectedFilterInvestorTiles()
              }
            </div>
          </div>
        </div>

        { isSearchCompleted === true && docuSearchResults.length === 0 &&
          <div className={styles['no-results-div']}>
            <div className={styles['icon-container']}>
              <div className={styles['icon']}></div>
            </div>
            <div className={styles['text-container']}>
              <h4>No Results Found</h4>
              <p>Please update your query and investor filters and try again.</p>
            </div>
          </div>
        }

        { isSearchCompleted === true && docuSearchResults.length > 0 &&
          <div className={styles['docusearch-results-container']}>
            <div className={styles['query-title']}>
              <h3>Search Results:</h3>
            </div>
            <div className={styles['report-list']}>
              {generateReportItems()}
            </div>
          </div>
        }

        { isShowPagination &&
          <div className={styles['docusearch-pagination-container']}>
            <div className={styles['pagination-items']}>
              {generatePagination()}
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default DocuSearchPage;
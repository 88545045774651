import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { clientMenuItems } from '../../../utils/constants';
import { fetchClientBasicProfileRequest, fetchClientProxyAdvisorsRequest } from '../../../requests/client-requests';
import { generateClientProxyAdvisorListTableItems } from '../../../utils/client-proxy-advisor-helpers';
import { generateClientProxyAdvisorListSkeletonTableItems } from '../../../utils/skeleton-helpers';
import { checkIsAccountManagerViewMode, getAuthClientId, getAuthClientName, getAuthUserProfilePictureUrl, getClientAvailableModules, getClientType, setLocalStorageClientData } from '../../../utils/auth';
import { createId, getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { handleRequestError } from '../../../utils/request-error-helpers';
import { useNavigate } from 'react-router-dom';
import useHttp from '../../../hooks/use-http';

import AccountManagerModeHeader from '../../../components/AccountManagerModeHeader/AccountManagerModeHeader';
import SideNavigationBar from '../../../components/UI/SideNavigationBar/SideNavigationBar';

import Table from '../../../components/UI/Table/Table';

import styles from './ProxyAdvisorListPage.module.css';

const ProxyAdvisorListPage = (props) => {
  const navigate = useNavigate();
  
  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const pageHeightMargin = checkIsAccountManagerViewMode() ? '161px' : '95px';
  /* eslint-disable no-unused-vars */

  const clientId = getAuthClientId();
  const clientAvailableModules = getClientAvailableModules();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();
  
  const [isLoading, error, sendRequest] = useHttp();
  const [pageViewMode, setPageViewMode] = useState('skeleton');

  const [clientType, setClientType] = useState(getClientType());
  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);
  const [tableItems, setTableItems] = useState(null);
  const [proxyAdvisorListData, setProxyAdvisorListData] = useState([]);

  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (dataClient) => {
        setLocalStorageClientData(dataClient);
        setClientType(dataClient['client_type']);
        setClientProfilePictureUrl(dataClient['profile_picture_url']);

        sendRequest(
          fetchClientProxyAdvisorsRequest(clientId),
          (dataProxyAdvisors) => {
            setProxyAdvisorListData(dataProxyAdvisors['items']);
            setTimeout(() => {
              setPageViewMode('normal');
            }, 200);
          }
        );
      }
    );
  }, [clientId, sendRequest]);
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  useEffect(() => {
    setTableItems(generateClientProxyAdvisorListTableItems(clientType, proxyAdvisorListData));
  }, [clientType, proxyAdvisorListData]);

  useEffect(() => {
    if(clientAvailableModules["proxy_advisors"] === false) {
      navigate('/login');
    }
  }, [navigate, clientAvailableModules]);

  return (
    <div className={styles['page']}>
      {checkIsAccountManagerViewMode() &&
        <AccountManagerModeHeader
          type={pageStyle === 'wide' ? 'wide' : 'narrow'}
          clientName={getAuthClientName()}
          exitRedirecUrl='../clients'
        />
      }

      <SideNavigationBar
        menuItems={clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='proxy-advisors'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div 
        id='page-right-container'
        className={`${styles['container']} ${styles[checkIsAccountManagerViewMode() ? 'with-account-manager-warning' : '']} ${styles[pageStyle]}`}
        style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
        ref={ref}
      >
        <div className={styles['page-header']}>
          <div className={styles['section']}>
            <div className={styles['row']}>
              <div className={styles['title']}>
                <h1>Proxy Advisors & Ratings</h1>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['page-body']} >
          <div className={styles['proxy-advisor-list-container']}>
            <Table
              width={isNaN(width) ? 1400 : (width - 2)}
              items={
                pageViewMode === 'skeleton' ?
                generateClientProxyAdvisorListSkeletonTableItems(clientType, Array.from({length: 5}, () => createId(4))) :
                tableItems
              }
              isScrollable={true}
              hasDynamicScroll={true}
              viewMode={pageViewMode}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProxyAdvisorListPage;

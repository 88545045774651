import SectionInfoBox from '../SectionInfoBox/SectionInfoBox';

import summaryPageStyles from '../ProxyAdvisorSummary.module.css';

const ProxyAdvisorSummaryNotesSection = (props) => {
  return (
    <div className={summaryPageStyles['section']}>
      <div className={summaryPageStyles['section-title']}>
        <h3>Notes</h3>
      </div>

      <div className={summaryPageStyles['content']}>
        <SectionInfoBox
          id='proxy-advisor#website'
          size='full-size-col'
          height='long'
          type='rich-text-block'
          value={props.notesData['esg_comment']}
        />
      </div>
    
      <div className={summaryPageStyles['separator']}></div>
    </div>
  );
}

export default ProxyAdvisorSummaryNotesSection;

import { Fragment, useCallback, useEffect, useState } from "react";
import { generateClientInvestorsMeetingNotesTableItems, generateClientProxyAdvisorsMeetingNotesTableItems } from "../../../../utils/client-engagement-helpers";
import { createClientEngagementsMeetingNoteRequest, listClientEngagementsMeetingNotesRequest, updateClientEngagementsMeetingNoteRequest, uploadClientEngagementsMeetingNoteAttachmentRequest } from "../../../../requests/client-engagement-requests";
import { getAuthClientId } from "../../../../utils/auth";
import { useSearchParams } from "react-router-dom";
import { handleRequestError } from "../../../../utils/request-error-helpers";
import useHttp from "../../../../hooks/use-http";
import sortArray from 'sort-array';

import Button from "../../../../components/UI/Button/Button";
import Table from "../../../../components/UI/Table/Table";

import EngagementMeetingNoteCreateForm from "../../../../components/EngagementForm/EngagementMeetingNoteForm/EngagementMeetingNoteCreateForm";
import EngagementMeetingNoteUpdateForm from "../../../../components/EngagementForm/EngagementMeetingNoteForm/EngagementMeetingNoteUpdateForm";

import styles from '../EngagementListPage.module.css';

const EngagementsMeetingNotes = (props) => {

  const clientId = getAuthClientId();
  const pageType = props.pageType;
  const setPageType = props.setPageType;
  const isViewOnly = props.isViewOnly;
  const isPrimaryEditor = props.isPrimaryEditor;
  
  const [isLoading, error, sendRequest] = useHttp();
  const [searchParams, setSearchParams] = useSearchParams();

  const [investorsMeetingNotesData, setInvestorsMeetingNotesData] = useState([]);
  const [investorsMeetingNotesTableItems, setInvestorsMeetingNotesTableItems] = useState(null);

  const [proxyAdvisorsMeetingNotesData, setProxyAdvisorsMeetingNotesData] = useState([]);
  const [proxyAdvisorsMeetingNotesTableItems, setProxyAdvisorsMeetingNotesTableItems] = useState(null);

  const [selectedMeetingNoteId, setSelectedMeetingNoteId] = useState(searchParams.get('meeting_note_id'));

  let searchParamsType = null;
  if (searchParams.get('type') === 'investor') {
    searchParamsType = 'type-investor'
  }
  else if (searchParams.get('type') === 'proxy-advisor') {
    searchParamsType = 'type-proxy-advisor'
  }
  const [selectedMeetingNoteInvestorType, setSelectedMeetingNoteInvestorType] = useState(searchParamsType);

  const [investorEngagementsMeetingNotesTableSortConfig, setInvestorEngagementsMeetingNotesTableSortConfig] = useState({
    'by': ['note_header'],
    'order': ['asc'],
    'computed': {
      'investor_name': meeting_note => meeting_note['investor']['name']
    }
  });

  const investorEngagementsMeetingNotesSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setInvestorEngagementsMeetingNotesTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setInvestorsMeetingNotesData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, investorEngagementsMeetingNotesTableSortConfig);
    });
  }, [investorEngagementsMeetingNotesTableSortConfig]);

  const [proxyAdvisorEngagementsMeetingNotesTableSortConfig, setProxyAdvisorEngagementsMeetingNotesTableSortConfig] = useState({
    'by': ['note_header'],
    'order': ['asc'],
    'computed': {
      'proxy_advisor_name': meeting_note => meeting_note['proxy_advisor']['name']
    }
  });

  const proxyAdvisorEngagementsMeetingNotesSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setProxyAdvisorEngagementsMeetingNotesTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setProxyAdvisorsMeetingNotesData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, proxyAdvisorEngagementsMeetingNotesTableSortConfig);
    });
  }, [proxyAdvisorEngagementsMeetingNotesTableSortConfig]);

  
  const investorClickHandler = (investorId) => {
    window.location.href = `../investors/${investorId}`;
  }

  const proxyAdvisorClickHandler = (proxyAdvisorId) => {
    window.location.href = `../proxy-advisors/${proxyAdvisorId}`;
  }

  /* Show meeting note details. */
  const meetingNoteClickHandler = useCallback((meetingNoteId, meetingNoteInvestorType) => {
    let searchParamsType = '';
    if (meetingNoteInvestorType === 'type-investor') {
      searchParamsType = 'investor'
    }
    else if (meetingNoteInvestorType === 'type-proxy-advisor') {
      searchParamsType = 'proxy-advisor'
    }
    setSearchParams({'meeting_note_id' : meetingNoteId, 'type': searchParamsType});
    setPageType('edit');
    setSelectedMeetingNoteId(meetingNoteId);
    setSelectedMeetingNoteInvestorType(meetingNoteInvestorType);
  }, [setPageType, setSearchParams]);

  /* Upload attachment for the meeting note. */
  const uploadClientMeetingNoteAttachmentHandler = (attachmentData, attachmentSection, setAttachments) => {
    const meetingNoteId = selectedMeetingNoteId !== null ? selectedMeetingNoteId : 'temp';
    sendRequest(
      uploadClientEngagementsMeetingNoteAttachmentRequest(clientId, meetingNoteId, attachmentData),
      (data) => { 
        setAttachments(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          data['attachment_section'] = attachmentSection;
          items.push(data)
          return items;
        });
      }
    );
  }

  /* Create new meeting note. */
  const clientMeetingNoteCreateHandler = (meetingNoteData) => {
    const createClientEngagementsMeetingNoteResponse = (data) => {
      if (data['investor_type'] === 'Investor') {
        setInvestorsMeetingNotesData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          items.push(data)
          return items;
        });
      }
      else if (data['investor_type'] === 'Proxy Advisor') {
        setProxyAdvisorsMeetingNotesData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          items.push(data)
          return items;
        });
      }
      setPageType('list');
    }
    sendRequest(
      createClientEngagementsMeetingNoteRequest(clientId, meetingNoteData),
      createClientEngagementsMeetingNoteResponse
    );
  }

  /* Update selected meeting note. */
  const clientMeetingNoteEditHandler = (meetingNoteData, setMeetingNoteData) => {
    const updateClientEngagementsMeetingNoteResponse = (data) => {
      if (data['investor_type'] === 'Investor') {
        setInvestorsMeetingNotesData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          const meetingNoteIndex = items.findIndex(item => item['id'] === parseInt(selectedMeetingNoteId));
          items[meetingNoteIndex] = data;
          return items;
        });
      }
      else if (data['investor_type'] === 'Proxy Advisor') {
        setProxyAdvisorsMeetingNotesData(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          const meetingNoteIndex = items.findIndex(item => item['id'] === parseInt(selectedMeetingNoteId));
          items[meetingNoteIndex] = data;
          return items;
        });
      }
      setMeetingNoteData(data);
    }
    sendRequest(
      updateClientEngagementsMeetingNoteRequest(clientId, selectedMeetingNoteId, meetingNoteData),
      updateClientEngagementsMeetingNoteResponse
    );
  }

  /* Return back to meeting notes table. */
  const returnToMeetingNotesClickHandler = () => {
    setSelectedMeetingNoteId(null);
    setSelectedMeetingNoteInvestorType(null);
    setPageType('list');
  }

  
  /* Send a request to fetch meeting note items and set the list with the response. */
  useEffect(() => {
    sendRequest(
      listClientEngagementsMeetingNotesRequest(clientId),
      (data) => { 
        setInvestorsMeetingNotesData(data['items'].filter(item => item['investor_type'] === 'Investor'));
        setProxyAdvisorsMeetingNotesData(data['items'].filter(item => item['investor_type'] === 'Proxy Advisor'));
      }
    );
  }, [sendRequest, clientId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  /* Generate investor meeting notes table and proxy advisors meeting notes table. */
  useEffect(() => {
    setInvestorsMeetingNotesTableItems(
      generateClientInvestorsMeetingNotesTableItems(
        investorsMeetingNotesData, meetingNoteClickHandler, investorClickHandler, investorEngagementsMeetingNotesTableSortConfig, investorEngagementsMeetingNotesSortHandler
      )
    );
  }, [investorsMeetingNotesData, investorEngagementsMeetingNotesTableSortConfig, meetingNoteClickHandler]);

  /* */
  useEffect(() => {
    setProxyAdvisorsMeetingNotesTableItems(
      generateClientProxyAdvisorsMeetingNotesTableItems(
        proxyAdvisorsMeetingNotesData, meetingNoteClickHandler, proxyAdvisorClickHandler , proxyAdvisorEngagementsMeetingNotesTableSortConfig, proxyAdvisorEngagementsMeetingNotesSortHandler
      )
    );
  }, [proxyAdvisorsMeetingNotesData, proxyAdvisorEngagementsMeetingNotesTableSortConfig, meetingNoteClickHandler]);


  return (
    <Fragment>
      { !isLoading && pageType === 'list' && proxyAdvisorsMeetingNotesTableItems && proxyAdvisorsMeetingNotesTableItems['middle']['body']['items'].length > 0 &&
        <div className={styles['engagement-meeting-note-list-container']}>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={proxyAdvisorsMeetingNotesTableItems} isScrollable={false} />
        </div>
      }

      { !isLoading && pageType === 'list' && investorsMeetingNotesTableItems && investorsMeetingNotesTableItems['middle']['body']['items'].length > 0 &&
        <div className={styles['engagement-meeting-note-list-container']}>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={investorsMeetingNotesTableItems} isScrollable={false} />
        </div>
      }

      { !isLoading && pageType === 'list' && proxyAdvisorsMeetingNotesTableItems && proxyAdvisorsMeetingNotesTableItems['middle']['body']['items'].length === 0 && investorsMeetingNotesTableItems && investorsMeetingNotesTableItems['middle']['body']['items'].length === 0 &&
        <div className={styles['no-enagements-div']}>
          <div className={styles['icon-container']}>
            <div className={styles['icon']}></div>
          </div>
          {isPrimaryEditor &&
            <Fragment>
              <div className={styles['text-container']}>
                <h4>You Have No Meeting Notes</h4>
                <p>Use below button to Create a Meeting Note</p>
              </div>
              <div className={styles['button-container']}>
                <Button
                  type={"primary"}
                  leftIcon={"plus"}
                  size={"xsmall"}
                  onClick={props.onCreateEngagementClick}>
                    Create Meeting Note
                </Button>
              </div>
            </Fragment>
          }
          {!isPrimaryEditor &&
            <div className={styles['text-container']}>
              <h4>You Have No Meeting Notes</h4>
            </div>
          }
        </div>
      }

      { pageType === 'new' &&
        <EngagementMeetingNoteCreateForm
          investorType='type-investor'
          isInvestorTypeEditable={true}
          hasFormTitle={true}
          onAttachmentUploadClick={uploadClientMeetingNoteAttachmentHandler}
          onSaveClick={clientMeetingNoteCreateHandler}
          onCancel={returnToMeetingNotesClickHandler}
        />
      }

      { pageType === 'edit' && selectedMeetingNoteId &&
        <EngagementMeetingNoteUpdateForm
          meetingNoteId={selectedMeetingNoteId}
          investorType={selectedMeetingNoteInvestorType}
          isInvestorTypeEditable={true}
          hasBackButton={false}
          onAttachmentUploadClick={uploadClientMeetingNoteAttachmentHandler}
          onSaveClick={clientMeetingNoteEditHandler}
          onBackClick={returnToMeetingNotesClickHandler}
          setBreadcrumbItems={props.setBreadcrumbItems}
          isViewOnly={isViewOnly}
        />
      }
    </Fragment>
  );
}

export default EngagementsMeetingNotes;

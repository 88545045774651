import { useEffect, useMemo, useState } from "react";
import { getClientAvailableModules } from "../../../../utils/auth";

import Tab from "../../../../components/UI/Tab/Tab";

import ProxyAdvisorEngagementsOtherNotes from "./ProxyAdvisorEngagementsOtherNotes/ProxyAdvisorEngagementsOtherNotes";
import ProxyAdvisorEngagementsMeetingNotes from "./ProxyAdvisorEngagementsMeetingNotes/ProxyAdvisorEngagementsMeetingNotes";
import ProxyAdvisorEngagementsMeetingRequests from "./ProxyAdvisorEngagementsMeetingRequests/ProxyAdvisorEngagementsMeetingRequests";
import ProxyAdvisorEngagementsShareholderCommunications from "./ProxyAdvisorEngagementsShareholderCommunications/ProxyAdvisorEngagementsShareholderCommunications";

import styles from './ProxyAdvisorEngagements.module.css';

const ProxyAdvisorEngagements = (props) => {
  const [tabItems, setTabItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState('tab-meeting-requests');
  const [showEngagementsTab, setShowEngagementsTab] = useState(true);

  const proxyAdvisorEngagementsTabItems = useMemo(() => [
    {
      'id': 'tab-meeting-requests',
      'text': 'Meeting Requests'
    },
    {
      'id': 'tab-shareholder-communications',
      'text': 'Shareholder Communications'
    },
    {
      'id': 'tab-meeting-notes',
      'text': 'Meeting Notes'
    },
    {
      'id': 'tab-other-notes',
      'text': 'Other Notes'
    }
  ], []);

  const proxyAdvisorEngagementsTabChangeHandler = (selectedTabId) => {
    setSelectedTab(selectedTabId)
  }

  useEffect(() => {
    const filteredTabItems = [];
    const clientAvailableModules = getClientAvailableModules();
    if(!clientAvailableModules['engagement_meeting_requests']) {
      filteredTabItems.push('tab-meeting-requests');
    }
    if(!clientAvailableModules['engagement_shareholder_communications']) {
      filteredTabItems.push('tab-shareholder-communications');
    }
    if(!clientAvailableModules['engagement_meeting_notes']) {
      filteredTabItems.push('tab-meeting-notes');
    }
    if(!clientAvailableModules['engagement_other_notes']) {
      filteredTabItems.push('tab-other-notes');
    }
    setTabItems(proxyAdvisorEngagementsTabItems.filter(item => !filteredTabItems.includes(item['id'])));
  }, [proxyAdvisorEngagementsTabItems]);

  useEffect(() => {
    if(tabItems.length > 0) {
      proxyAdvisorEngagementsTabChangeHandler(tabItems[0]['id']);
    }
  }, [tabItems]);

  return (
    <div>
      {showEngagementsTab &&
        <div className={styles['tab-container']}>
          <Tab
            items={tabItems}
            selectedTab={selectedTab}
            type='second-level'
            onTabChange={proxyAdvisorEngagementsTabChangeHandler}
          />
        </div>
      }

      <div className={styles['table-container']}>
        { selectedTab === 'tab-meeting-requests' &&
          <ProxyAdvisorEngagementsMeetingRequests
            width={props.width}
            proxyAdvisorId={props.proxyAdvisorId}
            isViewOnly={props.isViewOnly}
          />
        }
        { selectedTab === 'tab-shareholder-communications' &&
          <ProxyAdvisorEngagementsShareholderCommunications
            width={props.width}
            proxyAdvisorId={props.proxyAdvisorId}
            isViewOnly={props.isViewOnly}
          />
        }
        { selectedTab === 'tab-meeting-notes' && 
          <ProxyAdvisorEngagementsMeetingNotes
            width={props.width}
            proxyAdvisorId={props.proxyAdvisorId}
            isViewOnly={props.isViewOnly}
            setShowEngagementsTab={setShowEngagementsTab}
          />
        }
        { selectedTab === 'tab-other-notes' && 
          <ProxyAdvisorEngagementsOtherNotes
            width={props.width}
            proxyAdvisorId={props.proxyAdvisorId}
            isViewOnly={props.isViewOnly}
            setShowEngagementsTab={setShowEngagementsTab}
          />
        }
      </div>
    </div>
  );
}

export default ProxyAdvisorEngagements;

import { Fragment, useCallback, useEffect, useState } from "react";
import { generateClientInvestorEngagementsMeetingNotesTableItems } from "../../../../../utils/client-investor-helpers";
import { createClientEngagementsMeetingNoteRequest, listClientEngagementsMeetingNotesRequest, updateClientEngagementsMeetingNoteRequest, uploadClientEngagementsMeetingNoteAttachmentRequest } from "../../../../../requests/client-engagement-requests";
import { getAuthClientId } from "../../../../../utils/auth";
import useHttp from "../../../../../hooks/use-http";
import sortArray from 'sort-array';

import Button from "../../../../../components/UI/Button/Button";
import Table from "../../../../../components/UI/Table/Table";

import EngagementMeetingNoteCreateForm from "../../../../../components/EngagementForm/EngagementMeetingNoteForm/EngagementMeetingNoteCreateForm";
import EngagementMeetingNoteUpdateForm from "../../../../../components/EngagementForm/EngagementMeetingNoteForm/EngagementMeetingNoteUpdateForm";

import styles from '../InvestorEngagements.module.css';
import { handleRequestError } from "../../../../../utils/request-error-helpers";

const InvestorEngagementsMeetingNotes = (props) => {

  const clientId = getAuthClientId();
  const investorId = props.investorId;
  const isPrimaryEditor = props.isPrimaryEditor;
  const setShowEngagementsTab = props.setShowEngagementsTab;

  const [isLoading, error, sendRequest] = useHttp();

  const [investorEngagementsMeetingNotesData, setInvestorEngagementsMeetingNotesData] = useState([]);
  const [tableItems, setTableItems] = useState(null);

  const [selectedMeetingNoteId, setSelectedMeetingNoteId] = useState(null);
  const [showMeetingNoteForm, setShowMeetingNoteForm] = useState(false);
  const [meetingNoteFormType, setMeetingNoteFormType] = useState('new');

  const [tableSortConfig, setTableSortConfig] = useState({
    'by': ['header'],
    'order': ['asc']
  });

  const investorEnagementsMeetingNotesSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  /* Open new meeting note creation form. */
  const addNewMeetingNoteClickHandler = () => {
    setShowEngagementsTab(false);
    setMeetingNoteFormType('new');
    setShowMeetingNoteForm(true);
  };

  /* Upload attachment for the meeting note. */
  const uploadClientMeetingNoteAttachmentHandler = (attachmentData, attachmentSection, setAttachments) => {
    const meetingNoteId = selectedMeetingNoteId !== null ? selectedMeetingNoteId : 'temp';
    sendRequest(
      uploadClientEngagementsMeetingNoteAttachmentRequest(clientId, meetingNoteId, attachmentData),
      (data) => { 
        setAttachments(prevState => {
          const items = JSON.parse(JSON.stringify(prevState));
          data['attachment_section'] = attachmentSection;
          items.push(data)
          return items;
        });
      }
    );
  }

  /* Create new meeting note. */
  const clientMeetingNoteCreateHandler = (meetingNoteData) => {
    const createClientEngagementsMeetingNoteResponse = (data) => {
      setInvestorEngagementsMeetingNotesData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        items.push(data)
        return items;
      });
      setShowMeetingNoteForm(false);
      setShowEngagementsTab(true);
    }
    sendRequest(
      createClientEngagementsMeetingNoteRequest(clientId, meetingNoteData),
      createClientEngagementsMeetingNoteResponse
    );
  }

  /* Update selected meeting note. */
  const clientMeetingNoteEditHandler = (meetingNoteData, setMeetingNoteData) => {
    const updateClientEngagementsMeetingNoteResponse = (data) => {
      setInvestorEngagementsMeetingNotesData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        const meetingNoteIndex = items.findIndex(item => item['id'] === parseInt(selectedMeetingNoteId));
        items[meetingNoteIndex] = data;
        return items;
      });
      setMeetingNoteData(data);
    }
    sendRequest(
      updateClientEngagementsMeetingNoteRequest(clientId, selectedMeetingNoteId, meetingNoteData),
      updateClientEngagementsMeetingNoteResponse
    );
  }

  /* Show meeting note details. */
  const meetingNoteClickHandler = useCallback((clickedItemId) => {
    const meetingNoteId = clickedItemId.split('client-investor-engagements-meeting-note-')[1].split('#')[0];
    setShowEngagementsTab(false);
    setMeetingNoteFormType('edit');
    setShowMeetingNoteForm(true);
    setSelectedMeetingNoteId(meetingNoteId);
  }, [setShowEngagementsTab]);

  /* Return back to meeting notes table. */
  const returnToMeetingNotesClickHandler = () => {
    setShowMeetingNoteForm(false);
    setShowEngagementsTab(true);
    setSelectedMeetingNoteId(null);
  }

  /* Send a request to fetch meeting note items and set the list with the response. */
  useEffect(() => {
    const queryParams = `investor_id=${investorId}`;
    sendRequest(
      listClientEngagementsMeetingNotesRequest(clientId, queryParams),
      (data) => { setInvestorEngagementsMeetingNotesData(data['items']); }
    );
  }, [sendRequest, clientId, investorId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  /* Sort list items when sort configuration is updated. */
  useEffect(() => {
    setInvestorEngagementsMeetingNotesData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, tableSortConfig);
    });
  }, [tableSortConfig]);

  /* Generate meeting notes list. */
  useEffect(() => {
    setTableItems(
      generateClientInvestorEngagementsMeetingNotesTableItems(
        investorEngagementsMeetingNotesData, meetingNoteClickHandler, tableSortConfig, investorEnagementsMeetingNotesSortHandler
      )
    );
  }, [investorEngagementsMeetingNotesData, tableSortConfig, meetingNoteClickHandler]);


  return (
    <Fragment>
      { !isLoading && tableItems && tableItems['middle']['body']['items'].length === 0 && !showMeetingNoteForm && 
        <div className={styles['no-enagements-div']}>
          <div className={styles['icon-container']}>
            <div className={styles['icon']}></div>
          </div>
          {isPrimaryEditor &&
            <Fragment>
              <div className={styles['text-container']}>
                <h4>You Have No Meeting Notes</h4>
                <p>Use below button to Create a Meeting Note</p>
              </div>
              <div className={styles['button-container']}>
                <Button
                  type={"primary"}
                  leftIcon={"plus"}
                  size={"xsmall"}
                  onClick={addNewMeetingNoteClickHandler}>
                    Create Meeting Note
                  </Button>
              </div>
            </Fragment>
          }
          {!isPrimaryEditor &&
            <div className={styles['text-container']}>
              <h4>You Have No Meeting Notes</h4>
            </div>
          }
        </div>
      }

      { !isLoading && tableItems && tableItems['middle']['body']['items'].length > 0 && !showMeetingNoteForm &&
        <Fragment>
          {isPrimaryEditor &&
            <div className={styles['investor-engagement-list-buttons']}>  
              <div className={styles['right-buttons']}>
                <Button type={'primary'} size={'xsmall'} leftIcon={'plus'} onClick={addNewMeetingNoteClickHandler}>Create Meeting Note</Button>
              </div>
            </div>
          }
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={tableItems} isScrollable={false} />
        </Fragment>
      }

      { showMeetingNoteForm && meetingNoteFormType === 'new' &&
        <EngagementMeetingNoteCreateForm
          investorId={investorId}
          investorType='type-investor'
          isInvestorTypeEditable={false}
          hasFormTitle={false}
          onAttachmentUploadClick={uploadClientMeetingNoteAttachmentHandler}
          onSaveClick={clientMeetingNoteCreateHandler}
          onCancel={returnToMeetingNotesClickHandler}
        />
      }

      { showMeetingNoteForm && meetingNoteFormType === 'edit' && selectedMeetingNoteId &&
        <EngagementMeetingNoteUpdateForm
          meetingNoteId={selectedMeetingNoteId}
          investorId={investorId}
          investorType='type-investor'
          isInvestorTypeEditable={false}
          hasBackButton={true}
          onAttachmentUploadClick={uploadClientMeetingNoteAttachmentHandler}
          onSaveClick={clientMeetingNoteEditHandler}
          onBackClick={returnToMeetingNotesClickHandler}
        />
      }
    </Fragment>
  );
}

export default InvestorEngagementsMeetingNotes;

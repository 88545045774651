import { Fragment, useEffect, useState } from "react";
import useInputValidation from "../../hooks/use-input-validation";
import { clientContactDepartmentDropdownItems, relationshipStatusDropdownItems } from "../../utils/constants";
import { getValueFromVariable } from "../../utils/helpers";

import Button from "../UI/Button/Button";
import Dropdown from "..//UI/Dropdown/Dropdown";
import Textarea from "../UI/Textarea/Textarea";
import TextInput from "../UI/TextInput/TextInput";

import styles from './InvestorContactForm.module.css';

const InvestorContactForm = (props) => {
  const isViewOnly = props.isViewOnly;

  const contactInfo = props.contactInfo;
  const isContactInfoDefined = contactInfo !== null && contactInfo !== undefined;
  const isCustomContact = isContactInfoDefined ? getValueFromVariable(contactInfo['is_custom']) : true;

  const [fullname, setFullname, isFullnameValid, hasFullnameError, fullnameChangeHandler, fullnameBlurHandler] =
    useInputValidation(
      isContactInfoDefined ? getValueFromVariable(contactInfo['fullname']) : '',
      (fullname) => fullname.trim() !== ''
    );
  
  const [role, setRole, isRoleValid, hasRoleError, roleChangeHandler, roleBlurHandler] =
    useInputValidation(
      isContactInfoDefined ? getValueFromVariable(contactInfo['role']) : '',
      (role) => role.trim() !== ''
    );

  const [email, setEmail, isEmailValid, hasEmailError, emailChangeHandler, emailBlurHandler] =
    useInputValidation(
      isContactInfoDefined ? getValueFromVariable(contactInfo['email']) : '',
      (email) => email.trim() !== ''
    );
  
  const [department, setDepartment] = useState(isContactInfoDefined ? getValueFromVariable(contactInfo['department']) : '');
  const [relationshipStatus, setRelationshipStatus] = useState(isContactInfoDefined ? getValueFromVariable(contactInfo['relationship_status']) : '');
  const [city, setCity] = useState(isContactInfoDefined ? getValueFromVariable(contactInfo['city']) : '');
  const [phoneNumber, setPhoneNumber] = useState(isContactInfoDefined ? getValueFromVariable(contactInfo['phone_number']) : '');
  const [address, setAddress] = useState(isContactInfoDefined ? getValueFromVariable(contactInfo['address']) : '');
  const [notes, setNotes] = useState(isContactInfoDefined ? getValueFromVariable(contactInfo['notes']) : '');
  const [profilePictureUrl, setProfilePictureUrl] = useState(isContactInfoDefined ? getValueFromVariable(contactInfo['profile_picture_url']) : '');

  const cancelClickHandler = () => {
    props.onCancelClick();
  }

  const isContactFormValid = () => {
    fullnameBlurHandler();
    roleBlurHandler();
    emailBlurHandler();
    return isFullnameValid && isRoleValid && isEmailValid;
  }

  const isSaveButtonDisabled = !(isFullnameValid && isRoleValid && isEmailValid);

  const saveContactHandler = () => {
    if(!isContactFormValid()) {
      return;
    }

    const newContactGeneralInformationData = {
      'fullname': fullname,
      'role': role,
      'email': email,
      'profile_picture_url': profilePictureUrl,
      'department': department,
      'city': city,
      'phone_number': phoneNumber,
      'address': address
    };

    const newContactAqtionInformationData = {
      'relationship_status': relationshipStatus !== '' ? relationshipStatus : 'Unknown',
      'notes': notes
    }
    
    props.onSaveClick(newContactGeneralInformationData, newContactAqtionInformationData);
  }

  const investorContactPhotoChangeHandler = (event) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;				
      }

      var data = new FormData();
			data.append('profile_picture', file, file.name);
      props.onProfilePictureUploadClick(data, setProfilePictureUrl);
    }
  }

  const deleteContactHandler = () => {
    props.onDeleteClick();
  }

  useEffect(() => {
    setFullname(isContactInfoDefined ? getValueFromVariable(contactInfo['fullname']) : '');
    setRole(isContactInfoDefined ? getValueFromVariable(contactInfo['role']) : '');
    setEmail(isContactInfoDefined ? getValueFromVariable(contactInfo['email']) : '');
    setDepartment(isContactInfoDefined ? getValueFromVariable(contactInfo['department']) : '');
    setRelationshipStatus(isContactInfoDefined ? getValueFromVariable(contactInfo['relationship_status']) : '');
    setCity(isContactInfoDefined ? getValueFromVariable(contactInfo['city']) : '');
    setPhoneNumber(isContactInfoDefined ? getValueFromVariable(contactInfo['phone_number']) : '');
    setAddress(isContactInfoDefined ? getValueFromVariable(contactInfo['address']) : '');
    setNotes(isContactInfoDefined ? getValueFromVariable(contactInfo['notes']) : '');
    setProfilePictureUrl(isContactInfoDefined ? getValueFromVariable(contactInfo['profile_picture_url']) : '');
  }, [contactInfo, isContactInfoDefined,
      setEmail, setFullname, setRole]);

  return (
    <div className={styles['contact-details-container']}>
      <div className={styles['overlay']}></div>
      <div className={styles['contact-details-form']}>
        <div className={styles['header']}>
          <div className={styles['title']}>
            {props.type === 'new' &&
              <h1>Add New Contact</h1>
            }
            {props.type === 'edit' &&
              <h1>Edit Contact</h1>
            }
          </div>
          <div className={styles['close-button']} onClick={cancelClickHandler}>
            <div className={styles['close-icon']}></div>
          </div>
        </div>
        <div className={styles['body']}>
          <div className={styles['row']}>
            {props.type === 'new' &&
              <div className={styles['new-contact-photo-upload-container']}>
                <div className={styles['new-photo-container']}>
                  <div className={styles['new-contact-photo']}>
                    <div className={styles['photo']} style={{backgroundImage:`url("${profilePictureUrl}")`}}></div>
                    <label className={styles['new-contact-photo-upload-button']}>
                      <div className={styles['plus-icon']}></div>
                      <input type='file' accept='image/gif, image/jpeg, image/png' onChange={investorContactPhotoChangeHandler} />
                    </label>
                  </div>
                </div>
                <h4>Add Photo</h4>
              </div>
            }
            {props.type === 'edit' &&
              <div className={styles['contact-photo-upload-container']}>
                <div className={styles['photo-container']}>
                  <div
                    className={styles['contact-photo']}
                    style={{backgroundImage:`url("${profilePictureUrl}")`}}
                  ></div>
                </div>
                {isCustomContact && !isViewOnly &&
                  <div className={styles['button-container']}>
                    <label className={styles['contact-photo-upload-button']}>
                      <input type='file' accept='image/gif, image/jpeg, image/png' onChange={investorContactPhotoChangeHandler} />
                      <h4>Change Photo</h4>
                    </label>
                  </div>
                }
              </div>
            }
          </div>

          <div className={styles['row']}>
            <TextInput
              id='client-contact#contact-fullname'
              width='100%'
              label='Full Name *'
              placeholder='Enter Full Name'
              value={fullname}
              hasError={hasFullnameError}
              warningText={hasFullnameError && 'Full Name needs to be filled!'}
              onBlur={fullnameBlurHandler}
              onChange={fullnameChangeHandler}
              isDisabled={(!isCustomContact && props.type === 'edit') || isViewOnly}
            />
          </div>
          <div className={styles['row']}>
            <TextInput
              id='client-contact#contact-role'
              width='100%'
              label='Role *'
              placeholder='Enter Role'
              value={role}
              hasError={hasRoleError}
              warningText={hasRoleError && 'Role needs to be filled!'}
              onBlur={roleBlurHandler}
              onChange={roleChangeHandler}
              isDisabled={(!isCustomContact && props.type === 'edit') || isViewOnly}
            />
          </div>
          <div className={styles['row']}>
            <Dropdown
              id='client-contact#department'
              width='100%'
              label='Department'
              placeholder='Select Department'
              items={clientContactDepartmentDropdownItems}
              selected={department}
              onItemClick={setDepartment}
              isDisabled={(!isCustomContact && props.type === 'edit') || isViewOnly}
            />
          </div>
          <div className={styles['row']}>
            <Dropdown
              id='client-contact#relationship-status'
              width='100%'
              label='Relationship Status'
              placeholder='Select Relationship Status'
              items={relationshipStatusDropdownItems}
              hasChip={true}
              selected={relationshipStatus}
              onItemClick={setRelationshipStatus}
              isDisabled={isViewOnly ? true : false}
            />
          </div>
          <div className={styles['row']}>
            <TextInput
              id='client-contact#city'
              width='100%'
              label='City'
              placeholder='Enter City'
              value={city}
              onChange={setCity}
              isDisabled={(!isCustomContact && props.type === 'edit') || isViewOnly}
            />
          </div>
          <div className={styles['row']}>
            <TextInput
              id='client-contact#email'
              width='100%'
              label='Email *'
              placeholder='Enter Email'
              value={email}
              hasError={hasEmailError}
              warningText={hasEmailError && 'Email needs to be filled!'}
              onBlur={emailBlurHandler}
              onChange={emailChangeHandler}
              isDisabled={(!isCustomContact && props.type === 'edit') || isViewOnly}
            />
          </div>
          <div className={styles['row']}>
            <TextInput
              id='client-contact#phone-number'
              width='100%'
              label='Phone Number'
              placeholder='Enter Phone Number'
              value={phoneNumber}
              onChange={setPhoneNumber}
              isDisabled={(!isCustomContact && props.type === 'edit') || isViewOnly}
            />
          </div>
          <div className={styles['row']}>
            <Textarea
              id='client-contact#address'
              width='100%'
              height='120px'
              label='Address'
              size='base'
              placeholder='Enter Address'
              onChange={setAddress}
              isDisabled={(!isCustomContact && props.type === 'edit') || isViewOnly}
            >
              {address}
            </Textarea>
          </div>
          <div className={styles['row']}>
            <Textarea
              id='client-contact#notes'
              width='100%'
              height='120px'
              label='General Comment'
              size='base'
              placeholder='Enter General Comment'
              onChange={setNotes}
              isDisabled={isViewOnly ? true : false}
            >
              {notes}
            </Textarea>
          </div>
        </div>
        <div className={styles['footer']}>
          {props.type === 'new' && isCustomContact &&
            <div className={styles['right-buttons-container']}>
              <Button size='base' type='cancel' onClick={cancelClickHandler}>Cancel</Button>
              <Button size='base' type='primary' onClick={saveContactHandler} isDisabled={isSaveButtonDisabled}>Add New Contact</Button>
            </div>
          }
          
          {props.type === 'edit' && !isViewOnly &&
            <Fragment>
              { isCustomContact && 
                <div className={styles['left-buttons-container']}>
                  <Button size='base' type='delete' onClick={deleteContactHandler}>Delete Contact</Button>
                </div>
              }
              <div className={styles['right-buttons-container']}>
                <Button size='base' type='cancel' onClick={cancelClickHandler}>Cancel</Button>
                <Button size='base' type='primary' onClick={saveContactHandler} isDisabled={isSaveButtonDisabled}>Save</Button>
              </div>
            </Fragment>
          }
          {props.type === 'edit' && isViewOnly &&
            <div className={styles['right-buttons-container']}>
              <div className={styles['view-only-filler']}></div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default InvestorContactForm;

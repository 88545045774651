import { Fragment, useEffect, useState } from "react";
import { commaSeperatedValueToNumber } from "../../../utils/helpers";
import { fetchClientBasicProfileRequest, fetchClientInvestorsRequest, fetchClientProxyAdvisorsRequest } from "../../../requests/client-requests";
import { getClientEngagementsOtherNoteAttachmentRequest } from "../../../requests/client-engagement-requests";
import { getAuthClientId, getClientType } from "../../../utils/auth";
import { handleRequestError } from "../../../utils/request-error-helpers";
import useHttp from "../../../hooks/use-http";
import useInputValidation from "../../../hooks/use-input-validation";

import Button from "../../UI/Button/Button";
import RadioButtonGroup from "../../UI/RadioButtonGroup/RadioButtonGroup";

import EngagementFormItem from "../EngagementFormItem/EngagementFormItem";

import styles from './EngagementOtherNoteForm.module.css';

const EngagementOtherNoteCreateForm = (props) => {

  const clientId = getAuthClientId();
  const clientType = getClientType();

  const [isLoading, error, sendRequest] = useHttp();
  
  const investorId = props.investorId;
  const proxyAdvisorId = props.proxyAdvisorId;
  const isInvestorTypeEditable = props.isInvestorTypeEditable
  const hasFormTitle = props.hasFormTitle;

  const [clientTotalNumOfShares, setClientTotalNumOfShares] = useState(null);

  const [investorListData, setInvestorListData] = useState([]);
  const [proxyAdvisorListData, setProxyAdvisorListData] = useState([]);

  const [noteHeader, setNoteHeader, isNoteHeaderValid, hasNoteHeaderError, noteHeaderChangeHandler, noteHeaderBlurHandler] =
    useInputValidation('', (noteHeader) => noteHeader.trim() !== '');

  const [noteBody, setNoteBody, isNoteBodyValid, hasNoteBodyError, noteBodyChangeHandler, noteBodyBlurHandler] =
    useInputValidation('', (noteBody) => noteBody.trim() !== '');

  const [investorName, setInvestorName, isInvestorNameValid, hasInvestorNameError, investorNameChangeHandler, investorNameBlurHandler] =
    useInputValidation('', (investorName) => investorName.trim() !== '');

  const [investorNumOfShares, setInvestorNumOfShares, isInvestorNumOfSharesValid, hasInvestorNumOfSharesError, investorNumOfSharesChangeHandler, investorNumOfSharesBlurHandler] =
    useInputValidation('', () => true);

  const [proxyAdvisorName, setProxyAdvisorName, isProxyAdvisorNameValid, hasProxyAdvisorNameError, proxyAdvisorNameChangeHandler, proxyAdvisorNameBlurHandler] =
    useInputValidation('', (proxyAdvisorName) => proxyAdvisorName.trim() !== '');

  const [noteAttachments, setNoteAttachments] = useState([]);
    
  const [selectedInvestorType, setSelectedInvestorType] = useState(props.investorType ? props.investorType : 'type-investor');

  const radioButtonChangeToInvestorHandler = () => {
    setSelectedInvestorType('type-investor');
  }

  const radioButtonChangeToProxyAdvisorHandler = () => {
    setSelectedInvestorType('type-proxy-advisor');
  }

  const investorTypeRadioButtonItems = [
    {
      id: 'type-investor',
      value: 'Investor',
      text: 'Investor',
      isChecked: true,
      onChange: radioButtonChangeToInvestorHandler
    },
    {
      id: 'type-proxy-advisor',
      value: 'Proxy Advisor',
      text: 'Proxy Advisor',
      isChecked: false,
      onChange: radioButtonChangeToProxyAdvisorHandler
    }
  ];

  /* Send a request to fetch investor and proxy advisor list for the client to
   * fill combobox values located in the new other note form. */
  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (data) => { setClientTotalNumOfShares(data['total_num_of_shares']); }
    );
    
    if (isInvestorTypeEditable || selectedInvestorType === 'type-investor') {
      sendRequest(
        fetchClientInvestorsRequest(clientId),
        (data) => {
          if(investorId !== undefined) {
            const investor = data['items'].find(item => item['id'].toString() === investorId)
            setInvestorName(investor['name']);
            setInvestorNumOfShares(investor['num_of_shares']);
          }
          setInvestorListData(data['items']);
        }
      );
    }
    else {
      setInvestorListData([]);
    }

    if (isInvestorTypeEditable || selectedInvestorType === 'type-proxy-advisor') {
      sendRequest(
        fetchClientProxyAdvisorsRequest(clientId),
        (data) => {
          data['items'] = data['items'].filter(item => item['type'] === 'Proxy Advisor');
          if(proxyAdvisorId !== undefined) {
            const proxyAdvisor = data['items'].find(item => item['id'].toString() === proxyAdvisorId)
            setProxyAdvisorName(proxyAdvisor['name']);
          }
          setProxyAdvisorListData(data['items']);
        }
      );
    }
    else {
      setProxyAdvisorListData([]);
    }
  }, [sendRequest, clientId, isInvestorTypeEditable, selectedInvestorType, investorId, proxyAdvisorId,
      setInvestorName, setInvestorNumOfShares, setProxyAdvisorName]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const investorComboboxItems = investorListData.map(item => {
    return {
      'id': item['id'],
      'text': item['name']
    };
  });

  const proxyAdvisorComboboxItems = proxyAdvisorListData.map(item => {
    return {
      'id': item['id'],
      'text': item['name']
    };
  });


  useEffect(() => {
    /* Shared fields for both investor and proxy advisor notes. */
    setNoteHeader('');
    setNoteBody('');
    setNoteAttachments([]);
  }, [setInvestorName, setInvestorNumOfShares, setNoteBody, setNoteHeader, setProxyAdvisorName]);


  const otherNoteAttachmentsChangeHandler = (event) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;				
      }

      var data = new FormData();
			data.append('attachment', file, file.name);
      props.onAttachmentUploadClick(data, setNoteAttachments);
    }
  }

  const otherNoteAttachmentRemoveHandler = (clickedAttachmentId) => {
    setNoteAttachments(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const attachmentIndex = items.findIndex(item => item['id'] === clickedAttachmentId);
      items.splice(attachmentIndex, 1);
      return items;
    });
  }


  const isOtherNoteFormValid = () => {
    noteHeaderBlurHandler();
    noteBodyBlurHandler();
    
    const isGeneralInfoValid = isNoteHeaderValid && isNoteBodyValid;
    if (selectedInvestorType === 'type-investor') {
      investorNameBlurHandler();
      investorNumOfSharesBlurHandler();
      return isGeneralInfoValid && isInvestorNameValid && isInvestorNumOfSharesValid;
    }
    else if (selectedInvestorType === 'type-proxy-advisor') {
      proxyAdvisorNameBlurHandler();
      return isGeneralInfoValid && isProxyAdvisorNameValid;
    }
    else {
      return false;
    }
  }

  const saveOtherNoteClickHandler = () => {
    if (!isOtherNoteFormValid()) {
      return;
    }

    const otherNoteData = {
      'note_header': noteHeader,
      'note_body': noteBody,
      'note_attachments': noteAttachments
    };

    if (selectedInvestorType === 'type-investor') {
      const itemIndex = investorComboboxItems.findIndex(item => item['text'] === investorName);
      otherNoteData['investor_type'] = 'Investor';
      otherNoteData['investor'] = {
        'id': investorComboboxItems[itemIndex]['id'],
        'name': investorName
      };
      if(clientType === 'Corporate') {
        otherNoteData['investor']['num_of_shares'] = commaSeperatedValueToNumber(investorNumOfShares);
        otherNoteData['investor']['isc'] = (commaSeperatedValueToNumber(investorNumOfShares) / clientTotalNumOfShares) * 100;
      }
      else {
        otherNoteData['investor']['num_of_shares'] = 0;
        otherNoteData['investor']['isc'] = 0;
      }
      props.onSaveClick(otherNoteData);
    }
    else if (selectedInvestorType === 'type-proxy-advisor') {
      const itemIndex = proxyAdvisorComboboxItems.findIndex(item => item['text'] === proxyAdvisorName);
      otherNoteData['investor_type'] = 'Proxy Advisor';
      otherNoteData['proxy_advisor'] = {
        'id': proxyAdvisorComboboxItems[itemIndex]['id'],
        'name': proxyAdvisorName
      };
      props.onSaveClick(otherNoteData);
    }
    else {
      return;
    }
  }

  const openAttachmentInNewTab = (attachmentUrl) =>{
    if(attachmentUrl !== ''){ 
      sendRequest(getClientEngagementsOtherNoteAttachmentRequest(attachmentUrl), (data) => {
        var urlCreator = window.URL || window.webkitURL;
        window.open(urlCreator.createObjectURL(data), '_blank');
      })
    }
  
    if(!isLoading && error !== undefined) {
      handleRequestError(error);
    }
  }

  return (
    <div className={styles['other-note-container']}>
      <div className={styles['header']}>
        {hasFormTitle && 
          <div className={styles['form-title']}>
            <h2>Create an Other Note</h2>
          </div>
        }
        <div className={styles['other-note-title-container']}>
          <EngagementFormItem
            id='investor-other-note#note-header'
            type='text-input'
            label='Note Header'
            value={noteHeader}
            placeholder='Enter Note Header'
            hasError={hasNoteHeaderError}
            onBlur={noteHeaderBlurHandler}
            onChange={noteHeaderChangeHandler}
          />
        </div>
        
        <div className={styles['button-container']}>
          <Button type={"cancel"} size={"xsmall"} onClick={props.onCancel}>Cancel</Button>
          <Button type={'primary'} size={'xsmall'} onClick={saveOtherNoteClickHandler}>Save</Button>
        </div>
      </div>
      <div className={styles['body']}>
        <div className={styles['other-note-content-container']}>
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-other-note#note-body'
              type={'rich-text-input'}
              label='Note Body'
              value={noteBody}
              placeholder='Enter Note'
              hasError={hasNoteBodyError}
              onBlur={noteBodyBlurHandler}
              onChange={noteBodyChangeHandler}
            />
          </div>

          <div className={styles['row']}>
            { noteAttachments.length > 0 &&
              <Fragment>
                {noteAttachments.map(item => {
                  return (
                    <EngagementFormItem
                      key={`attached-item-${item['id']}`}
                      type='attached-item'
                      value={item['attachment_name']}
                      isRemovable={true}
                      onItemClick={() => openAttachmentInNewTab(item['attachment_url'])}
                      onRemoveClick={() => otherNoteAttachmentRemoveHandler(item['id'])}
                    />
                  );
                })}
                
                <EngagementFormItem
                  type='new-attachment-icon-button'
                  onChange={otherNoteAttachmentsChangeHandler}
                />
              </Fragment>
            }

            { noteAttachments.length === 0 &&
              <EngagementFormItem
                type='new-attachment-button'
                onChange={otherNoteAttachmentsChangeHandler}
              />
            }
          </div>
        </div>

        <div className={styles['other-note-details-container']}>
          {isInvestorTypeEditable &&
            <div className={styles['row']}>
              <RadioButtonGroup
                name='type-investor'
                items={investorTypeRadioButtonItems}
                selected={selectedInvestorType}
              />
            </div>
          }

          {selectedInvestorType === 'type-investor' && 
            <Fragment>
              <div className={styles['row']}>
                <EngagementFormItem
                  id='investor-other-note#investor-name'
                  type='combobox'
                  label='Investor'
                  value={investorName}
                  items={investorComboboxItems}
                  placeholder='Select Investor'
                  hasError={hasInvestorNameError}
                  onBlur={investorNameBlurHandler}
                  onChange={investorNameChangeHandler}
                  isDisabled={!isInvestorTypeEditable}
                />
              </div>
              {clientType === 'Corporate' && 
                <div className={styles['row']}>
                  <EngagementFormItem
                    id='investor-other-note#investor-num-of-shares'
                    type={'number-input'}
                    label='# of Shares'
                    value={investorNumOfShares}
                    placeholder='Enter # of Shares'
                    hasError={hasInvestorNumOfSharesError}
                    onBlur={investorNumOfSharesBlurHandler}
                    onChange={investorNumOfSharesChangeHandler}
                  />
                </div>
              }
            </Fragment>
          }
          {selectedInvestorType === 'type-proxy-advisor' && 
            <div className={styles['row']}>
              <EngagementFormItem
                id='investor-other-note#proxy-advisor-name'
                type='combobox'
                label='Proxy Advisor'
                value={proxyAdvisorName}
                items={proxyAdvisorComboboxItems}
                placeholder='Select Proxy Advisor'
                hasError={hasProxyAdvisorNameError}
                onBlur={proxyAdvisorNameBlurHandler}
                onChange={proxyAdvisorNameChangeHandler}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default EngagementOtherNoteCreateForm;

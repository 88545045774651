import { useState } from 'react';
import { investorProxyAdvisorDropdownItems, investorProxyAdvisorRelianceDropdownItems, investorVotingDecisionMakerDropdownItems, investorVotingDropdownItems } from '../../../../../utils/constants';
import { getValueFromVariable } from '../../../../../utils/helpers';

import Button from '../../../../../components/UI/Button/Button';
import SectionInfoBox from '../SectionInfoBox/SectionInfoBox';

import summaryPageStyles from '../InvestorSummary.module.css';

const InvestorSummaryStewardshipSection = (props) => {
  const isViewOnly = props.isViewOnly;

  const isCustomInvestor = props.isCustomInvestor;
  const [stewardshipData, setStewardshipData] = useState(props.stewardshipData);
  const [isEditMode, setIsEditMode] = useState(false);

  const [votingDecisionMaker, setVotingDecisionMaker] = useState(getValueFromVariable(stewardshipData['voting_decision_maker']));
  const [proxyAdvisor, setProxyAdvisor] = useState(getValueFromVariable(stewardshipData['proxy_advisors']));
  const [proxyAdvisorReliance, setProxyAdvisorReliance] = useState(getValueFromVariable(stewardshipData['proxy_advisors_reliance']));
  const [voting, setVoting] = useState(getValueFromVariable(stewardshipData['voting']));
  const [caseByCaseDescription, setCaseByCaseDescription] = useState(getValueFromVariable(stewardshipData['case_by_case_description']));

  const cancelEditHandler = () => {
    setIsEditMode(false);
    setVotingDecisionMaker(getValueFromVariable(stewardshipData['voting_decision_maker']));
    setProxyAdvisor(getValueFromVariable(stewardshipData['proxy_advisors']));
    setProxyAdvisorReliance(getValueFromVariable(stewardshipData['proxy_advisors_reliance']));
    setVoting(getValueFromVariable(stewardshipData['voting']));
    setCaseByCaseDescription(getValueFromVariable(stewardshipData['case_by_case_description']));
  }

  const updateStewardshipHandler = () => {
    const updatedStewardshipData = {
      'voting_decision_maker': votingDecisionMaker,
      'proxy_advisors': proxyAdvisor,
      'proxy_advisors_reliance': proxyAdvisorReliance,
      'voting': voting,
      'case_by_case_description': caseByCaseDescription
    };
    setStewardshipData(updatedStewardshipData);
    setIsEditMode(false);
    props.onSaveClick(updatedStewardshipData);
  }

  return (
    <div className={summaryPageStyles['section']}>
      <div className={summaryPageStyles['section-title']}>
        <h3>Stewardship</h3>
      </div>
      { isCustomInvestor && !isEditMode && !isViewOnly &&
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='secondary' leftIcon='edit' onClick={() => setIsEditMode(true)}>Edit</Button>
        </div>
      }
      { isCustomInvestor && isEditMode && !isViewOnly &&
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='cancel' onClick={cancelEditHandler}>Cancel</Button>
          <Button size='xsmall' type='primary' onClick={updateStewardshipHandler}>Save</Button>
        </div>
      }

      <div className={`${summaryPageStyles['content']} ${isEditMode && summaryPageStyles['edit-mode']}`}>
        <SectionInfoBox
          id='investor#voting-decision-maker'
          size='single-col'
          type={!isEditMode ? 'text' : 'dropdown'}
          label='Voting Decision Maker'
          dropdownItems={investorVotingDecisionMakerDropdownItems}
          value={votingDecisionMaker}
          onInputChange={setVotingDecisionMaker}
          hasTooltip={true}
          tooltipContent='This field indicates which team makes the final decision on how to vote their shares. Further information can be found in the glossary.'
        />
        <SectionInfoBox
          id='investor#proxy-advisors'
          size='single-col'
          type={!isEditMode ? 'text' : 'dropdown'}
          label='Proxy Advisor(s)'
          dropdownItems={investorProxyAdvisorDropdownItems}
          value={proxyAdvisor}
          onInputChange={setProxyAdvisor}
          hasTooltip={true}
          tooltipContent='This field indicates which Proxy Advisor(s) are used by this investor.'
        />
        <SectionInfoBox
          id='investor#proxy-advisors-reliance'
          size='single-col'
          type={!isEditMode ? 'text' : 'dropdown'}
          label='Proxy Advisor(s) Reliance'
          dropdownItems={investorProxyAdvisorRelianceDropdownItems}
          value={proxyAdvisorReliance}
          onInputChange={setProxyAdvisorReliance}
          hasTooltip={true}
          tooltipContent='This field shows the reliance of this investor on the recommendations of their Primary Proxy Advisor. Further information can be found in the glossary.'
        />
        <SectionInfoBox
          id='investor#voting'
          size='single-col'
          type={!isEditMode ? 'text' : 'dropdown'}
          label='Voting'
          dropdownItems={investorVotingDropdownItems}
          value={voting}
          onInputChange={setVoting}
          hasTooltip={true}
          tooltipContent='This field indicates the likelihood of this investor voting at your upcoming general meetings. Further information can be found in the glossary.'
        />
        <SectionInfoBox
          id='investor#case-by-case-description'
          size='single-col'
          type={!isEditMode ? 'text' : 'text-input'}
          label='Case-by-Case Description'
          value={caseByCaseDescription}
          onInputChange={setCaseByCaseDescription}
          hasTooltip={true}
          tooltipContent='If this investor votes on a case-by-case basis, you will find here a description of the criteria considered by this investor before deciding on whether they should vote at a general meeting.'
        />
      </div>
      <div className={summaryPageStyles['separator']}></div>
    </div>
  );
}

export default InvestorSummaryStewardshipSection;

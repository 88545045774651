import { useCallback, useEffect, useState } from "react";
import { generateClientProxyAdvisorContactListTableItems } from "../../../../utils/client-proxy-advisor-helpers";
import { createClientProxyAdvisorContactsRequest, deleteClientProxyAdvisorContactRequest, listClientProxyAdvisorContactsRequest, updateClientProxyAdvisorContactRequest, uploadClientProxyAdvisorContactProfilePictureRequest } from "../../../../requests/client-requests";
import { getAuthClientId } from "../../../../utils/auth";
import { handleRequestError } from "../../../../utils/request-error-helpers";
import useHttp from "../../../../hooks/use-http";
import sortArray from 'sort-array';

import Button from "../../../../components/UI/Button/Button";
import Table from "../../../../components/UI/Table/Table";
import InvestorContactForm from "../../../../components/InvestorContactForm/InvestorContactForm";

import styles from './ProxyAdvisorContactList.module.css';

const ProxyAdvisorContactList = (props) => {
  const [isLoading, error, sendRequest] = useHttp();

  const [tableSortConfig, setTableSortConfig] = useState({
    'by': ['name'],
    'order': ['asc']
  });

  const [proxyAdvisorContactListData, setProxyAdvisorContactListData] = useState([]);
  const [tableItems, setTableItems] = useState(null);

  const [showContactForm, setShowContactForm] = useState(false);
  const [contactFormType, setContactFormType] = useState('new');
  const [selectedContact, setSelectedContact] = useState(null);

  const clientId = getAuthClientId();
  const proxyAdvisorId = props.proxyAdvisorId;
  const isViewOnly = props.isViewOnly;
  const isPrimaryEditor = props.isPrimaryEditor;

  const proxyAdvisorContactListSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);

      return newConfig;
    });
  }


  const newContactFormHandler = () => {
    setShowContactForm(true);
    setContactFormType('new');
  }


  const contactClickHandler = useCallback((clickedItemId) => {
    const selectedContactId = clickedItemId.split('client-proxy-advisor-contact-')[1].split('#')[0].toString();
    setSelectedContact(proxyAdvisorContactListData.filter(item => item['id'].toString() === selectedContactId)[0]);
    setShowContactForm(true);
    setContactFormType('edit');
  }, [proxyAdvisorContactListData])


  const pinClickHandler = useCallback((clickedItemId) => {
    const selectedContactId = clickedItemId.split('client-proxy-advisor-contact-')[1].split('#')[0].toString();
      setProxyAdvisorContactListData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        const clickedItem = items.filter(item => item['id'].toString() === selectedContactId)[0];
        if (clickedItem !== undefined) {
          const body = {
            'aqtion_information': {
              'is_starred': !clickedItem['is_starred']
            }
          };
          sendRequest(
            updateClientProxyAdvisorContactRequest(clientId, proxyAdvisorId, selectedContactId, body),
            (_) => { }
          );
          clickedItem['is_starred'] = !clickedItem['is_starred'];
        }
        return items;
      });
  }, [clientId, proxyAdvisorId, sendRequest]);


  /* Upload profile picture for the investor contact. */
  const uploadClientProxyAdvisorContactProfilePictureHandler = (profilePictureData, setProfilePictureUrl) => {
    let selectedContactId;
    if(selectedContact !== null) {
      selectedContactId = selectedContact['id'];
    }
    else {
      selectedContactId = 'temp'
    }
    sendRequest(
      uploadClientProxyAdvisorContactProfilePictureRequest(clientId, proxyAdvisorId, selectedContactId, profilePictureData),
      (data) => { setProfilePictureUrl(data['profile_picture_url']); }
    );
  }


  const clientProxyAdvisorContactCreateHandler = (contactGeneralInformationData, contactAqtionInformationData) => {
    contactAqtionInformationData['is_starred'] = false;
    const body = {
      'general_information': contactGeneralInformationData,
      'aqtion_information': contactAqtionInformationData
    };
    const createNewProxyAdvisorInvestorContactResponse = (data) => {
      setProxyAdvisorContactListData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        items.push(data)
        return items;
      });
      setShowContactForm(false);
    }
    sendRequest(
      createClientProxyAdvisorContactsRequest(clientId, proxyAdvisorId, body),
      createNewProxyAdvisorInvestorContactResponse
    );
  }


  const clientProxyAdvisorContactEditHandler = (contactGeneralInformationData, contactAqtionInformationData) => {
    const selectedContactId = selectedContact['id'];
    const body = {
      'general_information': contactGeneralInformationData,
      'aqtion_information': contactAqtionInformationData
    };

    const updateClientProxyAdvisorContactResponse = (data) => {
      setProxyAdvisorContactListData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        if(selectedContact !== null && selectedContact['is_custom']) {
          const contactIndex = items.findIndex(item => item['id'] === selectedContact['id']);
          items[contactIndex] = data;
        }
        else if(selectedContact !== null && !selectedContact['is_custom']) {
          const contactIndex = items.findIndex(item => item['id'] === selectedContact['id']);
          items[contactIndex]['relationship_status'] = data['relationship_status'];
          items[contactIndex]['notes'] = data['notes'];
          items[contactIndex]['is_starred'] = data['is_starred'];
        }
        return items;
      });
      setShowContactForm(false);
    }
    sendRequest(
      updateClientProxyAdvisorContactRequest(clientId, proxyAdvisorId, selectedContactId, body),
      updateClientProxyAdvisorContactResponse
    );
  }


  const clientProxyAdvisorContactDeleteHandler = () => {
    const selectedContactId = selectedContact['id'];

    const deleteClientProxyAdvisorContactResponse = (res) => {
      setProxyAdvisorContactListData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        if(selectedContact !== null && selectedContact['is_custom']) {
          const contactIndex = items.findIndex(item => item['custom_proxy_advisor_contact_id'] === selectedContact['custom_proxy_advisor_contact_id']);
          items.splice(contactIndex, 1);
        }
        return items;
      });
      setShowContactForm(false);
    }
    sendRequest(
      deleteClientProxyAdvisorContactRequest(clientId, proxyAdvisorId, selectedContactId),
      deleteClientProxyAdvisorContactResponse
    );
  }


  useEffect(() => {
    sendRequest(
      listClientProxyAdvisorContactsRequest(clientId, proxyAdvisorId),
      (data) => { setProxyAdvisorContactListData(data['items']); }
    );
  }, [clientId, proxyAdvisorId, sendRequest]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  useEffect(() => {
    setProxyAdvisorContactListData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, tableSortConfig);
    });
  }, [tableSortConfig]);

  useEffect(() => {
    setTableItems(generateClientProxyAdvisorContactListTableItems(
      proxyAdvisorContactListData, tableSortConfig, pinClickHandler, 
      contactClickHandler, proxyAdvisorContactListSortHandler, isViewOnly
    ));
  }, [proxyAdvisorContactListData, tableSortConfig, pinClickHandler, contactClickHandler, isViewOnly]);

  return (
    <div className={styles['proxy-advisor-contact-list']}>
      { isPrimaryEditor &&
        <div className={styles['proxy-advisor-contact-list-buttons']}>  
          <div className={styles['right-buttons']}>
            <Button type={'primary'} size={'xsmall'} leftIcon={'plus'} onClick={newContactFormHandler}>Add New Contact</Button>
          </div>
        </div>
      }
      { !isPrimaryEditor &&
        <div className={styles['proxy-advisor-contact-list-empty-button-div']}></div>
      }

      { tableItems &&
        <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={tableItems} isScrollable={true} hasDynamicScroll={true} />
      }

      { showContactForm && contactFormType === 'new' &&
        <InvestorContactForm
          type='new'
          onSaveClick={clientProxyAdvisorContactCreateHandler}
          onProfilePictureUploadClick={uploadClientProxyAdvisorContactProfilePictureHandler}
          onCancelClick={() => setShowContactForm(false)}
          isViewOnly={isViewOnly}
        />
      }

      { showContactForm && contactFormType === 'edit' &&
        <InvestorContactForm
          type='edit'
          contactInfo={selectedContact}
          onSaveClick={clientProxyAdvisorContactEditHandler}
          onProfilePictureUploadClick={uploadClientProxyAdvisorContactProfilePictureHandler}
          onDeleteClick={clientProxyAdvisorContactDeleteHandler}
          onCancelClick={() => setShowContactForm(false)}
          isViewOnly={isViewOnly}
        />
      }
    </div>
  );
}

export default ProxyAdvisorContactList;

import { BASE_URI } from "./config";
import { getAccessToken } from "../utils/auth";


export const listClientSetupsRequest = () => {
  return {
    url: `${BASE_URI}/client-setups`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const fetchClientSetupDetailsRequest = (clientSetupId) => {
  return {
    url: `${BASE_URI}/client-setups/${clientSetupId}`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const createClientSetupRequest = (body) => {
  return {
    url: `${BASE_URI}/client-setups`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const updateClientSetupRequest = (clientSetupId, body) => {
  return {
    url: `${BASE_URI}/client-setups/${clientSetupId}`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`
      },
      body: body
    }
  };
}


export const uploadClientSetupProfilePictureRequest = (clientId, body) => {
  return {
    url: `${BASE_URI}/client-setups/${clientId}/profile-picture`,
    options: {
      method: 'POST',
      body: body,
      dataType: 'FormData',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}


export const getClientSetupProfilePicture = (profilePictureUrl) => {
  return {
    url: profilePictureUrl,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      },
      returnDataType: 'blob'
    }
  };
}

import { Fragment, useEffect, useState } from "react";
import { generateUpdatedShareholderCommunicationInvestorsTableItems, generateUpdatedShareholderCommunicationProxyAdvisorsTableItems } from "../../../utils/client-engagement-helpers";
import { fetchClientBasicProfileRequest, fetchClientInvestorsRequest, fetchClientProxyAdvisorsRequest } from "../../../requests/client-requests";
import { fetchClientEngagementsShareholderCommunicationRequest } from "../../../requests/client-engagement-requests";
import { createId } from "../../../utils/helpers";
import { getAuthClientId, getClientType } from "../../../utils/auth";
import { handleRequestError } from "../../../utils/request-error-helpers";
import useHttp from "../../../hooks/use-http";
import useInputValidation from "../../../hooks/use-input-validation";
import sortArray from 'sort-array';

import Button from "../../UI/Button/Button";
import MultiSelectComboBox from "../../UI/MultiSelectComboBox/MultiSelectComboBox";
import Table from "../../UI/Table/Table";

import EngagementFormItem from "../EngagementFormItem/EngagementFormItem";

import styles from './EngagementShareholderCommunicationForm.module.css';

const EngagementShareholderCommunicationUpdateForm = (props) => {

  const clientId = getAuthClientId();
  const clientType = getClientType();

  const [isLoading, error, sendRequest] = useHttp();
  
  const [clientTotalNumOfShares, setClientTotalNumOfShares] = useState(null);
  const shareholderCommunicationId = props.shareholderCommunicationId;

  const [engagementName, setEngagementName, isEngagementNameValid, hasEngagementNameError, engagementNameChangeHandler, engagementNameBlurHandler] =
    useInputValidation('', (engagementName) => engagementName.trim() !== '');

  const [emailSubject, setEmailSubject, isEmailSubjectValid, hasEmailSubjectError, emailSubjectChangeHandler, emailSubjectBlurHandler] =
    useInputValidation('', (emailSubject) => emailSubject.trim() !== '');

  const [emailBody, setEmailBody, isEmailBodyValid, hasEmailBodyError, emailBodyChangeHandler, emailBodyBlurHandler] =
    useInputValidation('', (emailBody) => emailBody.trim() !== '');

  const [investorListData, setInvestorListData] = useState([]);
  const [proxyAdvisorListData, setProxyAdvisorListData] = useState([]);

  const [shareholderCommunicationDetailsData, setShareholderCommunicationDetailsData] = useState([]);

  const [shareholderCommunicationInvestorsData, setShareholderCommunicationInvestorsData] = useState([]);
  const [shareholderCommunicationProxyAdvisorsData, setShareholderCommunicationProxyAdvisorsData] = useState([]);

  const [showAddInvestorComboBox, setShowAddInvestorComboBox] = useState(false);
  const [investorSearchValue, setInvestorSearchValue] = useState('');
  const [selectedInvestorItems, setSelectedInvestorItems] = useState([]);
  const [investorTableItems, setInvestorTableItems] = useState(null);

  const [showAddProxyAdvisorComboBox, setShowAddProxyAdvisorComboBox] = useState(false);
  const [proxyAdvisorSearchValue, setProxyAdvisorSearchValue] = useState('');
  const [selectedProxyAdvisorItems, setSelectedProxyAdvisorItems] = useState([]);
  const [proxyAdvisorTableItems, setProxyAdvisorTableItems] = useState(null);

  const [emailCcSearchValue, setEmailCcSearchValue] = useState('');
  const [addedEmailCcItems, setAddedEmailCcItems] = useState([]);

  const [shareholderCommunicationInvestorsTableSortConfig, setShareholderCommunicationInvestorsTableSortConfig] = useState({
    'by': ['name'],
    'order': ['asc']
  });

  const shareholderCommunicationInvestorsTableSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setShareholderCommunicationInvestorsTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setShareholderCommunicationInvestorsData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, shareholderCommunicationInvestorsTableSortConfig);
    });
  }, [shareholderCommunicationInvestorsTableSortConfig]);


  const [shareholderCommunicationProxyAdvisorsTableSortConfig, setShareholderCommunicationProxyAdvisorsTableSortConfig] = useState({
    'by': ['name'],
    'order': ['asc']
  });

  const shareholderCommunicationProxyAdvisorsTableSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setShareholderCommunicationProxyAdvisorsTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setShareholderCommunicationProxyAdvisorsData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, shareholderCommunicationProxyAdvisorsTableSortConfig);
    });
  }, [shareholderCommunicationProxyAdvisorsTableSortConfig]);


  /* Send a request to fetch investor and proxy advisor list for the client to
   * fill combobox values located in the investor and proxy advisor tables. */
  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (data) => { setClientTotalNumOfShares(data['total_num_of_shares']); }
    );

    sendRequest(
      fetchClientEngagementsShareholderCommunicationRequest(clientId, shareholderCommunicationId),
      (data) => { 
        setShareholderCommunicationDetailsData(data);
        setEngagementName(data['engagement_name']);
        setAddedEmailCcItems(
          data['draft_email_cc'].split(';').filter(item => item !== '').map(item => {
            return {
              'id': createId(4),
              'email': item,
              'is_custom_email': true
            };
          })
        );
        setEmailSubject(data['draft_email_subject']);
        setEmailBody(data['draft_email_body']);
        setShareholderCommunicationInvestorsData(data['investors']);
        setSelectedInvestorItems(data['investors'].map(item => item['client_investor_id']));
        setShareholderCommunicationProxyAdvisorsData(data['proxy_advisors']);
        setSelectedProxyAdvisorItems(data['proxy_advisors'].map(item => item['client_proxy_advisor_id']));
      }
    );

    sendRequest(
      fetchClientInvestorsRequest(clientId),
      (data) => { setInvestorListData(data['items']); }
    );

    sendRequest(
      fetchClientProxyAdvisorsRequest(clientId),
      (data) => {
        data['items'] = data['items'].filter(item => item['type'] === 'Proxy Advisor');
        setProxyAdvisorListData(data['items']);
      }
    );
  }, [sendRequest, clientId, shareholderCommunicationId, 
      setEmailBody, setEmailSubject, setEngagementName]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const comboBoxInvestorGroups = [
    {
      'id': 'investor-group-1',
      'title': 'All Investors',
      'isClickable': true,
      'items': investorListData.map(item => {
        return {
          'id': item['id'],
          'text': item['name']
        };
      })
    }
  ];

  const comboBoxProxyAdvisorGroups = [
    {
      'id': 'proxy-advisor-group-1',
      'title': 'All Proxy Advisors',
      'isClickable': true,
      'items': proxyAdvisorListData.map(item => {
        return {
          'id': item['id'],
          'text': item['name']
        };
      })
    }
  ];


  /* Opens investor combo box when "Add Investor" button clicked. */
  const addInvestorClickHandler = () => {
    setShowAddInvestorComboBox(true);
  }

  /* Removes investor item from the table and selected investor item
   * list when the trash icon clicked. */
  const removeInvestorItemClickHandler = (investorId) => {
    setSelectedInvestorItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const itemIndex = items.indexOf(investorId);
      items.splice(itemIndex, 1);
      return items;
    });
    setShareholderCommunicationInvestorsData(prevInvestorItems => {
      const newInvestorItems = JSON.parse(JSON.stringify(prevInvestorItems));
      const itemIndex = newInvestorItems.map(item => item['client_investor_id']).indexOf(investorId);
      newInvestorItems.splice(itemIndex, 1);
      return newInvestorItems;
    });
  }

  /* Creates a row in the investor table or removes it from the 
   * investor table according the value of the clicked checkbox. */
  const investorItemClickHandler = (investorId) => {
    const operation = selectedInvestorItems.indexOf(investorId) > -1 ? 'remove' : 'add';
    setSelectedInvestorItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const itemIndex = items.indexOf(investorId);
      if (itemIndex > -1) {
        items.splice(itemIndex, 1);
      }
      else {
        items.push(investorId);
      }
      return items;
    });

    if (operation === 'add') {
      setShareholderCommunicationInvestorsData(prevInvestorItems => {
        const newInvestorItems = JSON.parse(JSON.stringify(prevInvestorItems));
        const itemIndex = investorListData.map(item => item['id']).indexOf(investorId);
        newInvestorItems.push({
          "id": createId(4),
          "client_investor_id": investorListData[itemIndex]['id'],
          "name": investorListData[itemIndex]['name'],
          "num_of_shares": investorListData[itemIndex]['num_of_shares'],
          "isc": (clientType === 'Corporate') ?((investorListData[itemIndex]['num_of_shares'] / clientTotalNumOfShares) * 100) : 0,
          "email_sent_date": null,
          "email_status": null,
          "updated_at": null,
          "updated_by": null,
          "is_custom": investorListData[itemIndex]['is_custom'],
          "is_new": true
        });
        return newInvestorItems;
      });
    }
    else if (operation === 'remove') {
      setShareholderCommunicationInvestorsData(prevInvestorItems => {
        const newInvestorItems = JSON.parse(JSON.stringify(prevInvestorItems));
        const itemIndex = newInvestorItems.map(item => item['client_investor_id']).indexOf(investorId);
        newInvestorItems.splice(itemIndex, 1);
        return newInvestorItems;
      });
    }
  }

  /* Either selects all investors located in the investor combo box 
   * and creates rows for each one of them in the investor table or
   * removes every one of them according the value of the clicked checkbox. */
  const allInvestorsClickHandler = () => {
    const operation = comboBoxInvestorGroups[0]['items'].length === selectedInvestorItems.length ? 'remove' : 'add';
    if (operation === 'add') {
      setShareholderCommunicationInvestorsData(prevInvestorItems => {
        const newInvestorItems = JSON.parse(JSON.stringify(prevInvestorItems));
        investorListData.forEach(item => {
          if (!selectedInvestorItems.includes(item['id'])) {
            newInvestorItems.push({
              "id": createId(4),
              "client_investor_id": item['id'],
              "name": item['name'],
              "num_of_shares": item['num_of_shares'],
              "isc": (clientType === 'Corporate') ? ((item['num_of_shares'] / clientTotalNumOfShares) * 100) : 0,
              "email_sent_date": null,
              "email_status": null,
              "updated_at": null,
              "updated_by": null,
              "is_custom": item['is_custom'],
              "is_new": true
            });
          }
        })
        return newInvestorItems;
      });
      setSelectedInvestorItems(investorListData.map(item => item['id']));
    }
    else if (operation === 'remove') {
      setShareholderCommunicationInvestorsData([]);
      setSelectedInvestorItems([]);
    }
  }


  /* Opens proxy advisor combo box when "Add Proxy Advisor" button clicked. */
  const addProxyAdvisorClickHandler = () => {
    setShowAddProxyAdvisorComboBox(true);
  }

  /* Removes proxy advisor item from the table and selected proxy advisor item
   * list when the trash icon clicked. */
  const removeProxyAdvisorItemClickHandler = (proxyAdvisorId) => {
    setSelectedProxyAdvisorItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const itemIndex = items.indexOf(proxyAdvisorId);
      items.splice(itemIndex, 1);
      return items;
    });

    setShareholderCommunicationProxyAdvisorsData(prevProxyAdvisorItems => {
      const newProxyAdvisorItems = JSON.parse(JSON.stringify(prevProxyAdvisorItems));
      const itemIndex = newProxyAdvisorItems.map(item => item['client_proxy_advisor_id']).indexOf(proxyAdvisorId);
      newProxyAdvisorItems.splice(itemIndex, 1);
      return newProxyAdvisorItems;
    });
  }

  /* Creates a row in the proxy advisor table or removes it from the 
   * proxy advisor table according the value of the clicked checkbox. */
  const proxyAdvisorItemClickHandler = (proxyAdvisorId) => {
    const operation = selectedProxyAdvisorItems.indexOf(proxyAdvisorId) > -1 ? 'remove' : 'add';
    setSelectedProxyAdvisorItems(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const itemIndex = items.indexOf(proxyAdvisorId);
      if (itemIndex > -1) {
        items.splice(itemIndex, 1);
      }
      else {
        items.push(proxyAdvisorId);
      }
      return items;
    });

    if (operation === 'add') {
      setShareholderCommunicationProxyAdvisorsData(prevProxyAdvisorItems => {
        const newProxyAdvisorItems = JSON.parse(JSON.stringify(prevProxyAdvisorItems));
        const itemIndex = proxyAdvisorListData.map(item => item['id']).indexOf(proxyAdvisorId);
        newProxyAdvisorItems.push({
          "id": createId(4),
          "client_proxy_advisor_id": proxyAdvisorListData[itemIndex]['id'],
          "name": proxyAdvisorListData[itemIndex]['name'],
          "email_sent_date": null,
          "email_status": null,
          "updated_at": null,
          "updated_by": null,
          "is_new": true
        });
        return newProxyAdvisorItems;
      });
    }
    else if (operation === 'remove') {
      setShareholderCommunicationProxyAdvisorsData(prevProxyAdvisorItems => {
        const newProxyAdvisorItems = JSON.parse(JSON.stringify(prevProxyAdvisorItems));
        const itemIndex = newProxyAdvisorItems.map(item => item['client_proxy_advisor_id']).indexOf(proxyAdvisorId);
        newProxyAdvisorItems.splice(itemIndex, 1);
        return newProxyAdvisorItems;
      });
    }
  }

  /* Either selects all proxy advisors located in the proxy advisor combo box 
   * and creates rows for each one of them in the proxy advisor table or
   * removes every one of them according the value of the clicked checkbox. */
  const allProxyAdvisorsClickHandler = () => {
    const operation = comboBoxProxyAdvisorGroups[0]['items'].length === selectedProxyAdvisorItems.length ? 'remove' : 'add';
    if (operation === 'add') {
      setShareholderCommunicationProxyAdvisorsData(prevProxyAdvisorItems => {
        const newProxyAdvisorItems = JSON.parse(JSON.stringify(prevProxyAdvisorItems));
        proxyAdvisorListData.forEach(item => {
          if (!selectedProxyAdvisorItems.includes(item['id'])) {
            newProxyAdvisorItems.push({
              "id": createId(4),
              "client_proxy_advisor_id": item['id'],
              "name": item['name'],
              "email_sent_date": null,
              "email_status": null,
              "updated_at": null,
              "updated_by": null,
              "is_new": true
            });
          }
        })
        return newProxyAdvisorItems;
      });
      setSelectedProxyAdvisorItems(proxyAdvisorListData.map(item => item['id']));
    }
    else if (operation === 'remove') {
      setShareholderCommunicationProxyAdvisorsData([]);
      setSelectedProxyAdvisorItems([]);
    }
  }

  /* Adds new custom emaill address for the `Cc` area. */
  const addCustomEmailCcItemHandler = (email) => {
    setAddedEmailCcItems(prevAddedEmailCcItems => {
      const newAddedEmailCcItems = JSON.parse(JSON.stringify(prevAddedEmailCcItems));
      newAddedEmailCcItems.push({
        'id': createId(4),
        'email': email,
        'is_custom_email': true
      });
      return newAddedEmailCcItems;
    });
    setEmailCcSearchValue('');
  }

  /* Renoves emaill address from the `Cc` area. */
  const removeCustomEmailCcItemHandler = (clickedItem) => { 
    setAddedEmailCcItems(prevAddedEmailCcItems => {
      const newAddedEmailCcItems = JSON.parse(JSON.stringify(prevAddedEmailCcItems));
      return newAddedEmailCcItems.filter(item => item['email'] !== clickedItem['email']);
    });
  }

  /* Generates selected / added email tiles for the `Cc` area. */
  const generateEmailCcItems = () => {
    return addedEmailCcItems.map(item => {
      return (
        <div key={`added-cc-contact-${item['id']}`} className={styles['added-cc-contact']}>
          <div className={styles['added-cc-contact-text']}>
            <h3>{item['email']}</h3>
          </div>
          <div className={styles['remove-button']} onClick={() => removeCustomEmailCcItemHandler(item)}></div>
        </div>
      );
    });
  }


  const isShareholderCommunicationFormValid = () => {
    engagementNameBlurHandler();
    emailSubjectBlurHandler();
    emailBodyBlurHandler();
    return isEngagementNameValid && isEmailSubjectValid && isEmailBodyValid;
  }

  const saveShareholderCommunicationClickHandler = () => {
    if (!isShareholderCommunicationFormValid()) {
      return;
    }

    const newInvestorItems = shareholderCommunicationInvestorsData.filter(item => item['is_new'] && selectedInvestorItems.includes(item['client_investor_id'])).map(item => {
      return {
        'id': item['client_investor_id'],
        'name': item['name'],
        'num_of_shares': item['num_of_shares'],
        'isc': (clientType === 'Corporate') ? item['isc'] : 0
      }
    });
    const currentInvestorItemIds = shareholderCommunicationInvestorsData.map(item => item['id']);
    const removedInvestorItemIds = shareholderCommunicationDetailsData['investors'].filter(item => !currentInvestorItemIds.includes(item['id'])).map(item => item['id']);

    const newProxyAdvisorItems = shareholderCommunicationProxyAdvisorsData.filter(item => item['is_new'] && selectedProxyAdvisorItems.includes(item['client_proxy_advisor_id'])).map(item => {
      return {
        'id': item['client_proxy_advisor_id'],
        'name': item['name']
      }
    });
    const currentProxyAdvisorItemIds = shareholderCommunicationProxyAdvisorsData.map(item => item['id']);
    const removedProxyAdvisorItemIds = shareholderCommunicationDetailsData['proxy_advisors'].filter(item => !currentProxyAdvisorItemIds.includes(item['id'])).map(item => item['id']);

    const shareholderCommunicationData = {
      'engagement_name': engagementName,
      'draft_email_cc': addedEmailCcItems.map(item => item['email']).join(';'),
      'draft_email_subject': emailSubject,
      'draft_email_body': emailBody,
      'investors': {
        'new_items': newInvestorItems,
        'removed_item_ids': removedInvestorItemIds // Shareholder communication - investor item ids, not investor ids // TODO: Revisit this decision
      },
      'proxy_advisors': {
        'new_items': newProxyAdvisorItems,
        'removed_item_ids': removedProxyAdvisorItemIds  // Shareholder communication - proxy advisor item ids, not proxy advisor ids // TODO: Revisit this decision
      }
    };

    props.onSaveClick(shareholderCommunicationData);
  }


  /* Fills investor combo box and proxy advisor combo box with existing 
   * investor and proxy advisor items when the page loaded. */
  useEffect(() => {
    setInvestorTableItems(
      generateUpdatedShareholderCommunicationInvestorsTableItems(
        clientType,
        shareholderCommunicationInvestorsData,
        addInvestorClickHandler,
        addInvestorClickHandler,
        removeInvestorItemClickHandler,
        shareholderCommunicationInvestorsTableSortConfig,
        shareholderCommunicationInvestorsTableSortHandler
      )
    );
  }, [clientType, shareholderCommunicationInvestorsData, setInvestorTableItems, shareholderCommunicationInvestorsTableSortConfig]);

  useEffect(() => {
    setProxyAdvisorTableItems(
      generateUpdatedShareholderCommunicationProxyAdvisorsTableItems(
        shareholderCommunicationProxyAdvisorsData,
        addProxyAdvisorClickHandler,
        addProxyAdvisorClickHandler,
        removeProxyAdvisorItemClickHandler,
        shareholderCommunicationProxyAdvisorsTableSortConfig,
        shareholderCommunicationProxyAdvisorsTableSortHandler
      )
    );
  }, [shareholderCommunicationProxyAdvisorsData, setProxyAdvisorTableItems, shareholderCommunicationProxyAdvisorsTableSortConfig]);

  useEffect(() => {
    setEngagementName('');
    setEmailSubject('');
    setEmailBody('');
  }, [setEngagementName, setEmailSubject, setEmailBody]);

  return (
    <Fragment>
      {!isLoading && 
        <div className={styles['shareholder-communication-container']}>
          <div className={styles['header']}>
            <div className={styles['form-title']}>
              <h2>Edit Shareholder Communication</h2>
            </div>
          </div>
          <div className={styles['body']}>
            <div className={styles['row']}>
              <div className={styles['shareholder-communication-name-container']}>
                <EngagementFormItem
                  id='investor-shareholder-communication#engagement-name'
                  type='text-input'
                  label='Engagement Name'
                  value={engagementName}
                  placeholder='Enter Engagement Name'
                  hasError={hasEngagementNameError}
                  onBlur={engagementNameBlurHandler}
                  onChange={engagementNameChangeHandler}
                />
              </div>
              <div className={styles['action-button-container']}>
                <Button type={'primary'} size={'base'} onClick={saveShareholderCommunicationClickHandler}>Save</Button>
                <Button type={"cancel"} size={"base"} onClick={props.onCancel}>Cancel</Button>
              </div>
            </div>

            <div className={styles['row']}>
              <div className={styles['shareholder-communication-email-subject-body-container']}>
                <div className={styles['email-subject']}>
                  <EngagementFormItem
                    id='investor-shareholder-communication#email-subject'
                    type='text-input'
                    label='Email Subject'
                    value={emailSubject}
                    placeholder='Enter Email Subject'
                    hasError={hasEmailSubjectError}
                    onBlur={emailSubjectBlurHandler}
                    onChange={emailSubjectChangeHandler}
                  />
                </div>
                <div className={styles['email-body']}>
                  <EngagementFormItem
                    id='investor-shareholder-communication#email-body'
                    type='rich-text-input'
                    label='Email Body'
                    value={emailBody}
                    placeholder='Enter Content'
                    hasError={hasEmailBodyError}
                    onBlur={emailBodyBlurHandler}
                    onChange={emailBodyChangeHandler}
                  />
                </div>
              </div>

              <div className={styles['shareholder-communication-email-cc-container']}>
                <MultiSelectComboBox
                  id='add-email-cc-combobox'
                  width={500}
                  size='base'
                  label='Cc'
                  itemGroups={[]}
                  alwaysShowItems={false}
                  hasSearchIcon={false}
                  hasHeaderCover={false}
                  value={emailCcSearchValue}
                  placeholder='Enter recipient emails manually'
                  selected={[]}
                  onChange={setEmailCcSearchValue}
                  onItemClick={null}
                  autoFocus={false}
                  hasAddCustomButton={true}
                  onAddCustomClick={addCustomEmailCcItemHandler}
                />

                <div className={styles['added-cc-contacts-container']}>
                  { addedEmailCcItems.length === 0 &&
                    <div className={styles['note']}>
                      <p>You may enter recipient email addresses manually.</p>
                    </div>
                  }
                  { addedEmailCcItems.length > 0 &&
                    generateEmailCcItems()
                  }
                </div>
              </div>
            </div>

            <div className={styles['row']}>
              {investorTableItems && 
                <div className={styles['investor-table']}>
                  <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={investorTableItems} isScrollable={false} />
                  
                  <div className={styles['investor-combobox-container']}>
                    {showAddInvestorComboBox &&
                      <MultiSelectComboBox
                        id='add-investor-combobox'
                        size={"xsmall"}
                        itemGroups={comboBoxInvestorGroups}
                        alwaysShowItems={true}
                        hasSearchIcon={true}
                        hasHeaderCover={true}
                        value={investorSearchValue}
                        placeholder="Search Investor"
                        selected={selectedInvestorItems}
                        onChange={setInvestorSearchValue}
                        onItemClick={investorItemClickHandler}
                        onClickOutside={() => setShowAddInvestorComboBox(false)}
                        autoFocus={true}
                        onAllItemsClick={allInvestorsClickHandler}
                      />
                    }
                  </div>
                </div>
              }
            </div>
            <div className={styles['row']}>
              {proxyAdvisorTableItems && 
                <div className={`${styles['proxy-advisor-table']} ${styles['no-margin']}`}>
                  <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={proxyAdvisorTableItems} isScrollable={false} />
                  
                  <div className={styles['proxy-advisor-combobox-container']}>
                    {showAddProxyAdvisorComboBox &&
                      <MultiSelectComboBox
                        id='add-proxy-advisor-combobox'
                        size={"xsmall"}
                        itemGroups={comboBoxProxyAdvisorGroups}
                        alwaysShowItems={true}
                        hasSearchIcon={true}
                        hasHeaderCover={true}
                        value={proxyAdvisorSearchValue}
                        placeholder="Search Proxy Advisor"
                        selected={selectedProxyAdvisorItems}
                        onChange={setProxyAdvisorSearchValue}
                        onItemClick={proxyAdvisorItemClickHandler}
                        onClickOutside={() => setShowAddProxyAdvisorComboBox(false)}
                        autoFocus={true}
                        onAllItemsClick={allProxyAdvisorsClickHandler}
                      />
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
}

export default EngagementShareholderCommunicationUpdateForm;

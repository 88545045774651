import { Fragment, useEffect, useState } from 'react';
import { listClientEngagementsShareholderCommunicationsRequest } from '../../../../../requests/client-engagement-requests';
import { generateClientProxyAdvisorEngagementsShareholderCommunicationsTableItems } from '../../../../../utils/client-proxy-advisor-helpers';
import { getAuthClientId } from '../../../../../utils/auth';
import { handleRequestError } from '../../../../../utils/request-error-helpers';
import useHttp from '../../../../../hooks/use-http';
import sortArray from 'sort-array';

import Button from '../../../../../components/UI/Button/Button';
import Table from '../../../../../components/UI/Table/Table';

import styles from '../ProxyAdvisorEngagements.module.css';

const ProxyAdvisorEngagementsShareholderCommunications = (props) => {

  const clientId = getAuthClientId();
  const proxyAdvisorId = props.proxyAdvisorId;
  const isViewOnly = props.isViewOnly;

  const [isLoading, error, sendRequest] = useHttp();

  const [proxyAdvisorEngagementsShareholderCommunicationsData, setProxyAdvisorEngagementsShareholderCommunicationsData] = useState([]);
  const [tableItems, setTableItems] = useState(null);

  const [tableSortConfig, setTableSortConfig] = useState({
    'by': ['header'],
    'order': ['asc']
  });

  const proxyAdvisorEnagementsShareholderCommunicationsSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  /* Open new shareholder communication creation form. */
  const addNewShareholderCommunicationClickHandler = () => {
    window.location.href = '../engagements?shareholder_communication_id=x';
  };

  /* Send a request to list shareholder communication items and set the list with the response. */
  useEffect(() => {
    const queryParams = `proxy_advisor_id=${proxyAdvisorId}`;
    sendRequest(
      listClientEngagementsShareholderCommunicationsRequest(clientId, queryParams),
      (data) => { setProxyAdvisorEngagementsShareholderCommunicationsData(data['items']); }
    );
  }, [sendRequest, clientId, proxyAdvisorId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  /* Sort list items when sort configuration is updated. */
  useEffect(() => {
    setProxyAdvisorEngagementsShareholderCommunicationsData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, tableSortConfig);
    });
  }, [tableSortConfig]);

  /* Generate other notes list. */
  useEffect(() => {
    setTableItems(
      generateClientProxyAdvisorEngagementsShareholderCommunicationsTableItems(
        proxyAdvisorEngagementsShareholderCommunicationsData, tableSortConfig, proxyAdvisorEnagementsShareholderCommunicationsSortHandler
      )
    );
  }, [proxyAdvisorEngagementsShareholderCommunicationsData, tableSortConfig]);

  
  return (
    <div>
      { !isLoading && tableItems && tableItems['middle']['body']['items'].length === 0 && 
        <div className={styles['no-enagements-div']}>
          <div className={styles['icon-container']}>
            <div className={styles['icon']}></div>
          </div>
          {!isViewOnly &&
            <Fragment>
              <div className={styles['text-container']}>
                <h4>You Have No Shareholder Communications</h4>
                <p>Use below button to Create a Shareholder Communication</p>
              </div>
              <div className={styles['button-container']}>
                <Button
                  type={"primary"}
                  leftIcon={"plus"}
                  size={"xsmall"}
                  onClick={addNewShareholderCommunicationClickHandler}
                >
                    Create Shareholder Communication
                </Button>
              </div>
            </Fragment>
          }
          {isViewOnly &&
            <div className={styles['text-container']}>
              <h4>You Have No Shareholder Communications</h4>
            </div>
          }
        </div>
      }

      { !isLoading && tableItems && tableItems['middle']['body']['items'].length > 0 &&
        <Fragment>
          {!isViewOnly &&
            <div className={styles['proxy-advisor-engagement-list-buttons']}>  
              <div className={styles['right-buttons']}>
                <Button
                  type={'primary'}
                  size={'xsmall'}
                  leftIcon={'plus'}
                  onClick={addNewShareholderCommunicationClickHandler}
                >
                  Create Shareholder Communication
                </Button>
              </div>
            </div>
          }
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={tableItems} isScrollable={false} />
        </Fragment>
      }
    </div>
  );
}

export default ProxyAdvisorEngagementsShareholderCommunications;

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResizeDetector } from "react-resize-detector";
import { clientMenuItems } from "../../../utils/constants";
import { getClientInvestorEsgTopicRequest, getClientProxyAdvisorEsgTopicRequest, listClientAllEsgTopicsRequest, listClientEsgTopicAllCommentsRequest } from "../../../requests/client-esg-topic-requests";
import { checkIsAccountManagerViewMode, getAuthClientId, getAuthClientName, getAuthUserProfilePictureUrl, getClientAvailableModules, setLocalStorageClientData } from "../../../utils/auth";
import { getPageStyle, updatePageStyle } from "../../../utils/helpers";
import { fetchClientBasicProfileRequest } from "../../../requests/client-requests";
import { generateTopicCommentsDocument } from "../../../utils/word-export-helpers/topic-comments-export-helpers";
import { handleRequestError } from "../../../utils/request-error-helpers";
import useHttp from '../../../hooks/use-http';

import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import EsgAllTopicsContainer from "../../../components/EsgAllTopicsContainer/EsgAllTopicsContainer";
import SideNavigationBar from "../../../components/UI/SideNavigationBar/SideNavigationBar";

import styles from './EsgTopicsPage.module.css';
import AccountManagerModeHeader from "../../../components/AccountManagerModeHeader/AccountManagerModeHeader";

const EsgTopicsPage = (props) => {
  const navigate = useNavigate();

  /* eslint-disable no-unused-vars */
  const { width, height, ref } = useResizeDetector();
  const [pageStyle, setPageStyle] = useState(getPageStyle());
  const [searchParams, setSearchParams] = useSearchParams();
  const pageHeightMargin = checkIsAccountManagerViewMode() ? '161px' : '95px';
  /* eslint-disable no-unused-vars */

  const clientId = getAuthClientId();
  const clientAvailableModules = getClientAvailableModules();
  const userProfilePictureUrl = getAuthUserProfilePictureUrl();

  const [isLoading, error, sendRequest] = useHttp();

  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const [esgTopicsData, setEsgTopicsData] = useState([]);

  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (dataClient) => {
        setLocalStorageClientData(dataClient);
        setClientProfilePictureUrl(dataClient['profile_picture_url']);

        if(dataClient['module_management']['esg_topics']) {
          setBreadcrumbItems([{
            'text': 'ESG Topics',
            'href': '../investors'
          }]);

          sendRequest(
            listClientAllEsgTopicsRequest(clientId),
            (dataEsgTopics) => { setEsgTopicsData(dataEsgTopics['items']); }
          );
        }
        else {
          if(dataClient['module_management']['dashboard']) {
            window.location.href = 'dashboard';
          }
          else {
            window.location.href = 'investors';
          }
        }
      }
    );
  }, [sendRequest, clientId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const fetchInvestorQuestionComment = (investorId, questionId, setComment, setIsOpened) => {
    sendRequest(
      getClientInvestorEsgTopicRequest(clientId, investorId, questionId),
      (data) => { setComment(data['comment']); setIsOpened(true); }
    );
  }

  const fetchProxyAdvisorQuestionComment = (proxyAdvisorId, questionId, setComment, setIsOpened) => {
    sendRequest(
      getClientProxyAdvisorEsgTopicRequest(clientId, proxyAdvisorId, questionId),
      (data) => { setComment(data['comment']); setIsOpened(true); }
    );
  }

  const getSelectedTopicComments = (questionId, setEsgTopicInvestorsData, setEsgTopicProxyAdvisorsData) => {
    sendRequest(
      listClientEsgTopicAllCommentsRequest(clientId, questionId),
      (data) => {
        setEsgTopicInvestorsData(data['investors']['items']);
        setEsgTopicProxyAdvisorsData(data['proxy_advisors']['items']);
      }
    );
  }

  const exportSelectedTopicComments = (topicTitle, topicSubtitle, question) => {
    const queryParams = `include_comments=true`;
    sendRequest(
      listClientEsgTopicAllCommentsRequest(clientId, question['id'], queryParams),
      (data) => {
        generateTopicCommentsDocument(topicTitle, topicSubtitle, question, data['investors']['items'], data['proxy_advisors']['items']);
      }
    );
  }

  useEffect(() => {
    if(clientAvailableModules["esg_topics"] === false) {
      navigate('/login');
    }
  }, [navigate, clientAvailableModules]);

  return(
    <div className={styles['page']}>
      {checkIsAccountManagerViewMode() &&
        <AccountManagerModeHeader
          type={pageStyle === 'wide' ? 'wide' : 'narrow'}
          clientName={getAuthClientName()}
          exitRedirecUrl='../clients'
        />
      }

      <SideNavigationBar
        menuItems={clientMenuItems.filter(item => clientAvailableModules[item['module_id']])}
        profilePictureUrl={userProfilePictureUrl === '' ? clientProfilePictureUrl : userProfilePictureUrl}
        selected='esg-topics'
        type={pageStyle === 'wide' ? 'narrow' : 'wide'}
        onToggle={() => {
          if (pageStyle === 'narrow') {
            setPageStyle('wide');
            updatePageStyle('wide');
          }
          else {
            setPageStyle('narrow');
            updatePageStyle('narrow');
          }
        }}
      />

      <div
        className={`${styles['container']} ${styles[checkIsAccountManagerViewMode() ? 'with-account-manager-warning' : '']} ${styles[pageStyle]}`}
        style={{ height: `calc(100% - ${pageHeightMargin})`, overflowY: 'auto', overflowX: 'hidden' }}
        ref={ref}
      >
        <Breadcrumbs items={breadcrumbItems} />

        <div className={styles['page-header']}>
          <div className={styles['section']}>
            <div className={styles['row']}>
              <div className={styles['title']}>
                <h1>ESG Topics</h1>
              </div>
            </div>
          </div>
        </div>

        <div className={styles['page-body']}>
          <EsgAllTopicsContainer
            esgTopics={esgTopicsData}
            onQuestionItemClick={getSelectedTopicComments}
            onShowQuestionInvestorCommenClick={fetchInvestorQuestionComment}
            onShowQuestionProxyAdvisorCommenClick={fetchProxyAdvisorQuestionComment}
            onExportQuestionCommentsClick={exportSelectedTopicComments}
            setBreadcrumbItems={setBreadcrumbItems}
          />
        </div>
      </div>
    </div>
  );
}

export default EsgTopicsPage;

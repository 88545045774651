import  React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { checkAuthAccountManagerLoader, checkAuthClientDashboardLoader, checkAuthClientDocuSearchLoader, checkAuthClientEngagementsLoader, checkAuthClientEsgTopicsLoader, checkAuthClientLoader, checkAuthClientQuickVoteLoader, checkAuthClientReSearchLoader, getAuthUserRole } from './utils/auth';

import DashboardPage from './pages/Client/DashboardPage/DashboardPage';
import EngagementListPage from './pages/Client/EngagementListPage/EngagementListPage';
import EsgTopicsPage from './pages/Client/EsgTopicsPage/EsgTopicsPage';
import LoginPage from './pages/Auth/LoginPage/LoginPage';
import InvestorDetailsPage from './pages/Client/InvestorDetailsPage/InvestorDetailsPage';
import InvestorListPage from './pages/Client/InvestorListPage/InvestorListPage';
import ProxyAdvisorDetailsPage from './pages/Client/ProxyAdvisorDetailsPage/ProxyAdvisorDetailsPage';
import ProxyAdvisorListPage from './pages/Client/ProxyAdvisorListPage/ProxyAdvisorListPage';

import ClientSetupPage from './pages/AccountManager/ClientSetupPage/ClientSetupPage';
import ClientDetailsPage from './pages/AccountManager/ClientDetailsPage/ClientDetailsPage';
import MyClientsPage from './pages/AccountManager/MyClientsPage/MyClientsPage';
import HelpPage from './pages/Client/HelpPage/HelpPage';
import UserProfilePage from './pages/Client/UserProfilePage/UserProfilePage';
import DocuSearchPage from './pages/Client/DocuSearchPage/DocuSearchPage';
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage/ForgotPasswordPage';
import CreateCredentialsPage from './pages/Auth/CreateCredentialsPage/CreateCredentialsPage';
import QuickVotePage from './pages/Client/QuickVotePage/QuickVotePage';
import ReSearchPage from './pages/Client/ReSearchPage/ReSearchPage';

export const HomeRoute = () => {
  const userRole = getAuthUserRole();
  if (userRole === 'Account Manager') {
    return <Navigate to={'/clients'} />;
  }
  else if (userRole === 'Client'){
    return <Navigate to={'/dashboard'} />;
  }
  else {
    return <Navigate to={'/login'} />;
  }
}

export const LoginRoute = () => {
  const userRole = getAuthUserRole();
  if (userRole === 'Account Manager') {
    return <Navigate to={'/clients'} />;
  }
  else if (userRole === 'Client'){
    return <Navigate to={'/dashboard'} />;
  }
  else {
    return <LoginPage />;
  }
}

export const ForgotPasswordRoute = () => {
  const userRole = getAuthUserRole();
  if (userRole === 'Account Manager') {
    return <Navigate to={'/clients'} />;
  }
  else if (userRole === 'Client'){
    return <Navigate to={'/dashboard'} />;
  }
  else {
    return <ForgotPasswordPage />;
  }
}

export const CreateCredentialsRoute = () => {
  const userRole = getAuthUserRole();
  if (userRole === 'Account Manager') {
    return <Navigate to={'/clients'} />;
  }
  else if (userRole === 'Client'){
    return <Navigate to={'/dashboard'} />;
  }
  else {
    return <CreateCredentialsPage />;
  }
}

function App() {
  const history = createBrowserHistory();

  const router = createBrowserRouter([
    {
      path: '/login',
      element: <LoginRoute />
    },
    {
      path: '/forgot-password',
      element: <ForgotPasswordRoute />
    },
    {
      path: '/create-credentials',
      element: <CreateCredentialsRoute />
    },
    {
      path: '/clients',
      element: <MyClientsPage />,
      loader: checkAuthAccountManagerLoader
    },
    {
      path: '/clients/:clientId',
      element: <ClientDetailsPage />,
      loader: checkAuthAccountManagerLoader
    },
    {
      path: '/new-client-setup',
      element: <ClientSetupPage />,
      loader: checkAuthAccountManagerLoader
    },
    {
      path: '/client-setups/:_clientSetupId',
      element: <ClientSetupPage />,
      loader: checkAuthAccountManagerLoader
    },

    {
      path: '/dashboard',
      element: <DashboardPage />,
      loader: checkAuthClientDashboardLoader
    },
    {
      path: '/investors',
      element: <InvestorListPage />,
      loader: checkAuthClientLoader
    },
    {
      path: '/investors/:investorId',
      element: <InvestorDetailsPage />,
      loader: checkAuthClientLoader
    },
    {
      path: '/proxy-advisors',
      element: <ProxyAdvisorListPage />,
      loader: checkAuthClientLoader
    },
    {
      path: '/proxy-advisors/:proxyAdvisorId',
      element: <ProxyAdvisorDetailsPage />,
      loader: checkAuthClientLoader
    },
    {
      path: '/engagements',
      element: <EngagementListPage />,
      loader: checkAuthClientEngagementsLoader
    },
    {
      path: '/esg-topics',
      element: <EsgTopicsPage />,
      loader: checkAuthClientEsgTopicsLoader
    },
    {
      path: '/help',
      element: <HelpPage />,
      loader: checkAuthClientLoader
    },
    {
      path: '/profile',
      element: <UserProfilePage />,
      loader: checkAuthClientLoader
    },
    {
      path: '/docusearch',
      element: <DocuSearchPage />,
      loader: checkAuthClientDocuSearchLoader
    },
    /*{
      path: '/quickvote',
      element: <QuickVotePage />,
      loader: checkAuthClientQuickVoteLoader
    },
    {
      path: '/research',
      element: <ReSearchPage />,
      loader: checkAuthClientReSearchLoader
    },*/
    {
      path: '*',
      element: <Navigate to="/" replace />
    }
  ]);

  return (
    <RouterProvider history={history} router={router} />
  );
}

export default App;

import styles from './SubscriptionDetailsSection.module.css';

const SubscriptionDetailsSection = (props) => {
  const subscriptionDetails = props.subscriptionDetails;
  const clientAvailableModules = props.clientAvailableModules;

  const availableModules = {
    'dashboard': 'Dashboard',
    'investors': 'Investors',
    'investor_contacts': 'Investor - Contacts',
    'proxy_advisors': 'Proxy Advisors',
    'proxy_advisor_contacts': 'Proxy Advisors - Contacts',
    'esg_topics': 'ESG Topics',
    'engagements': 'Engagements',
    'docusearch': 'DocuSearch'
  }

  let moduleDetails = [];
  for (const [key, value] of Object.entries(availableModules)) {
    if(clientAvailableModules[key] && clientAvailableModules[key] === true) {
      moduleDetails.push(value);
    }
  }

  return (
    <div className={styles['subscription-details-container']}>
      <div className={styles['subscription-details-content']}>
        <div className={styles['title-container']}>
          <div className={styles['row']}>
            <h3>Subscription Details</h3>
          </div>
        </div>
        <div className={styles['details-form-container']}>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <h4>Contract Start Date:</h4>
            </div>
            <div className={styles['value']}>
              <p>{new Date(subscriptionDetails['contract_start_date']).toLocaleDateString('en-GB')}</p>
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <h4>Contract End Date:</h4>
            </div>
            <div className={styles['value']}>
              {subscriptionDetails['automatic_renewal'] === 'No' &&
                <p>{new Date(subscriptionDetails['contract_end_date']).toLocaleDateString('en-GB')}</p>
              }
              {subscriptionDetails['automatic_renewal'] === 'Yes' &&
                <p>-</p>
              }
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <h4>Investor Coverage:</h4>
            </div>
            <div className={styles['value']}>
              <p>{subscriptionDetails['number_of_investors']}</p>
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <h4>Module Details:</h4>
            </div>
            <div className={styles['value']}>
              <p>{moduleDetails.join(' / ')}</p>
            </div>
          </div>
        </div>
        <div className={styles['details-note-container']}>
          <div className={styles['note-content']}>
            <p>For further information about your subscription with AQTION, please <a href='mailto:support@aqtion-platform.com'>contact</a> your Account Manager.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionDetailsSection;

import { Fragment, useCallback, useEffect, useState } from "react";
import { commaSeperatedValueToNumber, getValueFromVariable, numberToCommaSeperatedValue } from "../../../utils/helpers";
import { fetchClientEngagementsOtherNoteRequest, getClientEngagementsOtherNoteAttachmentRequest } from "../../../requests/client-engagement-requests";
import { fetchClientBasicProfileRequest, fetchClientInvestorsRequest, fetchClientProxyAdvisorsRequest } from "../../../requests/client-requests";
import { getAuthClientId, getClientType } from "../../../utils/auth";
import { generateOtherNoteWordDocument } from "../../../utils/word-export-helpers/other-note-export-helpers";
import { getSquareWellFileRequest } from "../../../requests/squarewell-requests";
import { handleRequestError } from "../../../utils/request-error-helpers";
import useInputValidation from "../../../hooks/use-input-validation";
import useHttp from "../../../hooks/use-http";

import Button from "../../UI/Button/Button";
import RadioButtonGroup from "../../UI/RadioButtonGroup/RadioButtonGroup";
import EngagementFormItem from "../EngagementFormItem/EngagementFormItem";

import styles from './EngagementOtherNoteForm.module.css';

const EngagementOtherNoteUpdateForm = (props) => {

  const clientId = getAuthClientId();
  const clientType = getClientType();

  const [isLoading, error, sendRequest] = useHttp();

  const otherNoteId = props.otherNoteId;
  const investorId = props.investorId;
  const proxyAdvisorId = props.proxyAdvisorId;
  const isInvestorTypeEditable = props.isInvestorTypeEditable;
  const isViewOnly = props.isViewOnly;

  const setBreadcrumbItems = props.setBreadcrumbItems;

  const [clientName, setClientName] = useState(null);
  const [clientProfilePictureUrl, setClientProfilePictureUrl] = useState(null);
  const [clientTotalNumOfShares, setClientTotalNumOfShares] = useState(null);

  const [otherNoteData, setOtherNoteData] = useState({});
  const [investorListData, setInvestorListData] = useState([]);
  const [proxyAdvisorListData, setProxyAdvisorListData] = useState([]);

  const [isEditMode, setIsEditMode] = useState(props.type === 'new' ? true : false);

  const [noteHeader, setNoteHeader, isNoteHeaderValid, hasNoteHeaderError, noteHeaderChangeHandler, noteHeaderBlurHandler] =
    useInputValidation(getValueFromVariable(otherNoteData['note_header']), (noteHeader) => noteHeader.trim() !== '');

  const [noteBody, setNoteBody, isNoteBodyValid, hasNoteBodyError, noteBodyChangeHandler, noteBodyBlurHandler] =
    useInputValidation(getValueFromVariable(otherNoteData['note_body']), (noteBody) => noteBody.trim() !== '');

  const [investorName, setInvestorName, isInvestorNameValid, hasInvestorNameError, investorNameChangeHandler, investorNameBlurHandler] =
    useInputValidation(
      otherNoteData['investor_type'] === 'Investor' ? getValueFromVariable(otherNoteData['investor']['name']) : '', 
      (investorName) => investorName.trim() !== ''
    );

  const [investorNumOfShares, setInvestorNumOfShares, isInvestorNumOfSharesValid, hasInvestorNumOfSharesError, investorNumOfSharesChangeHandler, investorNumOfSharesBlurHandler] =
    useInputValidation(
      otherNoteData['investor_type'] === 'Investor' ? getValueFromVariable(otherNoteData['investor']['num_of_shares']) : '', 
      () => true
    );

  const [proxyAdvisorName, setProxyAdvisorName, isProxyAdvisorNameValid, hasProxyAdvisorNameError, proxyAdvisorNameChangeHandler, proxyAdvisorNameBlurHandler] =
    useInputValidation(
      otherNoteData['investor_type'] === 'Proxy Advisor' ? getValueFromVariable(otherNoteData['proxy_advisor']['name']) : '', 
      (proxyAdvisorName) => proxyAdvisorName.trim() !== ''
    );

  const [noteAttachments, setNoteAttachments] = useState(getValueFromVariable(otherNoteData['attachments']));

  const [createdAt, setCreatedAt] = useState(getValueFromVariable(otherNoteData['created_at'], 'date'));
  const [createdBy, setCreatedBy] = useState(getValueFromVariable(otherNoteData['created_by']));
  const [updatedAt, setUpdatedAt] = useState(getValueFromVariable(otherNoteData['updated_at'], 'date'));
  const [updatedBy, setUpdatedBy] = useState(getValueFromVariable(otherNoteData['update_by']));


  const [selectedInvestorType, setSelectedInvestorType] = useState(props.investorType ? props.investorType : 'type-investor');

  const radioButtonChangeToInvestorHandler = () => {
    setSelectedInvestorType('type-investor');
  }

  const radioButtonChangeToProxyAdvisorHandler = () => {
    setSelectedInvestorType('type-proxy-advisor');
  }

  const investorTypeRadioButtonItems = [
    {
      id: 'type-investor',
      value: 'Investor',
      text: 'Investor',
      isChecked: true,
      onChange: radioButtonChangeToInvestorHandler
    },
    {
      id: 'type-proxy-advisor',
      value: 'Proxy Advisor',
      text: 'Proxy Advisor',
      isChecked: false,
      onChange: radioButtonChangeToProxyAdvisorHandler
    }
  ];

  
  /* Send a request to fetch other note item's details, investor list and 
   * proxy advisor list for the client to fill the form and combobox values. */
  useEffect(() => {
    sendRequest(
      fetchClientBasicProfileRequest(clientId),
      (data) => { 
        setClientName(data['name']);
        setClientProfilePictureUrl(data['profile_picture_url']);
        setClientTotalNumOfShares(data['total_num_of_shares']);
      }
    );

    sendRequest(
      fetchClientEngagementsOtherNoteRequest(clientId, otherNoteId),
      (data) => {
        setOtherNoteData(data);
     
        if(setBreadcrumbItems !== undefined) {
          setBreadcrumbItems(prevState => {
            const items = JSON.parse(JSON.stringify(prevState));
            if(items.length < 3){
              items[1]['text'] = 'Other Notes';
              items[1]['href'] = '../engagements?type=other-notes';
              items.push({
                'text': data['note_header'],
                'href': ''
              });
            }
            return items;
          });
        }
      }
    );

    if (isInvestorTypeEditable || selectedInvestorType === 'type-investor') {
      sendRequest(
        fetchClientInvestorsRequest(clientId),
        (data) => {
          if(investorId !== undefined) {
            const investor = data['items'].find(item => item['id'].toString() === investorId)
            setInvestorName(investor['name']);
            setInvestorNumOfShares(investor['num_of_shares']);
          }
          setInvestorListData(data['items']);
        }
      );
    }
    else {
      setInvestorListData([]);
    }

    if (isInvestorTypeEditable || selectedInvestorType === 'type-proxy-advisor') {
      sendRequest(
        fetchClientProxyAdvisorsRequest(clientId),
        (data) => {
          data['items'] = data['items'].filter(item => item['type'] === 'Proxy Advisor');
          if(proxyAdvisorId !== undefined) {
            const proxyAdvisor = data['items'].find(item => item['id'].toString() === proxyAdvisorId)
            setProxyAdvisorName(proxyAdvisor['name']);
          }
          setProxyAdvisorListData(data['items']);
        }
      );
    }
    else {
      setProxyAdvisorListData([]);
    }
  }, [sendRequest, clientId, otherNoteId, isInvestorTypeEditable, selectedInvestorType, investorId, proxyAdvisorId,
      setInvestorName, setInvestorNumOfShares, setProxyAdvisorName, setBreadcrumbItems]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  const investorComboboxItems = investorListData.map(item => {
    return {
      'id': item['id'],
      'text': item['name']
    };
  });

  const proxyAdvisorComboboxItems = proxyAdvisorListData.map(item => {
    return {
      'id': item['id'],
      'text': item['name']
    };
  });


  const setInitialValues = useCallback(() => {
    /* Shared fields for both investor and proxy advisor notes. */
    setNoteHeader(getValueFromVariable(otherNoteData['note_header']));
    setNoteBody(getValueFromVariable(otherNoteData['note_body']));
    setNoteAttachments(getValueFromVariable(otherNoteData['attachments']));
    setCreatedAt(getValueFromVariable(otherNoteData['created_at'], 'date'));
    setCreatedBy(getValueFromVariable(otherNoteData['created_by']));
    setUpdatedAt(getValueFromVariable(otherNoteData['updated_at'], 'date'));
    setUpdatedBy(getValueFromVariable(otherNoteData['updated_by']));
    /* Investor specific fields. */
    setInvestorName(otherNoteData['investor_type'] === 'Investor' ? getValueFromVariable(otherNoteData['investor']['name']) : '');
    setInvestorNumOfShares(otherNoteData['investor_type'] === 'Investor' ? getValueFromVariable(otherNoteData['investor']['num_of_shares']) : '');
    /* Proxy advisor specific fields. */
    setProxyAdvisorName(otherNoteData['investor_type'] === 'Proxy Advisor' ? getValueFromVariable(otherNoteData['proxy_advisor']['name']) : '');
  }, [otherNoteData, setNoteHeader, setNoteBody, setInvestorName, setInvestorNumOfShares, setProxyAdvisorName]);

  useEffect(() => {
    setInitialValues();
  }, [setInitialValues]);


  const cancelClickHandler = () => {
    setInitialValues();
    setIsEditMode(false);
  }

  const otherNoteAttachmentsChangeHandler = (event) => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if(file.size > 500000){
        alert('File size should be smaller than 450Kb');
        return false;				
      }

      var data = new FormData();
			data.append('attachment', file, file.name);
      props.onAttachmentUploadClick(data, setNoteAttachments);
    }
  }

  const otherNoteAttachmentRemoveHandler = (clickedAttachmentId) => {
    setNoteAttachments(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      const attachmentIndex = items.findIndex(item => item['id'] === clickedAttachmentId);
      items.splice(attachmentIndex, 1);
      return items;
    });
  }

  const isOtherNoteFormValid = () => {
    noteHeaderBlurHandler();
    noteBodyBlurHandler();
    
    const isGeneralInfoValid = isNoteHeaderValid && isNoteBodyValid;
    if (selectedInvestorType === 'type-investor') {
      investorNameBlurHandler();
      investorNumOfSharesBlurHandler();
      return isGeneralInfoValid && isInvestorNameValid && isInvestorNumOfSharesValid;
    }
    else if (selectedInvestorType === 'type-proxy-advisor') {
      proxyAdvisorNameBlurHandler();
      return isGeneralInfoValid && isProxyAdvisorNameValid;
    }
    else {
      return false;
    }
  }

  const updateOtherNoteClickHandler = () => {
    if (!isOtherNoteFormValid()) {
      return;
    }

    const otherNoteData = {
      'note_header': noteHeader,
      'note_body': noteBody,
      'note_attachments': noteAttachments
    };

    if (selectedInvestorType === 'type-investor') {
      const itemIndex = investorComboboxItems.findIndex(item => item['text'].toLowerCase() === investorName.toLowerCase());
      otherNoteData['investor_type'] = 'Investor';
      otherNoteData['investor'] = {
        'id': investorComboboxItems[itemIndex]['id'],
        'name': investorName
      };
      if(clientType === 'Corporate') {
        otherNoteData['investor']['num_of_shares'] = commaSeperatedValueToNumber(investorNumOfShares);
        otherNoteData['investor']['isc'] = (commaSeperatedValueToNumber(investorNumOfShares) / clientTotalNumOfShares) * 100;
      }
      else {
        otherNoteData['investor']['num_of_shares'] = 0;
        otherNoteData['investor']['isc'] = 0;
      }
      props.onSaveClick(otherNoteData, setOtherNoteData);
      setIsEditMode(false);
    }
    else if (selectedInvestorType === 'type-proxy-advisor') {
      const itemIndex = proxyAdvisorComboboxItems.findIndex(item => item['text'] === proxyAdvisorName);
      otherNoteData['investor_type'] = 'Proxy Advisor';
      otherNoteData['proxy_advisor'] = {
        'id': proxyAdvisorComboboxItems[itemIndex]['id'],
        'name': proxyAdvisorName
      };
      props.onSaveClick(otherNoteData, setOtherNoteData);
      setIsEditMode(false);
    }
    else {
      return;
    }
  }

  const exportToWordClickHandler = () => { 
    sendRequest(
      getSquareWellFileRequest({'path': clientProfilePictureUrl}),
      (profilePictureData) => {
        if(selectedInvestorType === 'type-investor') {
          generateOtherNoteWordDocument(
            clientName, clientType, profilePictureData, 
            investorName, selectedInvestorType, numberToCommaSeperatedValue(investorNumOfShares),
            noteHeader, noteBody, noteAttachments,
            createdBy,
            createdAt !== '' && createdAt !== null ? createdAt.toLocaleDateString('en-GB') : '-',
            updatedBy !== '' ? updatedBy : '-',
            updatedAt !== '' && updatedAt !== null ? updatedAt.toLocaleDateString('en-GB') : '-'
          );
        }
        else if(selectedInvestorType === 'type-proxy-advisor') {
          generateOtherNoteWordDocument(
            clientName, clientType, profilePictureData, 
            proxyAdvisorName, selectedInvestorType, '-',
            noteHeader, noteBody, noteAttachments,
            createdBy,
            createdAt !== '' && createdAt !== null ? createdAt.toLocaleDateString('en-GB') : '-',
            updatedBy !== '' ? updatedBy : '-',
            updatedAt !== '' && updatedAt !== null ? updatedAt.toLocaleDateString('en-GB') : '-'
          );
        }
      }
    );    
  }

  const openAttachmentInNewTab = (attachmentUrl) =>{
    if(attachmentUrl !== ''){ 
      sendRequest(getClientEngagementsOtherNoteAttachmentRequest(attachmentUrl), (data) => {
        var urlCreator = window.URL || window.webkitURL;
        window.open(urlCreator.createObjectURL(data), '_blank');
      })
    }
  
    if(!isLoading && error !== undefined) {
      handleRequestError(error);
    }
  }

  return (
    <div className={styles['other-note-container']}>
      <div className={styles['header']}>
        {props.hasBackButton &&
          <div className={styles['back-button-container']}>
            <div className={styles['back-button']} onClick={props.onBackClick}>
              <div className={styles['back-icon']}></div>
              <h4>Back</h4>
            </div>
          </div>
        }

        <div className={styles['other-note-title-container']}>
          {!isEditMode && 
            <Fragment>
              <div className={styles['title']}>
                <h2>{noteHeader}</h2>
              </div>
              <div className={styles['type-chip']}>
                <div className={`${styles['icon']} ${styles['other-note']}`}></div>
                <div className={styles['label']}>
                  <p>Other Note</p>
                </div>
              </div>
            </Fragment>
          }
          {isEditMode && 
            <EngagementFormItem
              id='investor-other-note#note-header'
              type='text-input'
              label='Note Header'
              value={noteHeader}
              placeholder=''
              hasError={hasNoteHeaderError}
              onBlur={noteHeaderBlurHandler}
              onChange={noteHeaderChangeHandler}
            />
          }
        </div>

        {!isEditMode && 
          <div className={styles['button-container']}>
            <Button type={"secondary"} size={"xsmall"} leftIcon={'download'} onClick={exportToWordClickHandler}>Export to Word</Button>
            {!isViewOnly &&
              <Button type={'primary'} size={'xsmall'} leftIcon={'edit'} onClick={() => setIsEditMode((prev) => !prev)}>Edit</Button>
            }
          </div>
        }
        {isEditMode && 
          <div className={styles['button-container']}>
            <Button type={"cancel"} size={"xsmall"} onClick={cancelClickHandler}>Cancel</Button>
            <Button type={'primary'} size={'xsmall'} onClick={updateOtherNoteClickHandler}>Save</Button>
          </div>
        }
      </div>
      <div className={styles['body']}>
        <div className={styles['other-note-content-container']}>
          <div className={styles['row']}>
            <EngagementFormItem
              id='investor-other-note#note-body'
              type={isEditMode ? 'rich-text-input' : 'rich-text-content'}
              size='long'
              label='Note Body'
              value={noteBody}
              placeholder=''
              hasError={hasNoteBodyError}
              onBlur={noteBodyBlurHandler}
              onChange={noteBodyChangeHandler}
            />
          </div>

          <div className={styles['row']}>
            {!isEditMode && noteAttachments && noteAttachments.length > 0 &&
              <Fragment>
                {noteAttachments.map(item => {
                  return (
                    <EngagementFormItem
                      key={`attached-item-${item['id']}`}
                      type='attached-item'
                      value={item['attachment_name']}
                      isRemovable={false}
                      onItemClick={() => openAttachmentInNewTab(item['attachment_url'])}
                    />
                  );
                })}
              </Fragment>
            }

            {isEditMode && noteAttachments && noteAttachments.length === 0 &&
              <EngagementFormItem
                type='new-attachment-button'
                onChange={otherNoteAttachmentsChangeHandler}
              />
            }

            {isEditMode && noteAttachments && noteAttachments.length > 0 &&
              <Fragment>
                {noteAttachments.map(item => {
                  return (
                    <EngagementFormItem
                      key={`attached-item-${item['id']}`}
                      type='attached-item'
                      value={item['attachment_name']}
                      isRemovable={true}
                      // onItemClick={() => openAttachmentInNewTab(item['attachment_url'])}
                      onRemoveClick={() => otherNoteAttachmentRemoveHandler(item['id'])}
                    />
                  );
                })}
                
                <EngagementFormItem
                  type='new-attachment-icon-button'
                  onChange={otherNoteAttachmentsChangeHandler}
                />
              </Fragment>
            }
          </div>
        </div>

        <div className={styles['other-note-details-container']}>
          { isInvestorTypeEditable && isEditMode &&
            <div className={styles['row']}>
              <RadioButtonGroup
                name='type-investor'
                items={investorTypeRadioButtonItems}
                selected={selectedInvestorType}
              />
            </div>
          }

          { selectedInvestorType === 'type-investor' && 
            <div className={styles['row']}>
              <div className={clientType === 'Corporate' ? styles['half-col'] : ''}>
                <EngagementFormItem
                  id='investor-other-note#investor-name'
                  type={isEditMode ? 'combobox' : 'text'}
                  label='Investor'
                  value={investorName}
                  items={investorComboboxItems}
                  placeholder='Select Investor'
                  hasError={hasInvestorNameError}
                  onBlur={investorNameBlurHandler}
                  onChange={investorNameChangeHandler}
                  isDisabled={!isInvestorTypeEditable}
                />
              </div>
              {clientType === 'Corporate' && 
                <div className={styles['half-col']}>
                  <EngagementFormItem
                    id='investor-other-note#investor-num-of-shares'
                    type={isEditMode ? 'number-input' : 'text'}
                    label='# of Shares'
                    value={numberToCommaSeperatedValue(investorNumOfShares)}
                    placeholder='Enter # of Shares'
                    hasError={hasInvestorNumOfSharesError}
                    onBlur={investorNumOfSharesBlurHandler}
                    onChange={investorNumOfSharesChangeHandler}
                  />
                </div>
              }
            </div>
          }
          { selectedInvestorType === 'type-proxy-advisor' && 
            <div className={styles['row']}>
              <EngagementFormItem
                id='investor-other-note#proxy-advisor-name'
                type={isEditMode ? 'combobox' : 'text'}
                label='Proxy Advisor'
                value={proxyAdvisorName}
                items={proxyAdvisorComboboxItems}
                placeholder='Select Proxy Advisor'
                hasError={hasProxyAdvisorNameError}
                onBlur={proxyAdvisorNameBlurHandler}
                onChange={proxyAdvisorNameChangeHandler}
              />
            </div>
          }
          
          <div className={styles['separator']}></div>
          <div className={styles['row']}>
            <div className={styles['half-col']}>
              <EngagementFormItem
                type='text'
                label='Created By'
                value={createdBy}
              />
            </div>
            <div className={styles['half-col']}>
              <EngagementFormItem
                type='text'
                label='Created On'
                value={createdAt !== '' && createdAt !== null ? createdAt.toLocaleDateString('en-GB') : '-'}
              />
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['half-col']}>
              <EngagementFormItem
                type='text'
                label='Edited By'
                value={updatedBy !== '' ? updatedBy : '-'}
              />
            </div>
            <div className={styles['half-col']}>
              <EngagementFormItem
                type='text'
                label='Edited On'
                value={updatedAt !== '' && updatedAt !== null ? updatedAt.toLocaleDateString('en-GB') : '-'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EngagementOtherNoteUpdateForm;

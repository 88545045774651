import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import DropdowmItem from './DropdownItem';
import styles from './Dropdown.module.css';

const Dropdown = (props) => {
  const [showItems, setShowItems] = useState(false);
  const [selected, setSelected] = useState(props.selected);
  const [searchValue, setSearchValue] = useState(props.selected);

  const hasSearchBox = props.hasSearchBox === undefined ? false : props.hasSearchBox;

  // const [headerColor, setHeaderColor] = useState('');
  // const [chipColor, setChipColor] = useState('');

  const hasHeaderColor = props.hasHeaderColor;
  const hasChip = props.hasChip;

  const isReadOnly = props.isReadOnly;

  const placeholder = props.placeholder ? props.placeholder : 'Select...';

  const getChipColor = useCallback(() => {
    if (hasChip) {
      const filteredItems = props.items.filter(item => item['value'] === selected);
      if (filteredItems.length > 0) {
        return filteredItems[0]['chipColor'] ? filteredItems[0]['chipColor'] : '';
      }
    }
    return '';
  }, [selected, hasChip, props.items]);

  const getHeaderColor = useCallback(() => {
    if (hasHeaderColor) {
      const filteredItems = props.items.filter(item => item['value'] === selected);
      if (filteredItems.length > 0) {
        return filteredItems[0]['headerColor'] ? filteredItems[0]['headerColor'] : 'unknown';
      }
    }
    return '';
  }, [selected, hasHeaderColor, props.items]);

  const headerColor = getHeaderColor();
  const chipColor = getChipColor();
    
  const ref = useRef(null);

  const showItemsHandler = () => {
    if(!!isReadOnly) {
      return;
    }

    if(showItems === true && hasSearchBox){ 
      return;
    }

    if(!props.isDisabled) {
      setShowItems((prevState) => {
        return !prevState;
      });
    }
  }

  const selectItemHandler = (selectedValue) => {
    setSelected(selectedValue);
    setShowItems(false);
    if(props.onItemClick !== undefined) {
      props.onItemClick(selectedValue);
    }

    if(props.onBlur !== undefined) {
      props.onBlur();
    }

    if(hasSearchBox) {
      setSearchValue(selectedValue);
    }
  }

  const inputChangeHandler = (event) => {
    setSearchValue(event.target.value);
  }

  const tabKeyPressHandler = (event) => {
    if(event.key === 'Tab') {
      const filteredItems = props.items.filter(item => (!hasSearchBox || item['value'].toLowerCase().includes(searchValue.toLowerCase())));
      if(filteredItems.length === 1) {
        selectItemHandler(searchValue);
      }
      else {
        setShowItems(false);
        setSearchValue(selected);
        if(props.onBlur !== undefined) {
          props.onBlur();
        }
      }
    }
  }

  const propsOnBlur = props.onBlur;
  useEffect(() => {
    const handleClickOutside = (event) => {
      if(!ref.current.contains(event.target) && showItems) {
        setShowItems(false);

        if(hasSearchBox) {
          setSearchValue(selected);
        }

        if(propsOnBlur !== undefined) {
          propsOnBlur();
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, propsOnBlur, showItems, hasSearchBox, selected]);

  const propsSelected = props.selected;
  useEffect(() => {
    setSelected(propsSelected);
  }, [propsSelected]);

  const dropdownItems = props.items.filter(item => (!hasSearchBox || item['value'].toLowerCase().includes(searchValue.toLowerCase()))).map(item => {
    return (
      <DropdowmItem 
        key={item['id']}
        value={item['value']}
        isSelected={item['value'] === selected}
        onSelectItem={selectItemHandler}
        hasChip={props.hasChip}
        chipColor={props.hasChip && item['chipColor'] ? item['chipColor'] : ''}
      />
    );
  })

  const isDisabled = props.isDisabled ? styles['disabled'] : '';
  const sizeStyle = props.size === undefined ? styles['base'] : styles[props.size];
  const errorStyle = props.hasError ? styles['error'] : '';
  const withLabelStyle = props.label !== undefined ? styles['with-label'] : '';
  const viewOnlyStyle = !!isReadOnly ? styles['view-only'] : '';
  
  const headerStyle = headerColor !== '' ? styles[headerColor] : '';
  const chipStyle = chipColor !== '' ? styles[chipColor] : '';

  const customStyle = {};
  if (props.width !== undefined) {
    customStyle['width'] = props.width
  }

  const itemContainerCustomStyle = {};
  if (props.itemContainerWidth !== undefined) {
    itemContainerCustomStyle['width'] = props.itemContainerWidth;
  }
  if (props.itemContainerFloat !== undefined) {
    if (props.itemContainerFloat === 'right') {
      itemContainerCustomStyle['right'] = '0px';
      itemContainerCustomStyle['left'] = 'auto';
    }
    else if (props.itemContainerFloat === 'left') {
      itemContainerCustomStyle['right'] = 'auto';
      itemContainerCustomStyle['left'] = '0px';
    }
  }

  return(
    <Fragment>
      <div className={`${styles['dropdown-container']} ${withLabelStyle} ${sizeStyle} ${isDisabled}`} style={customStyle}>
        { props.label && <label className={styles['dropdown-label']}>{props.label}</label> }
        <div className='dropdown-wrapper' ref={ref}>
          
          { !hasSearchBox && 
            <div id={props.id} className={`${styles['dropdown-header']} ${errorStyle} ${headerStyle} ${viewOnlyStyle}`} onClick={showItemsHandler} data-selected={selected} data-placeholder={placeholder} tabIndex={props.tabIndex}>
              <div className={`${styles['text']} ${props.hasChip ? styles['has-chip'] : ''}`}>
                { (selected === null || selected === undefined || selected === '') && 
                  <p className={styles['placeholder']}>{placeholder}</p>
                }
                { selected !== '' &&
                  <Fragment>
                    { hasChip && 
                      <div className={`${styles['chip']} ${chipStyle}`}></div>
                    }
                    <p className={styles['value']}>{selected}</p>
                  </Fragment>
                }
              </div>
              <div className={styles['icon']}></div>
            </div>
          }

          { hasSearchBox && 
            <div id={props.id} className={`${styles['dropdown-text-header']}`} data-selected={selected} data-placeholder={placeholder}>
              <input
                id={props.id} 
                className={`${styles['text-input']} ${sizeStyle} ${errorStyle}`}
                placeholder={props.placeholder}
                value={searchValue}
                disabled={isDisabled}
                onChange={inputChangeHandler}
                onClick={showItemsHandler}
                onFocus={showItemsHandler}
                onKeyDown={tabKeyPressHandler}
                tabIndex={props.tabIndex}
              />
              <div className={styles['icon']}></div>
            </div>
          }

          { showItems &&
            <div className={styles['dropdown-list']} style={itemContainerCustomStyle}>
              { props.listLabel && 
                <div className={styles['list-label']}>
                  <p>{props.listLabel}</p>
                </div>
              }
              {dropdownItems}
            </div>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default Dropdown

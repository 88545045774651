import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { commaSeperatedValueToNumber, createId, getValueFromDropdown, getValueFromParagraph, getValueFromTextInput, numberToCommaSeperatedValue, setValueOfParagraph } from '../../../../utils/helpers';
import { generateClientDetailsShowInvestorListTableItems, generateClientDetailsEditInvestorListTableItems } from '../../../../utils/account-manager-client-details-helpers';
import { countryDropdownItems } from '../../../../utils/constants';
import { utils, writeFileXLSX } from 'xlsx';

import Button from '../../../../components/UI/Button/Button';
import Modal from '../../../../components/UI/Modal/Modal';
import Table from '../../../../components/UI/Table/Table';

import styles from './ClientDetailsInvestorList.module.css';

const ClientDetailsInvestorList = (props) => {
  const clientName = props.clientName;
  const clientType = props.clientType;
  const totalNumOfShares = props.totalNumOfShares;
  const numberOfInvestors = props.numberOfInvestors;

  const [isEditMode, setIsEditMode] = useState(false);
  const [tableItems, setTableItems] = useState(generateClientDetailsShowInvestorListTableItems(clientType, props.investorListData['items'], totalNumOfShares));
  const [investorListData, setInvestorListData] = useState(props.investorListData);
  const [squareWellInvestors, setSquareWellInvestors] = useState(props.squareWellInvestors);
  const [investorListActivateApproval, setInvestorListActivateApproval] = useState(false);
  const [investorListSaveApproval, setInvestorListSaveApproval] = useState(false);
  const [investorListDeleteAllApproval, setInvestorListDeleteAllApproval] = useState(false);
  
  const searchInvestorItems = useMemo(() => squareWellInvestors.map(item => {
    return {
      'id': item['id'],
      'text': item['name']
    }
  }), [squareWellInvestors]);


  const createInvestorRow = useCallback((item, selectInvestorComboboxItemHandler, iscCalculateFunction, deleteRowFunction) => {
    let newItemId = '';
    if(item['is_custom'] && item['custom_investor_id'] !== undefined) {
      newItemId = `client-custom-investor-existing-${item['custom_investor_id']}`;
    }
    else if(!item['is_custom'] && item['squarewell_investor_id'] !== undefined) {
      newItemId = `client-squarewell-investor-existing-${item['squarewell_investor_id']}`;
    }
    else {
      newItemId = `client-new-${createId(4)}`;
    }
    const investorName = item['name'] !== null ? item['name'] : '';
    const country = item['country'] !== null ? item['country'] : '';
    
    let numberOfShares = '-';
    let iscPercentage = '-';
    let lastMeetingVotes = '-';
    if(clientType === 'Corporate') {
      numberOfShares = item['num_of_shares'] !== null ? item['num_of_shares'] : '';
      iscPercentage = numberOfShares !== '' ? ((numberOfShares / totalNumOfShares) * 100).toFixed(2) : '-';
      lastMeetingVotes = item['last_general_meeting_votes'] !== null ? item['last_general_meeting_votes'] : '';
    }
    
    const isCustom = item['is_custom'] !== null ? item['is_custom'] : false;
    const countryColumnType = isCustom ? 'dropdown' : 'default';

    return {
      'id': newItemId,
      'columns': [
        {
          'id': `${newItemId}#investor-name`,
          'type': 'combobox',
          'placeholder': 'Enter Investor Name',
          'value': investorName,
          'lastSavedValue': investorName,
          'items': searchInvestorItems,
          'onSelected': selectInvestorComboboxItemHandler,
          'width': 192,
          'stretch': true
        },
        {
          'id': `${newItemId}#country`,
          'type': countryColumnType,
          'placeholder': 'Country',
          'selected': country,
          'value': country,
          'text': country,
          'items': countryDropdownItems,
          'width': 168,
          'stretch': true
        },
        {
          'id': `${newItemId}#number-of-shares`,
          'type': 'number-input',
          'placeholder': 'Enter Number',
          'value': numberToCommaSeperatedValue(numberOfShares),
          'onChange': iscCalculateFunction,
          'isDisabled': clientType === 'Corporate' ? false : true,
          'width': 192,
          'stretch': true
        },
        {
          'id': `${newItemId}#isc-percentage`,
          'text': iscPercentage,
          'width': 48,
          'stretch': true
        },
        {
          'id': `${newItemId}#last-meeting-votes`,
          'type': 'text-input',
          'placeholder': 'Enter Votes',
          'value': lastMeetingVotes,
          'isDisabled': clientType === 'Corporate' ? false : true,
          'width': 320,
          'stretch': true
        },
        { 
          'id': `${newItemId}#delete-button`,
          'type': 'icon-button',
          'icon': 'trash',
          'onClick': deleteRowFunction
        }
      ]
    };
  }, [clientType, searchInvestorItems, totalNumOfShares]);


  const updateInvestorTableItems = useCallback((tempTableItems) => {
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const investorName = getValueFromTextInput(`${itemId}#investor-name`);
        const country = getValueFromDropdown(`${itemId}#country`);
        
        let numberOfShares = '-';
        let iscPercentage = '-';
        let lastMeetingVotes = '-';
        if(clientType === 'Corporate') {
          numberOfShares = commaSeperatedValueToNumber(getValueFromTextInput(`${itemId}#number-of-shares`));
          iscPercentage = (numberOfShares !== '' && !isNaN(numberOfShares)) ? ((numberOfShares / totalNumOfShares) * 100).toFixed(2) : '-';
          lastMeetingVotes = getValueFromTextInput(`${itemId}#last-meeting-votes`);
        }

        item['columns'][0]['value'] = investorName;
        item['columns'][0]['lastSavedValue'] = investorName;
        item['columns'][1]['selected'] = country;
        item['columns'][1]['value'] = country;
        item['columns'][2]['value'] = clientType === 'Corporate' ? (isNaN(numberOfShares) ? '' : numberToCommaSeperatedValue(numberOfShares)) : '-';
        item['columns'][3]['text'] = iscPercentage;
        item['columns'][4]['value'] = lastMeetingVotes;
      });
    }
    return tempTableItems['middle']['body']['items'];
  }, [clientType, totalNumOfShares]);


  const removeAllInvestorsClickHandler = useCallback(() => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = [];
      newTableItems['middle']['header']['items'][0]['columns'][0]['text'] = `INVESTOR NAME (${newTableItems['middle']['body']['items'].length})`;

      const totalEnteredShares = newTableItems['middle']['body']['items'].reduce((acc, curr) => {
        const numberOfShares = getValueFromTextInput(`${curr['id']}#number-of-shares`) !== '' ? parseInt(commaSeperatedValueToNumber(getValueFromTextInput(`${curr['id']}#number-of-shares`))) : 0;
        return acc + numberOfShares;
      }, 0);
      newTableItems['middle']['header']['items'][0]['columns'][2]['text'] = `# OF SHARES (${numberToCommaSeperatedValue(totalEnteredShares)})`;

      if (newTableItems['middle']['body']['items'].length >= numberOfInvestors) {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = true;
      }
      else {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = false;
      }

      newTableItems['middle']['header']['buttons'][1]['isVisible'] = false;

      return newTableItems;
    });
    setInvestorListDeleteAllApproval(false);
  }, [numberOfInvestors])


  const addNewInvestorRowHandler = useCallback((createInvestorRow, selectInvestorComboboxItemHandler, iscCalculateHandler, removeInvestorRowHandler) => () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);
      if(newTableItems['middle']['body']['items'].length >= numberOfInvestors) {
        return newTableItems;
      }

      newTableItems['middle']['body']['items'].push(
        createInvestorRow({
          'name': '',
          'country': '',
          'num_of_shares': '',
          'last_general_meeting_votes': '',
          'is_custom': true
        }, selectInvestorComboboxItemHandler, iscCalculateHandler, removeInvestorRowHandler)
      );
      newTableItems['middle']['header']['items'][0]['columns'][0]['text'] = `INVESTOR NAME (${newTableItems['middle']['body']['items'].length})`;

      const totalEnteredShares = newTableItems['middle']['body']['items'].reduce((acc, curr) => {
        const numberOfShares = getValueFromTextInput(`${curr['id']}#number-of-shares`) !== '' ? parseInt(commaSeperatedValueToNumber(getValueFromTextInput(`${curr['id']}#number-of-shares`))) : 0;
        return acc + numberOfShares;
      }, 0);
      newTableItems['middle']['header']['items'][0]['columns'][2]['text'] = `# OF SHARES (${numberToCommaSeperatedValue(totalEnteredShares)})`;

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedComboboxItems = searchInvestorItems.filter(comboboxItem => !selectedItems.includes(comboboxItem['text']) || comboboxItem['text'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedComboboxItems;
      });

      if (newTableItems['middle']['body']['items'].length >= numberOfInvestors) {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = true;
      }
      else {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = false;
      }

      newTableItems['middle']['header']['buttons'][1]['isVisible'] = true;

      return newTableItems;
    });
  }, [numberOfInvestors, searchInvestorItems, updateInvestorTableItems]);

  
  const removeInvestorRowHandler = useCallback((itemId) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);

      const itemIndex = newTableItems['middle']['body']['items'].findIndex(item => item['id'] === itemId.split('#')[0]);
      if (itemIndex > -1) {
        newTableItems['middle']['body']['items'].splice(itemIndex, 1);
      }

      newTableItems['middle']['header']['items'][0]['columns'][0]['text'] = `INVESTOR NAME (${newTableItems['middle']['body']['items'].length})`;

      const totalEnteredShares = newTableItems['middle']['body']['items'].reduce((acc, curr) => {
        const numberOfShares = getValueFromTextInput(`${curr['id']}#number-of-shares`) !== '' ? parseInt(commaSeperatedValueToNumber(getValueFromTextInput(`${curr['id']}#number-of-shares`))) : 0;
        return acc + numberOfShares;
      }, 0);
      newTableItems['middle']['header']['items'][0]['columns'][2]['text'] = `# OF SHARES (${numberToCommaSeperatedValue(totalEnteredShares)})`;

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedComboboxItems = searchInvestorItems.filter(comboboxItem => !selectedItems.includes(comboboxItem['text']) || comboboxItem['text'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedComboboxItems;
      });

      if (newTableItems['middle']['body']['items'].length >= numberOfInvestors) {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = true;
      }
      else {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = false;
      }

      if (newTableItems['middle']['body']['items'].length === 0) {
        newTableItems['middle']['header']['buttons'][1]['isVisible'] = false;
      }
      else {
        newTableItems['middle']['header']['buttons'][1]['isVisible'] = true;
      }

      return newTableItems;
    });
  }, [searchInvestorItems, numberOfInvestors, updateInvestorTableItems]);


  const selectInvestorComboboxItemHandler = useCallback((itemId, investorName) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);

      newTableItems['middle']['body']['items'].filter(item => item['id'] === itemId).forEach(item => {
        const squareWellItemIndex = squareWellInvestors.findIndex(item => item['name'] === investorName);
        if(squareWellItemIndex > -1) {
          item['columns'][1]['type'] = 'default';
          item['columns'][1]['selected'] = squareWellInvestors[squareWellItemIndex]['country'];
          item['columns'][1]['value'] = squareWellInvestors[squareWellItemIndex]['country'];
          item['columns'][1]['text'] = squareWellInvestors[squareWellItemIndex]['country'];
        }
        else {
          item['columns'][1]['type'] = 'dropdown';
        }
        item['columns'][0]['value'] = investorName;
        item['columns'][0]['lastSavedValue'] = investorName;
      });

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedComboboxItems = searchInvestorItems.filter(comboboxItem => !selectedItems.includes(comboboxItem['text']) || comboboxItem['text'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedComboboxItems;
      });
      
      return newTableItems;
    });
  }, [searchInvestorItems, squareWellInvestors, updateInvestorTableItems]);


  const iscCalculateHandler = useCallback((itemId) => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);

      const totalEnteredShares = newTableItems['middle']['body']['items'].reduce((acc, curr) => {
        const numberOfShares = getValueFromTextInput(`${curr['id']}#number-of-shares`) !== '' ? parseInt(commaSeperatedValueToNumber(getValueFromTextInput(`${curr['id']}#number-of-shares`))) : 0;
        return acc + numberOfShares;
      }, 0);
      setValueOfParagraph('client-investor-list-num-of-shares', `# OF SHARES (${numberToCommaSeperatedValue(totalEnteredShares)})`);

      const updatedSharesValue = commaSeperatedValueToNumber(getValueFromTextInput(`${itemId}#number-of-shares`));
      if(updatedSharesValue !== '') {
        if(clientType === 'Corporate') {
          setValueOfParagraph(`${itemId}#isc-percentage`, ((parseInt(updatedSharesValue) / totalNumOfShares) * 100).toFixed(2))
        }
        else {
          setValueOfParagraph(`${itemId}#isc-percentage`, '-');
        }
      }
      else {
        setValueOfParagraph(`${itemId}#isc-percentage`, '-');
      }
      
      return prevTableItems;
    });
  }, [updateInvestorTableItems, clientType, totalNumOfShares]);


  const isItemsValid = useCallback((tempTableItems) => {
    let isValid = true;
    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const investorName = getValueFromTextInput(`${itemId}#investor-name`);
        const numberOfShares = getValueFromTextInput(`${itemId}#number-of-shares`);
        const lastMeetingVotes = getValueFromTextInput(`${itemId}#last-meeting-votes`);

        let country = '';
        const countryDropwdownElement = document.getElementById(`${itemId}#country`);
        if(countryDropwdownElement.dataset.selected !== undefined) {
          country = getValueFromDropdown(`${itemId}#country`);
        }
        else {
          country = getValueFromParagraph(`${itemId}#country`);
        }

        if(document.getElementById(`${itemId}#investor-name`).dataset.value === '') {
          document.getElementById(`${itemId}#investor-name`).style.border = '1px solid var(--color-danger-700)';
        }
        if(countryDropwdownElement.dataset.selected !== undefined && countryDropwdownElement.dataset.selected === countryDropwdownElement.dataset.placeholder) {
          countryDropwdownElement.style.border = '1px solid var(--color-danger-700)';
        }
        if(numberOfShares === '') {
          document.getElementById(`${itemId}#number-of-shares`).style.border = '1px solid var(--color-danger-700)';
        }
        if(lastMeetingVotes === '') {
          document.getElementById(`${itemId}#last-meeting-votes`).style.border = '1px solid var(--color-danger-700)';
        }
        
        isValid = isValid && (investorName !== '' && country !== '' && numberOfShares !== '' && lastMeetingVotes !== '');
      });
    }
    return isValid;
  }, []);


  const fetchInvestorData = useCallback((tempTableItems) => {
    const data = [];

    if (tempTableItems['middle']['body']['items'] !== null) {
      tempTableItems['middle']['body']['items'].forEach(item => {
        const itemId = item['id'];
        const investorName = getValueFromTextInput(`${itemId}#investor-name`);
        
        let numberOfShares = 0;
        let lastMeetingVotes = '';
        if(clientType === 'Corporate') {
          numberOfShares = commaSeperatedValueToNumber(getValueFromTextInput(`${itemId}#number-of-shares`));
          lastMeetingVotes = getValueFromTextInput(`${itemId}#last-meeting-votes`);
        }
        
        const squareWellItemIndex = squareWellInvestors.findIndex(item => item['name'] === investorName);
        if (squareWellItemIndex > -1) {
          const country = getValueFromParagraph(`${itemId}#country`);
          data.push({
            'squarewell_investor_id': squareWellInvestors[squareWellItemIndex]['id'],
            'name': investorName,
            'country': country,
            'num_of_shares': numberOfShares,
            'last_general_meeting_votes': lastMeetingVotes,
            'is_custom': false,
          });
        }
        else {
          const country = getValueFromDropdown(`${itemId}#country`);
          data.push({
            'custom_investor_id': itemId.includes('client-custom-investor-existing-') ? itemId.split('client-custom-investor-existing-')[1] : undefined,
            'name': investorName,
            'country': country,
            'num_of_shares': numberOfShares,
            'last_general_meeting_votes': lastMeetingVotes,
            'is_custom': true
          });
        }
      });
    }

    return data;
  }, [clientType, squareWellInvestors]);


  const cancelEditHandler = () => {
    setTableItems(generateClientDetailsShowInvestorListTableItems(clientType, investorListData['items'], totalNumOfShares));
    setIsEditMode(false);
  }


  const showInvestorListSaveApprovalHandler = () => {
    setTableItems(prevTableItems => {
      const newTableItems = {...prevTableItems};
      newTableItems['middle']['body']['items'] = updateInvestorTableItems(newTableItems);
      return newTableItems;
    });
    setInvestorListSaveApproval(true);
  }


  const updateInvestorListHandler = (isActivated) => {
    if(!isItemsValid(tableItems)) {
      return;
    }

    if(isActivated) {
      const data = investorListData['items'];
      setInvestorListData(prevState => {
        prevState['is_activated'] = true;
        return prevState;
      });
      setIsEditMode(false);
      props.onSaveClick(data, true);
      setInvestorListActivateApproval(false);
    }
    else {
      const data = fetchInvestorData(tableItems);
      setTableItems(generateClientDetailsShowInvestorListTableItems(clientType, data, totalNumOfShares));
      setInvestorListData(prevState => {
        prevState['items'] = data;
        prevState['is_activated'] = false;
        return prevState;
      });
      setIsEditMode(false);
      props.onSaveClick(data, false);
      setInvestorListSaveApproval(false)
    }
  }


  const tableExportHandler = () => {
    const data = [];
    if (investorListData !== null && investorListData['items'] !== null) {
      investorListData['items'].forEach(item => {
        data.push({
          'INVESTOR NAME': item['name'],
          'COUNTRY': item['country'],
          '# OF SHARES': item['num_of_shares'],
          'ISC %': item['num_of_shares'] !== '' ? ((item['num_of_shares'] / totalNumOfShares) * 100).toFixed(2) : '-',
          'LAST GENERAL MEETING VOTES': item['last_general_meeting_votes']
        });
      });
    }

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Investor List");
    writeFileXLSX(wb, "InvestorListExport.xlsx");
  }


  useEffect(() => {
    let newTableItems = null;
    if(!isEditMode) {
      newTableItems = generateClientDetailsShowInvestorListTableItems(clientType, investorListData['items'], totalNumOfShares);
    }
    else {
      newTableItems = generateClientDetailsEditInvestorListTableItems(
        addNewInvestorRowHandler(createInvestorRow, selectInvestorComboboxItemHandler, iscCalculateHandler, removeInvestorRowHandler),
        () => setInvestorListDeleteAllApproval(true)
      );
      investorListData['items'].forEach(item => {
        newTableItems['middle']['body']['items'].push(
          createInvestorRow(item, selectInvestorComboboxItemHandler, iscCalculateHandler, removeInvestorRowHandler)
        );
      });

      const totalCalculatedNumOfShares = investorListData['items'].reduce((acc, curr) => acc + parseInt(curr['num_of_shares']), 0);
      newTableItems['middle']['header']['items'][0]['columns'][0]['text'] = `INVESTOR NAME (${investorListData['items'].length})`;
      newTableItems['middle']['header']['items'][0]['columns'][2]['text'] = `# OF SHARES (${numberToCommaSeperatedValue(totalCalculatedNumOfShares)})`;

      const selectedItems = newTableItems['middle']['body']['items'].map(item => item['columns'][0]['value']);
      newTableItems['middle']['body']['items'].forEach(item => {
        const updatedComboboxItems = searchInvestorItems.filter(comboboxItem => !selectedItems.includes(comboboxItem['text']) || comboboxItem['text'] === item['columns'][0]['value']);
        item['columns'][0]['items'] = updatedComboboxItems;
      });

      if (newTableItems['middle']['body']['items'].length >= numberOfInvestors) {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = true;
      }
      else {
        newTableItems['middle']['header']['buttons'][0]['isDisabled'] = false;
      }
    }
    setTableItems(newTableItems);
  }, [
    isEditMode, clientType, investorListData, searchInvestorItems, totalNumOfShares, numberOfInvestors,
    addNewInvestorRowHandler, createInvestorRow, selectInvestorComboboxItemHandler, iscCalculateHandler, removeInvestorRowHandler,
    removeAllInvestorsClickHandler
  ]);


  const propsInvestorListData = props.investorListData;
  const propsSquareWellInvestors = props.squareWellInvestors;
  useEffect(() => {
    setInvestorListData(propsInvestorListData);
    setSquareWellInvestors(propsSquareWellInvestors);
  }, [propsInvestorListData, propsSquareWellInvestors]);


  return (
    <div className={styles['client-details-investor-list']}>
      { investorListDeleteAllApproval &&
        <Modal
            type='critical'
            title='Delete ALL Investors?'
            message={`Are you sure you want to delete ALL investors from ${clientName}'s list?`}
            onCloseClick={() => setInvestorListDeleteAllApproval(false)}
            approveButtonType='delete'
            approveButton = {{
              'text': 'Delete ALL',
              'onClick': () => removeAllInvestorsClickHandler()
            }}
            cancelButton = {{
              'text': 'Cancel',
              'onClick': () => setInvestorListDeleteAllApproval(false)
            }}
          />
      }

      { investorListSaveApproval &&
        <Modal
            type='critical'
            title='Warning!'
            message={`You will lose previous Investor list information when you save the changes. Are you sure to proceed?`}
            onCloseClick={() => setInvestorListSaveApproval(false)}
            approveButton = {{
              'text': 'Proceed',
              'onClick': () => updateInvestorListHandler(false)
            }}
            cancelButton = {{
              'text': 'Cancel',
              'onClick': () => setInvestorListSaveApproval(false)
            }}
          />
      }

      { investorListActivateApproval &&
        <Modal
            type='info'
            title='Activate Investor List'
            message='Are you sure you have finished editing the Investor list? Once "activated", an automated e-mail will be sent to client users to confirm the investor list has been updated.'
            onCloseClick={() => setInvestorListActivateApproval(false)}
            approveButton = {{
              'text': 'Activate',
              'onClick': () => updateInvestorListHandler(true)
            }}
            cancelButton = {{
              'text': 'Cancel',
              'onClick': () => setInvestorListActivateApproval(false)
            }}
          />
      }

      { tableItems !== null &&
        <Fragment>
          <div className={styles['investor-list-table-top-container']}>
            <div className={styles['title']}>
              <h1>Investor List as of {new Date(investorListData['last_updated_at']).toLocaleDateString('en-GB')}</h1>
            </div>
            <div className={styles['action-buttons-container']}>
              {!isEditMode &&
                <div className={styles['right-buttons']}>
                  <Button type={"primary"} size={"base"} onClick={() => setIsEditMode(true)}>
                    Update Investor List
                  </Button>
                  <Button type={'secondary'} size={'base'} leftIcon={'download'} onClick={tableExportHandler}>
                    Download as Excel
                  </Button>
                </div>
              }
              { isEditMode &&
                <div className={styles['right-buttons']}>
                  <Button type={"cancel"} size={"base"} onClick={cancelEditHandler}>Cancel</Button>
                  <Button type={"primary"} size={"base"} onClick={showInvestorListSaveApprovalHandler}>Save</Button>
                </div>
              }
            </div>
          </div>

          <Table width={props.pageWidth} items={tableItems} />
        
          <div className={styles['investor-list-table-bottom-container']} style={{marginBottom: 192}}>
            <div className={styles['action-buttons-container']}>
              { !isEditMode && !investorListData['is_activated'] &&
                <div className={styles['right-buttons']}>
                  <Button type={"primary"} size={"base"} onClick={() => setInvestorListActivateApproval(true)}>
                    Activate the List
                  </Button>
                </div>
              }
            </div>
          </div>
        </Fragment>
      }
    </div>
  )
}

export default ClientDetailsInvestorList;

import { useEffect, useState } from 'react';
import { automaticRenewalDropdownItems, esgDataRatingsDropdownItems } from '../../../../utils/constants';
import useInputValidation from '../../../../hooks/use-input-validation';

import Button from '../../../../components/UI/Button/Button';
import DateInput from '../../../../components/UI/DateInput/DateInput';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import Modal from '../../../../components/UI/Modal/Modal';
import TextInput from '../../../../components/UI/TextInput/TextInput';

import styles from './ClientSetupSubscriptionDetails.module.css';

const ClientSetupSubscriptionDetails = (props) => {
  const [contractStartDate, setContractStartDate, isContractStartDateValid, hasContractStartDateError, contractStartDateChangeHandler, contractStartDateBlurHandler] =
    useInputValidation((props.subscriptionDetailsData['contract_start_date'] !== null && props.subscriptionDetailsData['contract_start_date'] !== undefined && props.subscriptionDetailsData['contract_start_date'] !== '') ?
      new Date(props.subscriptionDetailsData['contract_start_date']) :
      '', (contractStartDate) => true);
  
  const [contractEndDate, setContractEndDate, isContractEndDateValid, hasContractEndDateError, contractEndDateChangeHandler, contractEndDateBlurHandler] =
    useInputValidation((props.subscriptionDetailsData['contract_end_date'] !== null && props.subscriptionDetailsData['contract_end_date'] !== undefined && props.subscriptionDetailsData['contract_end_date'] !== '') ?
      new Date(props.subscriptionDetailsData['contract_end_date']) :
      '', (contractEndDate) => true);

  const [automaticRenewal, setAutomaticRenewal, isAutomaticRenewalValid, hasAutomaticRenewalError, automaticRenewalChangeHandler, automaticRenewalBlurHandler] =
    useInputValidation(props.subscriptionDetailsData['automatic_renewal'], (automaticRenewal) => true);
  
  const [numberOfInvestors, setNumberOfInvestors, isNumberOfInvestorsValid, hasNumberOfInvestorsError, numberOfInvestorsChangeHandler, numberOfInvestorsBlurHandler] =
    useInputValidation(props.subscriptionDetailsData['number_of_investors'], (numberOfInvestors) => numberOfInvestors.toString().trim() !== '');
  
  const [numberOfUsers, setNumberOfUsers, isNumberOfUsersValid, hasNumberOfUsersError, numberOfUsersChangeHandler, numberOfUsersBlurHandler] =
    useInputValidation(props.subscriptionDetailsData['number_of_users'], (numberOfUsers) => numberOfUsers.toString().trim() !== '');

  const [esgDataAndRatings, setEsgDataAndRatings, isEsgDataAndRatingsValid, hasEsgDataAndRatingsError, esgDataAndRatingsChangeHandler, esgDataAndRatingsBlurHandler] =
    useInputValidation(props.subscriptionDetailsData['esg_data_and_ratings'], (esgDataAndRatings) => true);


  const automaticRenewalCheckHandler = (selectedValue) => {
    automaticRenewalChangeHandler(selectedValue);
    if (selectedValue === 'Yes') {
      setContractEndDate('-');
    }
    else {
      setContractEndDate(
        (props.subscriptionDetailsData['contract_end_date'] !== null && props.subscriptionDetailsData['contract_end_date'] !== undefined && props.subscriptionDetailsData['contract_end_date'] !== '') ?
        new Date(props.subscriptionDetailsData['contract_end_date']) :
        ''
      );
    }
  }

  const isSubscriptionDetailsFormValid = () => {
    numberOfInvestorsBlurHandler();
    numberOfUsersBlurHandler();
    return isContractStartDateValid && isContractEndDateValid && isAutomaticRenewalValid && isNumberOfInvestorsValid && isNumberOfUsersValid && isEsgDataAndRatingsValid;
  }

  const [pagePreference, setPagePreference] = useState('next');

  const saveClientSubscriptionDetails = (clickedButton) => {
    const getContractEndDate = (automaticRenewal, contractEndDate) => {
      if (automaticRenewal === 'Yes' || contractEndDate === '') {
        return '';
      }
      else {
        return contractEndDate
      }
    }

    const updatedSubscriptionDetailsData = {
      'contract_start_date': contractStartDate,
      'contract_end_date': getContractEndDate(automaticRenewal, contractEndDate),
      'automatic_renewal': automaticRenewal,
      'number_of_investors': isNaN(parseInt(numberOfInvestors)) ? '' : parseInt(numberOfInvestors),
      'number_of_users': isNaN(parseInt(numberOfUsers)) ? '' : parseInt(numberOfUsers),
      'esg_data_and_ratings': esgDataAndRatings
    };
    if(clickedButton === 'next') {
      props.onNextClick(updatedSubscriptionDetailsData, 'next');
    }
    else if(clickedButton === 'prev') {
      props.onPrevClick(updatedSubscriptionDetailsData, 'prev');
    }
    else if(clickedButton === 'quit') {
      props.onSaveAndQuitClick(updatedSubscriptionDetailsData, 'quit');
    }
  }

  
  const saveWithValidationHandler = (clickedButton) => {
    setPagePreference(clickedButton);
    if(!isSubscriptionDetailsFormValid()) {
      setSubscriptionDetailsWarning(true);
    }
    else {
      saveClientSubscriptionDetails(clickedButton);
    }
  }


  const propsSubscriptionDetailsData = props.subscriptionDetailsData;
  useEffect(() => {
    setContractStartDate(
      (propsSubscriptionDetailsData['contract_start_date'] !== null && propsSubscriptionDetailsData['contract_start_date'] !== undefined && propsSubscriptionDetailsData['contract_start_date'] !== '') ?
        new Date(propsSubscriptionDetailsData['contract_start_date']) :
        ''
    );
    setContractEndDate(
      (propsSubscriptionDetailsData['contract_end_date'] !== null && propsSubscriptionDetailsData['contract_end_date'] !== undefined && propsSubscriptionDetailsData['contract_end_date'] !== '') ?
        new Date(propsSubscriptionDetailsData['contract_end_date']) :
        ''
    );
    setAutomaticRenewal(propsSubscriptionDetailsData['automatic_renewal']);
    setNumberOfInvestors(propsSubscriptionDetailsData['number_of_investors']);
    setNumberOfUsers(propsSubscriptionDetailsData['number_of_users']);
    setEsgDataAndRatings(propsSubscriptionDetailsData['esg_data_and_ratings']);
  }, [propsSubscriptionDetailsData, setContractStartDate, setContractEndDate, setAutomaticRenewal,
      setNumberOfInvestors, setNumberOfUsers, setEsgDataAndRatings
     ]);


  const [subscriptionDetailsWarning, setSubscriptionDetailsWarning] = useState(false);

  return (
    <div className={`${styles['client-setup-subscription-details-step']}`}>
      { subscriptionDetailsWarning && 
        <Modal
          type='warning'
          title='Missing Field Error'
          message='One or several fields are missing. Please review and fill in missing information to be able to complete Subscription Details set-up.'
          onCloseClick={() => setSubscriptionDetailsWarning(false)}
          approveButton = {{
            'text': 'Continue',
            'onClick': () => saveClientSubscriptionDetails(pagePreference)
          }}
          cancelButton = {{
            'text': 'Cancel',
            'onClick': () => setSubscriptionDetailsWarning(false)
          }}
        />
      }

      <div className={styles['subscription-details-form']}>
        <div className={styles['row']}>
          <DateInput
            id='subscription-details#contract-start-date'
            width='100%'
            label='Contract Start Date'
            selected={contractStartDate}
            hasError={hasContractStartDateError}
            onBlur={contractStartDateBlurHandler}
            onChange={contractStartDateChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='subscription-details#automatic-renewal'
            width='100%'
            label='Automatic Renewal'
            placeholder='Select an Automatic Renewal Option'
            items={automaticRenewalDropdownItems}
            selected={automaticRenewal}
            hasError={hasAutomaticRenewalError}
            onBlur={automaticRenewalBlurHandler}
            onItemClick={automaticRenewalCheckHandler}
          />
        </div>
        <div className={styles['row']}>
          { automaticRenewal === 'Yes' &&
            <TextInput
              id='subscription-details#contract-end-date'
              width='100%'
              label='Contract End Date'
              value='-'
              isDisabled={true}
            />
          }
          { automaticRenewal !== 'Yes' &&
            <DateInput
              id='subscription-details#contract-end-date'
              width='100%'
              label='Contract End Date'
              selected={contractEndDate}
              hasError={hasContractEndDateError}
              onBlur={contractEndDateBlurHandler}
              onChange={contractEndDateChangeHandler}
            />
          }
        </div>
        <div className={styles['row']}>
          <TextInput
            id='subscription-details#number-of-investors'
            width='100%'
            label='Number of Investors *'
            placeholder='Enter Number of Investors'
            value={numberOfInvestors}
            hasError={hasNumberOfInvestorsError}
            onBlur={numberOfInvestorsBlurHandler}
            onChange={numberOfInvestorsChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <TextInput
            id='subscription-details#number-of-users'
            width='100%'
            label='Number of Users *'
            placeholder='Enter Number of Users'
            value={numberOfUsers}
            hasError={hasNumberOfUsersError}
            onBlur={numberOfUsersBlurHandler}
            onChange={numberOfUsersChangeHandler}
          />
        </div>
        <div className={styles['row']}>
          <Dropdown
            id='subscription-details#esg-data-ratings'
            width='100%'
            // label='ESG Data & Ratings'
            // placeholder='Select ESG Data & Ratings'
            label='Advisory Support'
            placeholder='Select Advisory Support'
            items={esgDataRatingsDropdownItems}
            selected={esgDataAndRatings}
            hasError={hasEsgDataAndRatingsError}
            onBlur={esgDataAndRatingsBlurHandler}
            onItemClick={esgDataAndRatingsChangeHandler}
          />
        </div>
      </div>

      <div className={styles['action-buttons-container']} style={{'width': props.pageWidth}}>
        <div className={styles['left-buttons']}>
          <Button type={'cancel'} size={'base'} leftIcon={'arrow-left'} onClick={() => saveWithValidationHandler('prev')}>Previous</Button>
        </div>
        <div className={styles['right-buttons']}>
          <Button type={'cancel'} size={'base'} onClick={() => saveWithValidationHandler('quit')}>Save & Quit</Button>
          <Button type={'primary'} size={'base'} rightIcon={'arrow-right'} onClick={() => saveWithValidationHandler('next')}>Next</Button>
        </div>
      </div>
    </div>
  );
}

export default ClientSetupSubscriptionDetails;

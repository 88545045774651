import { Tooltip } from 'react-tooltip'
import parse from 'html-react-parser';

import TextInput from '../../../../../components/UI/TextInput/TextInput';
import Textarea from '../../../../../components/UI/Textarea/Textarea';

import styles from './SectionInfoBox.module.css';

const SectionInfoBox = (props) => {

  return (
    <div className={`${styles['col-item']} ${styles[props.size]}`}>
      { props.label &&
        <div className={styles['label']}>
          <h4>{props.label}</h4>
          { props.hasTooltip && 
            <div className={styles['tooltip-icon']} data-tooltip-id={`${props.id}-tooltip`}>
              <Tooltip
                id={`${props.id}-tooltip`}
                place='bottom'
                content={props.tooltipContent}
                className='aqtion-tooltip'
              />
            </div>
          }
        </div>
      }
      <div className={styles['value']}>
        { props.type === 'text' && 
          <div className={styles['text']}>
            <p>{props.value}</p>
          </div>
        }
        
        { props.type === 'text-input' && 
          <TextInput
            id={props.id}
            width='100%'
            size='base'
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onInputChange}
          />
        }
        
        { props.type === 'text-block' && 
          <div className={styles['text-block']}>
            <p>{props.value}</p>
          </div>
        }

        { props.type === 'url' && 
          <div className={styles['url']}>
            <p><a href={props.value} target='_blank' rel='noreferrer'>{props.value}</a></p>
          </div>
        }

        { props.type === 'text-block-input' && 
          <Textarea
            id={props.id}
            width='100%'
            height={props.height !== undefined ? props.height : '100%'}
            size='base'
            placeholder={props.placeholder}  
            onChange={props.onInputChange}
          >
            {props.value}
          </Textarea>
        }

        { props.type === 'rich-text-block' &&
          <div className={`${styles['rich-text-block']} ${styles[props.height]}`}>
            {parse(props.value)}
          </div>
        }
      </div>
    </div>
  );
}

export default SectionInfoBox;

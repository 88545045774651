import { createId, numberToCommaSeperatedValue } from "./helpers";

/* Account Manager - Client Setup - User Management Table */
export const generateClientSetupUserTableItems = (addNewUserRowHandler) => {
  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-user-list-add-button',
            'text': 'Add User',
            'onClick': addNewUserRowHandler,
            'leftIcon': 'plus'
          },
        ],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': 'NAME', 'stretch': true},
              {'text': 'SURNAME', 'stretch': true},
              {'text': 'EMAIL', 'stretch': true},
              {'text': 'DEPARTMENT', 'stretch': true},
              {'text': 'JOB TITLE', 'stretch': true},
              {'text': 'TYPE', 'stretch': true},
              {'text': ''}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            128, 128, 172, 172, 128, 128, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 8}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            128, 128, 172, 172, 128, 128, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 8}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  return tableItems;
}


/* Account Manager - Client Setup - Show Proxy Advisors Table */
export const generateClientSetupShowProxyAdvisorTableItems = (proxyAdvisorItems) => {
  const createShowProxyAdvisorRow = (proxyAdvisorName) => {
    const tempItemId = `client-proxy-advisor-${createId(4)}`;
    return {
      'id': tempItemId,
      'columns': [
        {
          'id': `${tempItemId}#proxy-advisor-name`,
          'type': 'default',
          'text': proxyAdvisorName,
          'stretch': false
        },
        {
          'type': 'empty-cell',
          'stretch': true
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': `PROXY ADVISOR / RATING NAME (${proxyAdvisorItems.length})`, 'stretch': false},
              {'text': '', 'stretch': true},
            ]
          }
        ],
        'config': {
          'columnWidths': [
            420, 0
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 0, 'right': 0, 'bottom': 0, 'left': 0}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            420, 0
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 0, 'right': 0, 'bottom': 0, 'left': 0}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  proxyAdvisorItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createShowProxyAdvisorRow(item['proxy_advisor_name'], item['last_general_meeting_recommendations'])
    );
  });

  return tableItems;
}


/* Account Manager - Client Setup - Edit Proxy Advisors Table */
export const generateClientSetupEditProxyAdvisorTableItems = (addNewProxyAdvisorRowHandler) => {
  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'proxy-advisor-list-add-button',
            'text': 'Add Proxy Advisor / Rating',
            'onClick': addNewProxyAdvisorRowHandler,
            'leftIcon': 'plus'
          }
        ],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': 'PROXY ADVISOR / RATING NAME', 'stretch': false},
              {'text': '', 'stretch': true},
              {'text': ''}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            420, 0, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 0, 'right': 0, 'bottom': 0, 'left': 0},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 8}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            420, 0, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 0, 'right': 0, 'bottom': 0, 'left': 0},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 8}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  return tableItems;
}


/* Account Manager - Client Setup - Show Investor List Table */
export const generateClientSetupShowInvestorListTableItems = (clientType, investorListItems, totalNumOfShares) => {
  const createInvestorRow = (item) => {
    const tempItemId = `client-investor-list-${createId(4)}`;
    const investorName = item['name'] !== null ? item['name'] : '';
    const country = item['country'] !== null ? item['country'] : '';
    
    let numberOfShares = '-';
    let iscPercentage = '-';
    let lastMeetingVotes = '-';
    if(clientType === 'Corporate') {
      numberOfShares = item['num_of_shares'] !== null ? parseInt(item['num_of_shares']) : '';
      iscPercentage = numberOfShares !== '' ? ((numberOfShares / totalNumOfShares) * 100).toFixed(2) : '-';
      lastMeetingVotes = item['last_general_meeting_votes'] !== null ? item['last_general_meeting_votes'] : '';
    }
    
    const isCustom = item['is_custom'];

    return {
      'id': tempItemId,
      'columns': [
        {
          'id': `${tempItemId}#investor-name`,
          'type': 'text-with-chip',
          'text': investorName,
          'has_custom_chip': isCustom,
          'stretch': true
        },
        {
          'id': `${tempItemId}#country`,
          'type': 'default',
          'text': country,
          'stretch': true
        },
        {
          'id': `${tempItemId}#number-of-shares`,
          'type': 'default',
          'text': numberToCommaSeperatedValue(numberOfShares),
          'stretch': true
        },
        {
          'id': `${tempItemId}#isc-percentage`,
          'type': 'default',
          'text': iscPercentage,
          'stretch': true
        },
        {
          'id': `${tempItemId}#last-meeting-votes`,
          'type': 'default',
          'text': lastMeetingVotes,
          'stretch': true
        },
        {
          'id': `${tempItemId}#filler`,
          'type': 'default',
          'text': '',
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    let totalCalculatedNumOfShares = '';
    if(clientType === 'Corporate') {
      totalCalculatedNumOfShares = investorListItems.reduce((acc, curr) => acc + parseInt(curr['num_of_shares']), 0);
    }
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': `INVESTOR NAME (${investorListItems.length})`, 'stretch': true},
              {'text': 'COUNTRY', 'stretch': true},
              {'text': (clientType === 'Corporate') ? 
                      `# OF SHARES (${numberToCommaSeperatedValue(totalCalculatedNumOfShares)})` : 
                      `# OF SHARES`, 'stretch': true},
              {'text': 'ISC %', 'stretch': true},
              {'text': 'LAST GENERAL MEETING VOTES', 'stretch': true},
              {'text': '', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            192, 168, 192, 48, 320, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 8}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            192, 168, 192, 48, 320, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 8}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  investorListItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createInvestorRow(item)
    );
  });

  return tableItems;
}


/* Account Manager - Client Setup - Edit Investor List Table */
export const generateClientSetupEditInvestorListTableItems = (addNewInvestorListRowHandler) => {
  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-investor-list-add-button',
            'text': 'Add Investor',
            'onClick': addNewInvestorListRowHandler,
            'leftIcon': 'plus'
          }
        ],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': 'INVESTOR NAME', 'stretch': true},
              {'text': 'COUNTRY', 'stretch': true},
              {'id': 'client-investor-list-num-of-shares', 'text': '# OF SHARES', 'stretch': true},
              {'text': 'ISC %', 'stretch': true},
              {'text': 'LAST GENERAL MEETING VOTES', 'stretch': true},
              {'text': ''}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            192, 168, 192, 48, 320, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 8}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            192, 168, 192, 48, 320, 20
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 8}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  return tableItems;
}

import { Fragment, useCallback, useEffect, useState } from "react";
import { generateClientShareholderCommunicationsTableItems } from "../../../../utils/client-engagement-helpers";
import { createClientEngagementsShareholderCommunicationRequest, listClientEngagementsShareholderCommunicationsRequest, updateClientEngagementsShareholderCommunicationRequest } from "../../../../requests/client-engagement-requests";
import { getAuthClientId } from "../../../../utils/auth";
import { useSearchParams } from "react-router-dom";
import { handleRequestError } from "../../../../utils/request-error-helpers";
import useHttp from "../../../../hooks/use-http";
import sortArray from 'sort-array';

import Button from "../../../../components/UI/Button/Button";
import Table from "../../../../components/UI/Table/Table";

import EngagementShareholderCommunicationCreateForm from "../../../../components/EngagementForm/EngagementShareholderCommunicationForm/EngagementShareholderCommunicationCreateForm";
import EngagementShareholderCommunicationDetailsForm from "../../../../components/EngagementForm/EngagementShareholderCommunicationForm/EngagementShareholderCommunicationDetailsForm";
import EngagementShareholderCommunicationUpdateForm from "../../../../components/EngagementForm/EngagementShareholderCommunicationForm/EngagementShareholderCommunicationUpdateForm";

import styles from '../EngagementListPage.module.css';

const EngagementsShareholderCommunications = (props) => {

  const clientId = getAuthClientId();
  const pageType = props.pageType;
  const setPageType = props.setPageType;
  const isViewOnly = props.isViewOnly;
  const isPrimaryEditor = props.isPrimaryEditor;
  
  const [isLoading, error, sendRequest] = useHttp();
  const [searchParams, setSearchParams] = useSearchParams();

  const [shareholderCommunicationsData, setShareholderCommunicationsData] = useState([]);
  const [shareholderCommunicationsTableItems, setShareholderCommunicationsTableItems] = useState(null);

  const [selectedShareholderCommunicationId, setSelectedShareholderCommunicationId] = useState(searchParams.get('shareholder_communication_id'));

  const [shareholderCommunicationsTableSortConfig, setShareholderCommunicationsTableSortConfig] = useState({
    'by': ['engagement_name'],
    'order': ['asc']
  });

  const shareholderCommunicationsSortHandler = (sortBy) => {
    const prepend = (value, array) => {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    setShareholderCommunicationsTableSortConfig(prevConfig => {
      const newConfig = {...prevConfig};
      const elementIndex = newConfig['by'].indexOf(sortBy);

      let orderBy = 'desc';
      if (elementIndex !== -1) {
        orderBy = newConfig['order'][elementIndex] === 'desc' ? 'asc' : 'desc';
        newConfig['by'].splice(elementIndex, 1);
        newConfig['order'].splice(elementIndex, 1);
      }
      newConfig['by'] = prepend(sortBy, newConfig['by']);
      newConfig['order'] = prepend(orderBy, newConfig['order']);
      
      return newConfig;
    });
  }

  useEffect(() => {
    setShareholderCommunicationsData(prevState => {
      const items = JSON.parse(JSON.stringify(prevState));
      return sortArray(items, shareholderCommunicationsTableSortConfig);
    });
  }, [shareholderCommunicationsTableSortConfig]);


  /* Show shareholder communication details. */
  const shareholderCommunicationClickHandler = useCallback((shareholderCommunicationId) => {
    setSearchParams({'shareholder_communication_id' : shareholderCommunicationId});
    setPageType('details');
    setSelectedShareholderCommunicationId(shareholderCommunicationId);
  }, [setPageType, setSearchParams]);

  
  /* Create new shareholder communication. */
  const clientShareholderCommunicationCreateHandler = (shareholderCommunicationData) => {
    const createClientEngagementsShareholderCommunicationResponse = (data) => {
      setShareholderCommunicationsData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        items.push(data)
        return items;
      });
      setPageType('list');
    }
    sendRequest(
      createClientEngagementsShareholderCommunicationRequest(clientId, shareholderCommunicationData),
      createClientEngagementsShareholderCommunicationResponse
    );
  }

  /* Update selected shareholder communication. */
  const clientShareholderCommunicationEditHandler = (shareholderCommunicationData) => {
    const updateClientEngagementsShareholderCommunicationResponse = (data) => {
      setShareholderCommunicationsData(prevState => {
        const items = JSON.parse(JSON.stringify(prevState));
        const updatedItemIndex = items.map(item => item['id']).indexOf(selectedShareholderCommunicationId);
        items[updatedItemIndex] = data;
        return items;
      });
      setPageType('details');
    }
    sendRequest(
      updateClientEngagementsShareholderCommunicationRequest(clientId, selectedShareholderCommunicationId, shareholderCommunicationData),
      updateClientEngagementsShareholderCommunicationResponse
    );
  }

  /* Return back to shareholder communications table. */
  const returnToShareholderCommunicationsClickHandler = () => {
    setSelectedShareholderCommunicationId(null);
    setPageType('list');
  }

  
  /* Send a request to fetch shareholder communication items and set the list with the response. */
  useEffect(() => {
    sendRequest(
      listClientEngagementsShareholderCommunicationsRequest(clientId),
      (data) => { 
        setShareholderCommunicationsData(data['items']);
      }
    );
  }, [sendRequest, clientId]);
  
  if(!isLoading && error !== undefined) {
    handleRequestError(error);
  }

  /* Generate shareholder communications table. */
  useEffect(() => {
    setShareholderCommunicationsTableItems(
      generateClientShareholderCommunicationsTableItems(
        shareholderCommunicationsData, shareholderCommunicationClickHandler, shareholderCommunicationsTableSortConfig, shareholderCommunicationsSortHandler
      )
    );
  }, [shareholderCommunicationsData, shareholderCommunicationsTableSortConfig, shareholderCommunicationClickHandler]);


  return (
    <Fragment>

      { !isLoading && pageType === 'list' && shareholderCommunicationsTableItems && shareholderCommunicationsTableItems['middle']['body']['items'].length > 0 &&
        <div className={styles['engagement-shareholder-communication-list-container']}>
          <Table width={isNaN(props.width) ? 1400 : (props.width - 2)} items={shareholderCommunicationsTableItems} isScrollable={false} />
        </div>
      }

      { !isLoading && pageType === 'list' && shareholderCommunicationsTableItems && shareholderCommunicationsTableItems['middle']['body']['items'].length === 0 &&
        <div className={styles['no-enagements-div']}>
          <div className={styles['icon-container']}>
            <div className={styles['icon']}></div>
          </div>
          {isPrimaryEditor &&
            <Fragment>
              <div className={styles['text-container']}>
                <h4>You Have No Shareholder Communications</h4>
                <p>Use below button to Create a Shareholder Communication</p>
              </div>
              <div className={styles['button-container']}>
                <Button
                  type={"primary"}
                  leftIcon={"plus"}
                  size={"xsmall"}
                  onClick={props.onCreateEngagementClick}>
                    Create Shareholder Communication
                </Button>
              </div>
            </Fragment>
          }
          {!isPrimaryEditor &&
            <div className={styles['text-container']}>
              <h4>You Have No Shareholder Communications</h4>
            </div>
          }
        </div>
      }

      { pageType === 'new' &&
        <EngagementShareholderCommunicationCreateForm
          hasFormTitle={true}
          onSaveClick={clientShareholderCommunicationCreateHandler}
          onCancel={returnToShareholderCommunicationsClickHandler}
        />
      }

      { pageType === 'edit' && selectedShareholderCommunicationId &&
        <EngagementShareholderCommunicationUpdateForm
          width={props.width}
          shareholderCommunicationId={selectedShareholderCommunicationId}
          onSaveClick={clientShareholderCommunicationEditHandler}
          onCancel={() => setPageType('details')}
        />
      }

      { pageType === 'details' && selectedShareholderCommunicationId &&
        <EngagementShareholderCommunicationDetailsForm
          width={props.width}
          shareholderCommunicationId={selectedShareholderCommunicationId}
          onEditEngagement={() => setPageType('edit')}
          setBreadcrumbItems={props.setBreadcrumbItems}
          isViewOnly={isViewOnly}
        />
      }
    </Fragment>
  );
}

export default EngagementsShareholderCommunications;

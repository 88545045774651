import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { getValueFromVariable } from '../../../../../utils/helpers';
import { investorESGMainServiceProvidersDropdownItems } from '../../../../../utils/constants';

import Button from '../../../../../components/UI/Button/Button';
import SectionInfoBox from '../SectionInfoBox/SectionInfoBox';

import summaryPageStyles from '../InvestorSummary.module.css';

const InvestorSummaryESGServiceProvidersSection = (props) => {
  const isViewOnly = props.isViewOnly;

  const isCustomInvestor = props.isCustomInvestor;
  const [esgServiceProvidersData, setESGServiceProvidersData] = useState(props.esgServiceProvidersData);
  const [isEditMode, setIsEditMode] = useState(false);

  const [mainProviders, setMainProviders] = useState(getValueFromVariable(esgServiceProvidersData['main_providers']));
  const [otherProviders, setOtherProviders] = useState(getValueFromVariable(esgServiceProvidersData['other_providers']));

  const cancelEditHandler = () => {
    setIsEditMode(false);
    setMainProviders(getValueFromVariable(esgServiceProvidersData['main_providers']));
    setOtherProviders(getValueFromVariable(esgServiceProvidersData['other_providers']));
  }

  const updateESGServiceProvidersHandler = () => {
    const updatedESGServiceProvidersData = {
      'esg_main_service_providers': mainProviders,
      'esg_other_service_providers': otherProviders
    };
    setESGServiceProvidersData(updatedESGServiceProvidersData);
    setIsEditMode(false);
    props.onSaveClick(updatedESGServiceProvidersData);
  }

  return (
    <div className={summaryPageStyles['section']}>
      <div className={summaryPageStyles['section-title']}>
        <h3>ESG Service Providers</h3>
        <div className={summaryPageStyles['tooltip-icon']} data-tooltip-id='esg-service-providers-title-tooltip'>
          <Tooltip
            id='esg-service-providers-title-tooltip'
            place='bottom'
            content='This shows which ESG Ratings and Research provider(s) this investor uses.'
            className='aqtion-tooltip'
          />
        </div>
      </div>
      { isCustomInvestor && !isEditMode && !isViewOnly &&
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='secondary' leftIcon='edit' onClick={() => setIsEditMode(true)}>Edit</Button>
        </div>
      }
      { isCustomInvestor && isEditMode && !isViewOnly &&
        <div className={summaryPageStyles['action-buttons']}>
          <Button size='xsmall' type='cancel' onClick={cancelEditHandler}>Cancel</Button>
          <Button size='xsmall' type='primary' onClick={updateESGServiceProvidersHandler}>Save</Button>
        </div>
      }

      <div className={`${summaryPageStyles['content']} ${isEditMode && summaryPageStyles['edit-mode']}`}>
        <SectionInfoBox
          id='investor#esg-main-service-providers'
          size='single-col'
          type={!isEditMode ? 'text' : 'dropdown'}
          label='Main Providers'
          dropdownItems={investorESGMainServiceProvidersDropdownItems}
          value={mainProviders}
          onInputChange={setMainProviders}
        />
        <SectionInfoBox
          id='investor#esg-other-service-providers'
          size='single-col'
          type={!isEditMode ? 'text' : 'text-input'}
          label='Other Providers'
          value={otherProviders}
          onInputChange={setOtherProviders}
        />
      </div>
      <div className={summaryPageStyles['separator']}></div>
    </div>
  );
}

export default InvestorSummaryESGServiceProvidersSection;

import { BASE_URI } from "./config";
import { getAccessToken } from "../utils/auth";


export const listSquareWellInvestorsRequest = () => {
  return {
    url: `${BASE_URI}/squarewell/investors`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

export const listSquareWellProxyAdvisorsRequest = () => {
  return {
    url: `${BASE_URI}/squarewell/proxy_advisors`,
    options: {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    }
  };
}

export const getSquareWellFileRequest = (body) => {
  return {
    url: `${BASE_URI}/squarewell/files`,
    options: {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json'
      },
      body: body,
      returnDataType: 'blob'
    }
  };
}
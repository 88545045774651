


/* Client Investor List Skeleton Table Items */
export const generateClientInvestorListSkeletonTableItems = (clientType, skeletonInvestorListItems) => {
  const createInvestorRowLeft = (item) => {
    const rowId = `client-skeleton-investor-${item}`;
    return {
      'id': rowId,
      'columns': [
        { 'id': `${rowId}#investor-name`, 'type': 'default', 'text': '', 'stretch': false}
      ]
    };
  }

  const createInvestorRowMiddle = (item) => {
    const rowId = `client-skeleton-investor-${item}`;
    return {
      'id': rowId,
      'columns': [
        { 'id': `${rowId}#filler-left`, 'type': 'empty-cell', 'stretch': false },
        { 'id': `${rowId}#country`, 'type': 'default', 'text': '', 'stretch': true }
      ].concat((clientType === 'Corporate') ? [
        { 'id': `${rowId}#number-of-shares`, 'type': 'default', 'text': '', 'stretch': true },
        { 'id': `${rowId}#isc-percentage`, 'type': 'default', 'text': '', 'stretch': true },
        { 'id': `${rowId}#previous-general-meeting-votes`, 'type': 'default', 'text': '', 'stretch': true }
      ] : []).concat([
        { 'id': `${rowId}#investor-type`, 'type': 'default', 'text': '', 'stretch': true },
        { 'id': `${rowId}#level-of-insights`, 'type': 'default', 'text': '', 'stretch': true },
        { 'id': `${rowId}#voting-decision-maker`, 'type': 'default', 'text': '', 'stretch': true },
        { 'id': `${rowId}#proxy-advisors`, 'type': 'default', 'text': '', 'stretch': true },
        { 'id': `${rowId}#proxy-advisors-reliance`, 'type': 'default', 'text': '', 'stretch': true },
        { 'id': `${rowId}#esg-focus`, 'type': 'default', 'text': '', 'stretch': true },
        { 'id': `${rowId}#esg-service-main-providers`, 'type': 'default', 'text': '', 'stretch': true },
        { 'id': `${rowId}#activist`, 'type': 'default', 'text': '', 'stretch': true },
        { 'id': `${rowId}#receptivity-to-activists`, 'type': 'default', 'text': '', 'stretch': true }
      ])
    };
  }

  const createTableLeftItems = () => {
    const leftItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'investor-list-header-left',
            'columns': [
              {
                'text': 'INVESTOR NAME',
                'isSortable': true,
                'onSortClick': () => {},
                'orderBy': 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [320],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [320],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 32}
          ]
        }
      }
    };
    return leftItems;
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'investor-list-header-middle',
            'columns': [
              {'text': '', 'stretch': false},
              {
                'text': 'COUNTRY',
                'isSortable': true,
                'onSortClick': () => {},
                'orderBy': 'asc',
                'stretch': true
              }
            ].concat((clientType === 'Corporate') ? [
              {
                'text': '# OF SHARES',
                'isSortable': true,
                'onSortClick': () => {},
                'orderBy': 'desc',
                'stretch': true
              },
              {'text': 'ISC %', 'stretch': true},
              {'text': 'LAST GENERAL MEETING VOTES', 'stretch': true}
            ] : []).concat([
              {'text': 'INVESTOR TYPE', 'stretch': true},
              {'text': 'LEVEL OF INSIGHT', 'stretch': true},
              {'text': 'VOTING DECISION MAKER', 'stretch': true},
              {'text': 'PROXY ADVISORS', 'stretch': true},
              {'text': 'PROXY ADVISORS RELIANCE', 'stretch': true},
              {'text': 'ESG FOCUS', 'stretch': true},
              {'text': 'ESG SERVICE PROVIDERS', 'stretch': true},
              {'text': 'ACTIVIST', 'stretch': true},
              {'text': 'RECEPTIVITY TO ACTIVISTS', 'stretch': true}
            ])
          }
        ],
        'config': {
          'columnWidths': 
            [320, 168]
              .concat((clientType === 'Corporate') ? [192, 48, 240] : [])
              .concat([168, 168, 192, 240, 192, 120, 240, 120, 192]),
          'columnPaddings': [
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 32},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ].concat((clientType === 'Corporate') ? [
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ] : []).concat([
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ])
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths':
            [320, 168]
              .concat((clientType === 'Corporate') ? [192, 48, 240] : [])
              .concat([168, 168, 192, 240, 192, 120, 240, 120, 192]),
          'columnPaddings': [
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 32},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ].concat((clientType === 'Corporate') ? [
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ] : []).concat([
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
              {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
            ])
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'left': createTableLeftItems(),
    'middle': createTableMiddleItems()
  };

  skeletonInvestorListItems.forEach(item => {
    tableItems['left']['body']['items'].push(
      createInvestorRowLeft(item)
    );
    tableItems['middle']['body']['items'].push(
      createInvestorRowMiddle(item)
    );
  });

  return tableItems;
}








/* Client Proxy Advisor List Skeleton Table Items */
export const generateClientProxyAdvisorListSkeletonTableItems = (clientType, skeletonProxyAdvisorListItems) => {
  const createProxyAdvisorRowMiddle = (item) => {
    const rowId = `client-skeleton-proxy-advisor-${item}`;
    return {
      'id': rowId,
      'columns': [
        { 'id': `${rowId}#proxy-advisor-name`, 'type': 'default', 'text': '', 'stretch': true }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'proxy-advisor-list-header',
            'columns': [
              {'text': `PROXY ADVISOR / RATING NAME`, 'stretch': true}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            360
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            360
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  skeletonProxyAdvisorListItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createProxyAdvisorRowMiddle(item)
    );
  });

  return tableItems;
}





export const generateDocuSearchResultSkeletonItems = () => {
  return Array.from({length: 5}, (_, i) => {
    return {
      'fileIdentifier': `skeleton-file-${i}`,
      'documentTitle': '',
      'investorName': '',
      'occurences': [],
      'publicationDate': '',
      'sourceTypeName': '',
      'sourceUrl': ''
    };
  });
}
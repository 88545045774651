import { jwtDecode } from "jwt-decode";
import { redirect } from 'react-router-dom';

export function deleteSession() {
  // Old local storage info
  localStorage.removeItem('sessionExpiryDate');
  localStorage.removeItem('token');

  localStorage.removeItem('accessToken');
  localStorage.removeItem('clientId');
  localStorage.removeItem('clientType');
  localStorage.removeItem('clientName');
  localStorage.removeItem('clientAvailableModules');
  localStorage.removeItem('userRole');
  localStorage.removeItem('userType');
  localStorage.removeItem('userName');
  localStorage.removeItem('userProfilePictureUrl');
  localStorage.removeItem('isViewMode');
  window.location.href = '/login';
}

export function getAccessToken() {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken;
}

export function getIsAccessTokenRenewalRequired() {
  const isAccessTokenRenewalRequired = localStorage.getItem('isAccessTokenRenewalRequired');
  return isAccessTokenRenewalRequired;
}

export function getAuthClientId() {
  const clientId = localStorage.getItem('clientId');
  return clientId;
}

export function getAuthClientName() {
  const clientName = localStorage.getItem('clientName');
  return clientName;
}

export function getAuthUserRole() {
  const userRole = localStorage.getItem('userRole');
  return userRole;
}

export function getAuthUserType() {
  const decodedToken = jwtDecode(getAccessToken());
  const userType = decodedToken['user_type'];
  return userType;
}

export function getAuthUserName() {
  const userName = localStorage.getItem('userName');
  return userName;
}

export function getAuthUserProfilePictureUrl() {
  const userProfilePictureUrl = localStorage.getItem('userProfilePictureUrl');
  return userProfilePictureUrl === 'null' ? '' : userProfilePictureUrl;
}

export function getClientType() {
  const clientType = localStorage.getItem('clientType');
  return clientType;
}

export function getClientAvailableModules() {
  const clientAvailableModules = JSON.parse(localStorage.getItem('clientAvailableModules'));
  return clientAvailableModules;
}

export function setLocalStorageClientData(client) {
  localStorage.setItem('clientId', client['id']);
  localStorage.setItem('clientName', client['name']);
  localStorage.setItem('clientType', client['client_type']);
  localStorage.setItem('clientAvailableModules', JSON.stringify(client['module_management']));
}

export function isViewMode() {
  const isViewMode = localStorage.getItem('isViewMode');
  return isViewMode === 'true';
}

export function checkAuthAccountManagerLoader() {
  const accessToken = getAccessToken();
  const userRole = getAuthUserRole();

  if (!accessToken || userRole === 'Client') {
    return redirect('/login');
  }
  return accessToken;
}

export function checkAuthClientLoader() {
  const accessToken = getAccessToken();
  const clientId = getAuthClientId();
  // const userRole = getAuthUserRole();

  // if ((!accessToken && !clientId) || userRole === 'Account Manager') {
  if ((!accessToken && !clientId)) {
    return redirect('/login');
  }
  return accessToken;
}

export function checkAuthClientDashboardLoader() {
  const accessToken = checkAuthClientLoader();
  const clientAvailableModules = getClientAvailableModules();
  if(!clientAvailableModules['dashboard']) {
    redirect('/investors');
  }
  return accessToken;
}

export function checkAuthClientEsgTopicsLoader() {
  const accessToken = checkAuthClientLoader();
  const clientAvailableModules = getClientAvailableModules();
  if(!clientAvailableModules['esg_topics']) {
    if(clientAvailableModules['dashboard']) {
      redirect('/dashboard');
    }
    else {
      redirect('/investors');
    }
  }
  return accessToken;
}

export function checkAuthClientEngagementsLoader() {
  const accessToken = checkAuthClientLoader();
  const clientAvailableModules = getClientAvailableModules();
  if(!clientAvailableModules['engagements']) {
    if(clientAvailableModules['dashboard']) {
      redirect('/dashboard');
    }
    else {
      redirect('/investors');
    }
  }
  return accessToken;
}

export function checkAuthClientDocuSearchLoader() {
  const accessToken = checkAuthClientLoader();
  const clientAvailableModules = getClientAvailableModules();
  if(!clientAvailableModules['docusearch']) {
    if(clientAvailableModules['dashboard']) {
      redirect('/dashboard');
    }
    else {
      redirect('/investors');
    }
  }
  return accessToken;
}

export function checkAuthClientQuickVoteLoader() {
  const accessToken = checkAuthClientLoader();
  const clientAvailableModules = getClientAvailableModules();
  if(!clientAvailableModules['quickvote']) {
    if(clientAvailableModules['dashboard']) {
      redirect('/dashboard');
    }
    else {
      redirect('/investors');
    }
  }
  return accessToken;
}

export function checkAuthClientReSearchLoader() {
  const accessToken = checkAuthClientLoader();
  const clientAvailableModules = getClientAvailableModules();
  if(!clientAvailableModules['research']) {
    if(clientAvailableModules['dashboard']) {
      redirect('/dashboard');
    }
    else {
      redirect('/investors');
    }
  }
  return accessToken;
}

export function checkIsAccountManagerViewMode() {
  const userRole = getAuthUserRole();
  const userType = getAuthUserType();

  if (userRole === 'Account Manager' && userType === 'Admin' && isViewMode()) {
    return true;
  }
  return false;
}

export function enableAccountManagerViewMode(clientId, clientName, clientType, clientAvailableModules) {
  localStorage.setItem('clientId', clientId);
  localStorage.setItem('clientName', clientName);
  localStorage.setItem('clientType', clientType);
  localStorage.setItem('clientAvailableModules', JSON.stringify(clientAvailableModules));
  localStorage.setItem('isViewMode', true);
}

export function disableAccountManagerViewMode() {
  localStorage.removeItem('clientId');
  localStorage.removeItem('clientName');
  localStorage.removeItem('clientType');
  localStorage.removeItem('clientAvailableModules');
  localStorage.removeItem('isViewMode');
}
import { meetingRequestEmailStatusDropdownItems, meetingRequestMeetingStatusDropdownItems, meetingRequestMeetingStatusDeactivatedDropdownItems, shareholderCommunicationEmailStatusDropdownItems } from "./constants";



export const generateNewMeetingRequestInvestorTableItems = (clientType, addInvestorClickHandler, removeAllInvestorsClickHandler) => {
  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-meeting-request-add-investor-button',
            'text': 'Add Investor',
            'onClick': addInvestorClickHandler,
            'leftIcon': 'plus'
          },
          {
            'id': 'client-meeting-request-remove-all-investors-button',
            'text': 'Delete ALL Investors',
            'onClick': removeAllInvestorsClickHandler,
            'type': 'icon-button',
            'icon': 'delete',
            'isVisible': false
          }
        ],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': 'INVESTOR NAME', 'stretch': false}
            ].concat((clientType === 'Corporate') ? [
              {'text': 'ISC', 'stretch': false}
            ] : []).concat([
              {'text': 'DELETE', 'stretch': false}
            ])
          }
        ],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            352, 52
          ] : [
            452
          ]).concat([
            52
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ])
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            352, 52
          ] : [
            452
          ]).concat([
            52
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ])
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  return tableItems;
}








export const generateNewMeetingRequestProxyAdvisorTableItems = (addProxyAdvisorClickHandler, removeAllProxyAdvisorsClickHandler) => {
  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-meeting-request-add-proxy-advisor-button',
            'text': 'Add Proxy Advisor',
            'onClick': addProxyAdvisorClickHandler,
            'leftIcon': 'plus'
          },
          {
            'id': 'client-meeting-request-remove-all-proxy-advisors-button',
            'text': 'Delete ALL Proxy Advisors',
            'onClick': removeAllProxyAdvisorsClickHandler,
            'type': 'icon-button',
            'icon': 'delete',
            'isVisible': false
          }
        ],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': 'PROXY ADVISOR NAME', 'stretch': false},
              {'text': 'DELETE', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            352, 52
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            352, 52
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  return tableItems;
}













export const generateNewShareHolderCommunicationInvestorTableItems = (clientType, addInvestorClickHandler, removeAllInvestorsClickHandler) => {
  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-shareholder-communication-add-investor-button',
            'text': 'Add Investor',
            'onClick': addInvestorClickHandler,
            'leftIcon': 'plus'
          },
          {
            'id': 'client-shareholder-communication-remove-all-investors-button',
            'text': 'Delete ALL Investors',
            'onClick': removeAllInvestorsClickHandler,
            'type': 'icon-button',
            'icon': 'delete',
            'isVisible': false
          }
        ],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': 'INVESTOR NAME', 'stretch': false}
            ].concat((clientType === 'Corporate') ? [
              {'text': 'ISC', 'stretch': false}
            ] : []).concat([
              {'text': 'DELETE', 'stretch': false}
            ])
          }
        ],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            352, 52
          ] : [
            452
          ]).concat([
            52
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ])
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            352, 52
          ] : [
            452
          ]).concat([
            52
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ])
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  return tableItems;
}


export const generateNewShareHolderCommunicationProxyAdvisorTableItems = (addProxyAdvisorClickHandler, removeAllProxyAdvisorsClickHandler) => {
  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-shareholder-communication-add-proxy-advisor-button',
            'text': 'Add Proxy Advisor',
            'onClick': addProxyAdvisorClickHandler,
            'leftIcon': 'plus'
          },
          {
            'id': 'client-shareholder-communication-remove-all-proxy-advisors-button',
            'text': 'Delete ALL Proxy Advisors',
            'onClick': removeAllProxyAdvisorsClickHandler,
            'type': 'icon-button',
            'icon': 'delete',
            'isVisible': false
          }
        ],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': 'PROXY ADVISOR NAME', 'stretch': false},
              {'text': 'DELETE', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            352, 52
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            352, 52
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  return tableItems;
}


















/* Client - Show Meeting Note List For Client Investors Table */
export const generateClientInvestorsMeetingNotesTableItems = (investorsMeetingNotesItems, meetingNoteClickHandler, investorClickHandler, sortConfig, sortFunction) => {
  const createInvestorsMeetingNoteRow = (item) => {
    const meetingNoteId = item['id'];
    const meetingNoteHeader = item['note_header'];
    const investorId = item['investor']['id'];
    const investorName = item['investor']['name'];
    const investorIsCustom = item['investor']['is_custom'];
    const meetingDate = new Date(item['meeting_date']).toLocaleDateString('en-GB');
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];

    const rowId = `client-engagements-meeting-note-${meetingNoteId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#note-header`,
          'type': 'link-button',
          'text': meetingNoteHeader,
          'stretch': true,
          'onClick': () => meetingNoteClickHandler(meetingNoteId, 'type-investor')
        },
        {
          'id': `${rowId}#investor-name`,
          'type': 'link-button',
          'text': investorName,
          'has_custom_chip': investorIsCustom,
          'stretch': true,
          'onClick': () => investorClickHandler(investorId)
        },
        {
          'id': `${rowId}#meeting-date`,
          'type': 'default',
          'text': meetingDate,
          'stretch': false
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-engagements-meeting-notes-list-header-middle',
            'columns': [
              {
                'text': 'NOTE HEADER',
                'isSortable': true,
                'onSortClick': () => sortFunction('note_header'),
                'orderBy': sortConfig['by'].includes('note_header') ? sortConfig['order'][sortConfig['by'].indexOf('note_header')] : 'asc',
                'stretch': true
              },
              {
                'text': 'INVESTOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('investor_name'),
                'orderBy': sortConfig['by'].includes('investor_name') ? sortConfig['order'][sortConfig['by'].indexOf('investor_name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'MEETING DATE',
                'isSortable': true,
                'onSortClick': () => sortFunction('meeting_date'),
                'orderBy': sortConfig['by'].includes('meeting_date') ? sortConfig['order'][sortConfig['by'].indexOf('meeting_date')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            200, 200, 128, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            200, 200, 128, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  investorsMeetingNotesItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createInvestorsMeetingNoteRow(item)
    );
  });

  return tableItems;
}



/* Client - Show Meeting Note List For Client Proxy Advisors Table */
export const generateClientProxyAdvisorsMeetingNotesTableItems = (proxyAdvisorsMeetingNotesItems, meetingNoteClickHandler, proxyAdvisorClickHandler, sortConfig, sortFunction) => {
  const createProxyAdvisorsMeetingNoteRow = (item) => {
    const meetingNoteId = item['id'];
    const meetingNoteHeader = item['note_header'];
    const proxyAdvisorId = item['proxy_advisor']['id'];
    const proxyAdvisorName = item['proxy_advisor']['name'];
    const meetingDate = new Date(item['meeting_date']).toLocaleDateString('en-GB');
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];

    const rowId = `client-engagements-meeting-note-${meetingNoteId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#note-header`,
          'type': 'link-button',
          'text': meetingNoteHeader,
          'stretch': true,
          'onClick': () => meetingNoteClickHandler(meetingNoteId, 'type-proxy-advisor')
        },
        {
          'id': `${rowId}#proxy-advisor-name`,
          'type': 'link-button',
          'text': proxyAdvisorName,
          'stretch': true,
          'onClick': () => proxyAdvisorClickHandler(proxyAdvisorId)
        },
        {
          'id': `${rowId}#meeting-date`,
          'type': 'default',
          'text': meetingDate,
          'stretch': false
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-investor-engagements-meeting-notes-list-header-middle',
            'columns': [
              {
                'text': 'NOTE HEADER',
                'isSortable': true,
                'onSortClick': () => sortFunction('note_header'),
                'orderBy': sortConfig['by'].includes('note_header') ? sortConfig['order'][sortConfig['by'].indexOf('note_header')] : 'asc',
                'stretch': true
              },
              {
                'text': 'PROXY ADVISOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('proxy_advisor_name'),
                'orderBy': sortConfig['by'].includes('proxy_advisor_name') ? sortConfig['order'][sortConfig['by'].indexOf('proxy_advisor_name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'MEETING DATE',
                'isSortable': true,
                'onSortClick': () => sortFunction('meeting_date'),
                'orderBy': sortConfig['by'].includes('meeting_date') ? sortConfig['order'][sortConfig['by'].indexOf('meeting_date')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            200, 200, 128, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            200, 200, 128, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  proxyAdvisorsMeetingNotesItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createProxyAdvisorsMeetingNoteRow(item)
    );
  });

  return tableItems;
}































































/* Client - Show Other Note List For Client Investors Table */
export const generateClientInvestorsOtherNotesTableItems = (investorsOtherNotesItems, otherNoteClickHandler, investorClickHandler, sortConfig, sortFunction) => {
  const createInvestorsOtherNoteRow = (item) => {
    const otherNoteId = item['id'];
    const otherNoteHeader = item['note_header'];
    const investorId = item['investor']['id'];
    const investorName = item['investor']['name'];
    const investorIsCustom = item['investor']['is_custom'];
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];

    const rowId = `client-engagements-other-note-${otherNoteId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#note-header`,
          'type': 'link-button',
          'text': otherNoteHeader,
          'stretch': true,
          'onClick': () => otherNoteClickHandler(otherNoteId, 'type-investor')
        },
        {
          'id': `${rowId}#investor-name`,
          'type': 'link-button',
          'text': investorName,
          'has_custom_chip': investorIsCustom,
          'stretch': true,
          'onClick': () => investorClickHandler(investorId)
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-engagements-other-notes-list-header-middle',
            'columns': [
              {
                'text': 'NOTE HEADER',
                'isSortable': true,
                'onSortClick': () => sortFunction('note_header'),
                'orderBy': sortConfig['by'].includes('note_header') ? sortConfig['order'][sortConfig['by'].indexOf('note_header')] : 'asc',
                'stretch': true
              },
              {
                'text': 'INVESTOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('investor_name'),
                'orderBy': sortConfig['by'].includes('investor_name') ? sortConfig['order'][sortConfig['by'].indexOf('investor_name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            200, 200, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            200, 200, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  investorsOtherNotesItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createInvestorsOtherNoteRow(item)
    );
  });

  return tableItems;
}


/* Client - Show Other Note List For Client Proxy Advisors Table */
export const generateClientProxyAdvisorsOtherNotesTableItems = (proxyAdvisorsOtherNotesItems, otherNoteClickHandler, proxyAdvisorClickHandler, sortConfig, sortFunction) => {
  const createProxyAdvisorsOtherNoteRow = (item) => {
    const otherNoteId = item['id'];
    const otherNoteHeader = item['note_header'];
    const proxyAdvisorId = item['proxy_advisor']['id'];
    const proxyAdvisorName = item['proxy_advisor']['name'];
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];

    const rowId = `client-engagements-other-note-${otherNoteId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#note-header`,
          'type': 'link-button',
          'text': otherNoteHeader,
          'stretch': true,
          'onClick': () => otherNoteClickHandler(otherNoteId, 'type-proxy-advisor')
        },
        {
          'id': `${rowId}#proxy-advisor-name`,
          'type': 'link-button',
          'text': proxyAdvisorName,
          'stretch': true,
          'onClick': () => proxyAdvisorClickHandler(proxyAdvisorId)
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-investor-engagements-other-notes-list-header-middle',
            'columns': [
              {
                'text': 'NOTE HEADER',
                'isSortable': true,
                'onSortClick': () => sortFunction('note_header'),
                'orderBy': sortConfig['by'].includes('note_header') ? sortConfig['order'][sortConfig['by'].indexOf('note_header')] : 'asc',
                'stretch': true
              },
              {
                'text': 'PROXY ADVISOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('proxy_advisor_name'),
                'orderBy': sortConfig['by'].includes('proxy_advisor_name') ? sortConfig['order'][sortConfig['by'].indexOf('proxy_advisor_name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            200, 200, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            200, 200, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  proxyAdvisorsOtherNotesItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createProxyAdvisorsOtherNoteRow(item)
    );
  });

  return tableItems;
}






/* Client - Show Meeting Request List For the Client */
export const generateClientMeetingRequestsTableItems = (meetingRequestItems, meetingRequestClickHandler, sortConfig, sortFunction) => {
  const createMeetingRequestRow = (item) => {
    const meetingRequestId = item['id'];
    const engagementName = item['engagement_name'];
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];

    const rowId = `client-engagements-meeting-request-${meetingRequestId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#engagement-name`,
          'type': 'link-button',
          'text': engagementName,
          'stretch': true,
          'onClick': () => meetingRequestClickHandler(meetingRequestId)
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-engagements-meeting-request-list-header-middle',
            'columns': [
              {
                'text': 'ENGAGEMENT NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('engagement_name'),
                'orderBy': sortConfig['by'].includes('engagement_name') ? sortConfig['order'][sortConfig['by'].indexOf('engagement_name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            200, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            200, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  meetingRequestItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createMeetingRequestRow(item)
    );
  });

  return tableItems;
}









/* Client - Show Meeting Request Investor List */
export const generateClientMeetingRequestInvestorsTableItems = (
  clientType, meetingRequestInvestorItems, investorClickHandler, investorCustomizeClickHandler,
  emailStatusChangeHandler, meetingStatusChangeHandler, sortConfig, sortFunction, isViewOnly
) => {
  const createMeetingRequestInvestorRowLeft = (item) => {
    const investorId = item['client_investor_id'];
    const investorIsCustom = item['is_custom'];
    const investorName = item['name'];
    const rowId = `client-engagements-meeting-request-investor-${investorId}`;

    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#investor-name`,
          'type': 'link-button',
          'text': investorName,
          'has_custom_chip': investorIsCustom,
          'stretch': false,
          'onClick': () => investorClickHandler(investorId)
        }
      ]
    };
  }

  const createMeetingRequestInvestorRowRight = (item) => {
    const investorId = item['client_investor_id'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');
    
    let meetingStatus = '';
    let meetingStatusDropdownItems = [];
    let meetingStatusIsDisabled = true;
    let meetingStatusHasChip = false;
    if(emailStatus === 'Not Sent') {
      meetingStatus = '-';
      meetingStatusDropdownItems = meetingRequestMeetingStatusDeactivatedDropdownItems;
      meetingStatusIsDisabled = true;
      meetingStatusHasChip = false;
    }
    else {
      meetingStatus = item['meeting_status'] === null ? 'Pending' : item['meeting_status'];
      meetingStatusDropdownItems = meetingRequestMeetingStatusDropdownItems;
      meetingStatusIsDisabled = false;
      meetingStatusHasChip = true;
    }

    const rowId = `client-engagements-meeting-request-investor-${investorId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#email-status`,
          'type': 'dropdown',
          'selected': emailStatus,
          'value': emailStatus,
          'text': emailStatus,
          'items': meetingRequestEmailStatusDropdownItems,
          'width': 144,
          'hasChip':true,
          'hasHeaderColor':true,
          'onSelected': (itemId, selectedValue) => emailStatusChangeHandler(itemId, selectedValue),
          'isReadOnly': isViewOnly,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': true
        },
        {
          'id': `${rowId}#meeting-status`,
          'type': 'dropdown',
          'selected': meetingStatus,
          'value': meetingStatus,
          'text': meetingStatus,
          'items': meetingStatusDropdownItems,
          'width': 144,
          'hasChip':meetingStatusHasChip,
          'hasHeaderColor':true,
          'onSelected': (itemId, selectedValue) => meetingStatusChangeHandler(itemId, selectedValue),
          'isDisabled': meetingStatusIsDisabled,
          'isReadOnly': isViewOnly,
          'stretch': false
        },
        { 
          'id': `${rowId}#customize-button`,
          'type': 'icon-button',
          'icon': 'edit',
          'stretch': false,
          'onClick': () => investorCustomizeClickHandler(investorId)
        }
      ]
    };
  }

  const createMeetingRequestInvestorRowMiddle = (item) => {
    const investorId = item['client_investor_id'];
    const investorIsc = item['isc'].toFixed(2);
    const updatedBy = item['updated_by'] === null ? '-' : item['updated_by'];
    const updatedAt = item['updated_at'] === null ? '-' : new Date(item['updated_at']).toLocaleDateString('en-GB');
    
    const rowId = `client-engagements-meeting-request-investor-${investorId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#filler-left`,
          'type': 'empty-cell',
          'stretch': false
        }
      ].concat((clientType === 'Corporate') ? [
        {
          'id': `${rowId}#investor-isc`,
          'type': 'default',
          'text': investorIsc,
          'stretch': false
        }
      ] : []).concat([
        {
          'id': `${rowId}#customized-by`,
          'type': 'default',
          'text': updatedBy,
          'stretch': true
        },
        {
          'id': `${rowId}#customized-at`,
          'type': 'default',
          'text': updatedAt,
          'stretch': true
        },
        {
          'id': `${rowId}#filler-right`,
          'type': 'empty-cell',
          'stretch': false
        }
      ])
    };
  }

  const createTableLeftItems = () => {
    const leftItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-engagements-meeting-request-investor-list-header-left',
            'columns': [
              {
                'text': 'INVESTOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('name'),
                'orderBy': sortConfig['by'].includes('name') ? sortConfig['order'][sortConfig['by'].indexOf('name')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [232],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [232],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return leftItems;
  }

  const createTableRightItems = () => {
    const rightItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-engagements-meeting-request-investor-list-header-right',
            'columns': [
              {
                'text': 'EMAIL STATUS',
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'stretch': true
              },
              {
                'text': 'MEETING STATUS',
                'stretch': false
              },
              {
                'text': 'CUSTOMIZE',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            144, 96, 144, 70
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            144, 96, 144, 70
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ]
        }
      }
    };
    return rightItems;
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': '', 'stretch': false}
            ].concat((clientType === 'Corporate') ? [
              {
                'text': 'ISC %',
                'isSortable': true,
                'onSortClick': () => sortFunction('isc'),
                'orderBy': sortConfig['by'].includes('isc') ? sortConfig['order'][sortConfig['by'].indexOf('isc')] : 'asc',
                'stretch': false
              }
            ] : []).concat([
              {
                'text': 'CUSTOMIZED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CUSTOMIZED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': true
              },
              {'text': '', 'stretch': false}
            ])
          }
        ],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            232, 48
          ] : [
            328
          ]).concat([
            120, 120, 598
          ]),
          'columnPaddings': [
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 20, 'right': 24, 'bottom': 20, 'left': 24}
          ])
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            232, 48
          ] : [
            328
          ]).concat([
            120, 120, 598
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ])
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'left': createTableLeftItems(),
    'middle': createTableMiddleItems(),
    'right': createTableRightItems()
  };

  meetingRequestInvestorItems.forEach(item => {
    tableItems['left']['body']['items'].push(
      createMeetingRequestInvestorRowLeft(item)
    );
    tableItems['right']['body']['items'].push(
      createMeetingRequestInvestorRowRight(item)
    );
    tableItems['middle']['body']['items'].push(
      createMeetingRequestInvestorRowMiddle(item)
    );
  });

  return tableItems;
}



/* Client - Show Meeting Request Proxy Advisor List */
export const generateClientMeetingRequestProxyAdvisorsTableItems = (
  meetingRequestProxyAdvisorItems, proxyAdvisorClickHandler, proxyAdvisorCustomizeClickHandler, 
  emailStatusChangeHandler, meetingStatusChangeHandler, sortConfig, sortFunction, isViewOnly
) => {
  const createMeetingRequestProxyAdvisorRowLeft = (item) => {
    const proxyAdvisorId = item['client_proxy_advisor_id'];
    const proxyAdvisorName = item['name'];
    const rowId = `client-engagements-meeting-request-proxy-advisor-${proxyAdvisorId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#proxy-advisor-name`,
          'type': 'link-button',
          'text': proxyAdvisorName,
          'stretch': false,
          'onClick': () => proxyAdvisorClickHandler(proxyAdvisorId)
        }
      ]
    };
  }

  const createMeetingRequestProxyAdvisorRowRight = (item) => {
    const proxyAdvisorId = item['client_proxy_advisor_id'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');
    
    let meetingStatus = '';
    let meetingStatusDropdownItems = [];
    let meetingStatusIsDisabled = true;
    let meetingStatusHasChip = false;
    if(emailStatus === 'Not Sent') {
      meetingStatus = '-';
      meetingStatusDropdownItems = meetingRequestMeetingStatusDeactivatedDropdownItems;
      meetingStatusIsDisabled = true;
      meetingStatusHasChip = false;
    }
    else {
      meetingStatus = item['meeting_status'] === null ? 'Pending' : item['meeting_status'];
      meetingStatusDropdownItems = meetingRequestMeetingStatusDropdownItems;
      meetingStatusIsDisabled = false;
      meetingStatusHasChip = true;
    }

    const rowId = `client-engagements-meeting-request-proxy-advisor-${proxyAdvisorId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#email-status`,
          'type': 'dropdown',
          'selected': emailStatus,
          'value': emailStatus,
          'text': emailStatus,
          'items': meetingRequestEmailStatusDropdownItems,
          'width': 144,
          'hasChip':true,
          'hasHeaderColor':true,
          'onSelected': (itemId, selectedValue) => emailStatusChangeHandler(itemId, selectedValue),
          'isReadOnly': isViewOnly,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': true
        },
        {
          'id': `${rowId}#meeting-status`,
          'type': 'dropdown',
          'selected': meetingStatus,
          'value': meetingStatus,
          'text': meetingStatus,
          'items': meetingStatusDropdownItems,
          'width': 144,
          'hasChip':meetingStatusHasChip,
          'hasHeaderColor':true,
          'onSelected': (itemId, selectedValue) => meetingStatusChangeHandler(itemId, selectedValue),
          'isDisabled': meetingStatusIsDisabled,
          'isReadOnly': isViewOnly,
          'stretch': false
        },
        { 
          'id': `${rowId}#customize-button`,
          'type': 'icon-button',
          'icon': 'edit',
          'stretch': false,
          'onClick': () => proxyAdvisorCustomizeClickHandler(proxyAdvisorId)
        }
      ]
    };
  }

  const createMeetingRequestProxyAdvisorRowMiddle = (item) => {
    const proxyAdvisorId = item['client_proxy_advisor_id'];
    const updatedBy = item['updated_by'] === null ? '-' : item['updated_by'];
    const updatedAt = item['updated_at'] === null ? '-' : new Date(item['updated_at']).toLocaleDateString('en-GB');
    const rowId = `client-engagements-meeting-request-proxy-advisor-${proxyAdvisorId}`;
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#filler-left`,
          'type': 'empty-cell',
          'stretch': false
        },
        {
          'id': `${rowId}#customized-by`,
          'type': 'default',
          'text': updatedBy,
          'stretch': true
        },
        {
          'id': `${rowId}#customized-at`,
          'type': 'default',
          'text': updatedAt,
          'stretch': true
        },
        {
          'id': `${rowId}#filler-right`,
          'type': 'empty-cell',
          'stretch': false
        }
      ]
    };
  }

  const createTableLeftItems = () => {
    const leftItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-engagements-meeting-request-proxy-advisor-list-header-left',
            'columns': [
              {
                'text': 'PROXY ADVISOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('name'),
                'orderBy': sortConfig['by'].includes('name') ? sortConfig['order'][sortConfig['by'].indexOf('name')] : 'asc',
                'stretch': true
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [328],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [328],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return leftItems;
  }

  const createTableRightItems = () => {
    const rightItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-engagements-meeting-request-proxy-advisor-list-header-right',
            'columns': [
              {
                'text': 'EMAIL STATUS',
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'stretch': true
              },
              {
                'text': 'MEETING STATUS',
                'stretch': false
              },
              {
                'text': 'CUSTOMIZE',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            144, 96, 144, 70
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ],
          'zIndex': 3
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            144, 96, 144, 70
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ]
        }
      }
    };
    return rightItems;
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client_header',
            'columns': [
              {'text': '', 'stretch': false},
              {
                'text': 'CUSTOMIZED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CUSTOMIZED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': true
              },
              {'text': '', 'stretch': false}
            ]
          }
        ],
        'config': {
          'columnWidths': [
            328, 120, 120, 598
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            328, 120, 120, 598
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'left': createTableLeftItems(),
    'middle': createTableMiddleItems(),
    'right': createTableRightItems()
  };

  meetingRequestProxyAdvisorItems.forEach(item => {
    tableItems['left']['body']['items'].push(
      createMeetingRequestProxyAdvisorRowLeft(item)
    );
    tableItems['right']['body']['items'].push(
      createMeetingRequestProxyAdvisorRowRight(item)
    );
    tableItems['middle']['body']['items'].push(
      createMeetingRequestProxyAdvisorRowMiddle(item)
    );
  });

  return tableItems;
}









/* Client - Update Meeting Request Investor List */
export const generateUpdatedMeetingRequestInvestorsTableItems = (
  clientType, meetingRequestInvestorItems, addInvestorClickHandler, 
  investorClickHandler, investorDeleteClickHandler, sortConfig, sortFunction
) => {
  const createMeetingRequestInvestorRow = (item) => {
    const investorId = item['client_investor_id'];
    const investorName = item['name'];
    const investorIsc = item['isc'].toFixed(2);
    const investorIsCustom = item['is_custom'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');
    
    let meetingStatus = '';
    if(emailStatus === 'Not Sent') {
      meetingStatus = '-';
    }
    else {
      meetingStatus = item['meeting_status'] === null ? 'Pending' : item['meeting_status'];
    }

    const updatedBy = item['updated_by'] === null ? '-' : item['updated_by'];
    const updatedAt = item['updated_at'] === null ? '-' : new Date(item['updated_at']).toLocaleDateString('en-GB');
    const rowId = `client-meeting-request-investor-${investorId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#investor-name`,
          'type': 'link-button',
          'text': investorName,
          'has_custom_chip': investorIsCustom,
          'stretch': true,
          'onClick': () => investorClickHandler(investorId)
        }
      ].concat((clientType === 'Corporate') ? [
        {
          'id': `${rowId}#investor-isc`,
          'type': 'default',
          'text': investorIsc,
          'stretch': false
        }
      ] : []).concat([
        {
          'id': `${rowId}#customized-by`,
          'type': 'default',
          'text': updatedBy,
          'stretch': false
        },
        {
          'id': `${rowId}#customized-at`,
          'type': 'default',
          'text': updatedAt,
          'stretch': false
        },
        {
          'id': `${rowId}#email-status`,
          'type': 'default',
          'text': emailStatus,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': false
        },
        {
          'id': `${rowId}#meeting-status`,
          'type': 'default',
          'text': meetingStatus,
          'stretch': false
        },
        { 
          'id': `${rowId}#delete-button`,
          'type': 'icon-button',
          'icon': 'trash',
          'onClick': () => investorDeleteClickHandler(investorId)
        }
      ])
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-meeting-request-add-investor-button',
            'text': 'Add Investor',
            'onClick': addInvestorClickHandler,
            'leftIcon': 'plus'
          }
        ],
        'items': [
          {
            'id': 'client-meeting-request-investor-list-header-middle',
            'columns': [
              {
                'text': 'INVESTOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('name'),
                'orderBy': sortConfig['by'].includes('name') ? sortConfig['order'][sortConfig['by'].indexOf('name')] : 'asc',
                'stretch': true
              }
            ].concat((clientType === 'Corporate') ? [
              {
                'text': 'ISC %',
                'isSortable': true,
                'onSortClick': () => sortFunction('isc'),
                'orderBy': sortConfig['by'].includes('isc') ? sortConfig['order'][sortConfig['by'].indexOf('isc')] : 'asc',
                'stretch': false
              }
            ] : []).concat([
              {
                'text': 'CUSTOMIZED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CUSTOMIZED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'EMAIL STATUS',
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'stretch': false
              },
              {
                'text': 'MEETING STATUS',
                'stretch': false
              },
              {
                'text': 'DELETE',
                'stretch': false
              }
            ])
          }
        ],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            192, 48
          ] : [
            192
          ]).concat([
            120, 120, 144, 112, 144, 52
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 0}
          ])
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            192, 48
          ] : [
            192
          ]).concat([
            120, 120, 144, 112, 144, 52
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 0}
          ])
        }
      }
    };
    return middleItems;
    
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  meetingRequestInvestorItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createMeetingRequestInvestorRow(item)
    );
  });

  return tableItems;
}


/* Client - Update Meeting Request Proxy Advisor List */
export const generateUpdatedMeetingRequestProxyAdvisorsTableItems = (
  meetingRequestProxyAdvisorItems, addProxyAdvisorClickHandler, 
  proxyAdvisorClickHandler, proxyAdvisorDeleteClickHandler, sortConfig, sortFunction
) => {
  const createMeetingRequestProxyAdvisorRow = (item) => {
    const proxyAdvisorId = item['client_proxy_advisor_id'];
    const proxyAdvisorName = item['name'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');
    
    let meetingStatus = '';
    if(emailStatus === 'Not Sent') {
      meetingStatus = '-';
    }
    else {
      meetingStatus = item['meeting_status'] === null ? 'Pending' : item['meeting_status'];
    }

    const updatedBy = item['updated_by'] === null ? '-' : item['updated_by'];
    const updatedAt = item['updated_at'] === null ? '-' : new Date(item['updated_at']).toLocaleDateString('en-GB');
    const rowId = `client-meeting-request-proxy-advisor-${proxyAdvisorId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#proxy-advisor-name`,
          'type': 'link-button',
          'text': proxyAdvisorName,
          'stretch': true,
          'onClick': () => proxyAdvisorClickHandler(proxyAdvisorId)
        },
        {
          'id': `${rowId}#customized-by`,
          'type': 'default',
          'text': updatedBy,
          'stretch': false
        },
        {
          'id': `${rowId}#customized-at`,
          'type': 'default',
          'text': updatedAt,
          'stretch': false
        },
        {
          'id': `${rowId}#email-status`,
          'type': 'default',
          'text': emailStatus,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': false
        },
        {
          'id': `${rowId}#meeting-status`,
          'type': 'default',
          'text': meetingStatus,
          'stretch': false
        },
        { 
          'id': `${rowId}#delete-button`,
          'type': 'icon-button',
          'icon': 'trash',
          'onClick': () => proxyAdvisorDeleteClickHandler(proxyAdvisorId)
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-meeting-request-add-proxy-advisor-button',
            'text': 'Add Proxy Advisor',
            'onClick': addProxyAdvisorClickHandler,
            'leftIcon': 'plus'
          }
        ],
        'items': [
          {
            'id': 'client-engagements-meeting-request-proxy-advisor-list-header-middle',
            'columns': [
              {
                'text': 'PROXY ADVISOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('name'),
                'orderBy': sortConfig['by'].includes('name') ? sortConfig['order'][sortConfig['by'].indexOf('name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CUSTOMIZED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CUSTOMIZED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'EMAIL STATUS',
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'stretch': false
              },
              {
                'text': 'MEETING STATUS',
                'stretch': false
              },
              {
                'text': 'DELETE',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            248, 120, 120, 144, 112, 144, 52
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 0}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            248, 120, 120, 144, 112, 144, 52
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 0}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  meetingRequestProxyAdvisorItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createMeetingRequestProxyAdvisorRow(item)
    );
  });

  return tableItems;
}
























/* Client - Show Shareholder Communications List For the Client */
export const generateClientShareholderCommunicationsTableItems = (shareholderCommunicationItems, shareholderCommunicationClickHandler, sortConfig, sortFunction) => {
  const createShareholderCommunicationRow = (item) => {
    const shareholderCommunicationId = item['id'];
    const engagementName = item['engagement_name'];
    const createdAt = new Date(item['created_at']).toLocaleDateString('en-GB');
    const createdBy = item['created_by'];

    const rowId = `client-engagements-shareholder-communication-${shareholderCommunicationId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#engagement-name`,
          'type': 'link-button',
          'text': engagementName,
          'stretch': true,
          'onClick': () => shareholderCommunicationClickHandler(shareholderCommunicationId)
        },
        {
          'id': `${rowId}#created-at`,
          'type': 'default',
          'text': createdBy,
          'stretch': false
        },
        {
          'id': `${rowId}#created-by`,
          'type': 'default',
          'text': createdAt,
          'stretch': false
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-engagements-shareholder-communication-list-header-middle',
            'columns': [
              {
                'text': 'ENGAGEMENT NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('engagement_name'),
                'orderBy': sortConfig['by'].includes('engagement_name') ? sortConfig['order'][sortConfig['by'].indexOf('engagement_name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CREATED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_by'),
                'orderBy': sortConfig['by'].includes('created_by') ? sortConfig['order'][sortConfig['by'].indexOf('created_by')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CREATED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction('created_at'),
                'orderBy': sortConfig['by'].includes('created_at') ? sortConfig['order'][sortConfig['by'].indexOf('created_at')] : 'asc',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            200, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            200, 160, 128
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  shareholderCommunicationItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createShareholderCommunicationRow(item)
    );
  });

  return tableItems;
}


/* Client - Show Shareholder Communication Investor List */
export const generateClientShareholderCommunicationInvestorsTableItems = (
  clientType, shareholderCommunicationInvestorItems, investorClickHandler, 
  investorCustomizeClickHandler, emailStatusChangeHandler, sortConfig, sortFunction, isViewOnly
) => {
  const createShareholderCommunicationInvestorRow = (item) => {
    const investorId = item['client_investor_id'];
    const investorName = item['name'];
    const investorIsc = item['isc'].toFixed(2);
    const investorIsCustom = item['is_custom'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const updatedBy = item['updated_by'] === null ? '-' : item['updated_by'];
    const updatedAt = item['updated_at'] === null ? '-' : new Date(item['updated_at']).toLocaleDateString('en-GB');
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');
    const rowId = `client-engagements-shareholder-communication-investor-${investorId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#investor-name`,
          'type': 'link-button',
          'text': investorName,
          'has_custom_chip': investorIsCustom,
          'stretch': true,
          'onClick': () => investorClickHandler(investorId)
        }
      ].concat((clientType === 'Corporate') ? [
        {
          'id': `${rowId}#investor-isc`,
          'type': 'default',
          'text': investorIsc,
          'stretch': false
        }
      ] : []).concat([
        {
          'id': `${rowId}#customized-by`,
          'type': 'default',
          'text': updatedBy,
          'stretch': false
        },
        {
          'id': `${rowId}#customized-at`,
          'type': 'default',
          'text': updatedAt,
          'stretch': false
        },
        {
          'id': `${rowId}#email-status`,
          'type': 'dropdown',
          'selected': emailStatus,
          'value': emailStatus,
          'text': emailStatus,
          'items': shareholderCommunicationEmailStatusDropdownItems,
          'width': 144,
          'hasChip':true,
          'hasHeaderColor':true,
          'onSelected': (itemId, selectedValue) => emailStatusChangeHandler(itemId, selectedValue),
          'isReadOnly': isViewOnly,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': false
        },
        { 
          'id': `${rowId}#customize-button`,
          'type': 'icon-button',
          'icon': 'edit',
          'stretch': false,
          'onClick': () => investorCustomizeClickHandler(investorId)
        }
      ])
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-engagements-shareholder-communication-investor-list-header-middle',
            'columns': [
              {
                'text': 'INVESTOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('name'),
                'orderBy': sortConfig['by'].includes('name') ? sortConfig['order'][sortConfig['by'].indexOf('name')] : 'asc',
                'stretch': true
              }
            ].concat((clientType === 'Corporate') ? [
              {
                'text': 'ISC %',
                'isSortable': true,
                'onSortClick': () => sortFunction('isc'),
                'orderBy': sortConfig['by'].includes('isc') ? sortConfig['order'][sortConfig['by'].indexOf('isc')] : 'asc',
                'stretch': false
              }
            ] : []).concat([
              {
                'text': 'CUSTOMIZED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CUSTOMIZED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'EMAIL STATUS',
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'stretch': false
              },
              {
                'text': 'CUSTOMIZE',
                'stretch': false
              }
            ])
          }
        ],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            200, 48
          ] : [
            296
          ]).concat([
            160, 128, 144, 128, 70
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ])
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            200, 48
          ] : [
            296
          ]).concat([
            160, 128, 144, 128, 70
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ])
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  shareholderCommunicationInvestorItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createShareholderCommunicationInvestorRow(item)
    );
  });

  return tableItems;
}


/* Client - Show Shareholder Communication Proxy Advisor List */
export const generateClientShareholderCommunicationProxyAdvisorsTableItems = (
  shareholderCommunicationProxyAdvisorItems, proxyAdvisorClickHandler, 
  proxyAdvisorCustomizeClickHandler, emailStatusChangeHandler, sortConfig, sortFunction, isViewOnly
) => {
  const createShareholderCommunicationProxyAdvisorRow = (item) => {
    const proxyAdvisorId = item['client_proxy_advisor_id'];
    const proxyAdvisorName = item['name'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const updatedBy = item['updated_by'] === null ? '-' : item['updated_by'];
    const updatedAt = item['updated_at'] === null ? '-' : new Date(item['updated_at']).toLocaleDateString('en-GB');
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');
    const rowId = `client-engagements-shareholder-communication-proxy-advisor-${proxyAdvisorId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#proxy-advisor-name`,
          'type': 'link-button',
          'text': proxyAdvisorName,
          'stretch': true,
          'onClick': () => proxyAdvisorClickHandler(proxyAdvisorId)
        },
        {
          'id': `${rowId}#customized-by`,
          'type': 'default',
          'text': updatedBy,
          'stretch': false
        },
        {
          'id': `${rowId}#customized-at`,
          'type': 'default',
          'text': updatedAt,
          'stretch': false
        },
        {
          'id': `${rowId}#email-status`,
          'type': 'dropdown',
          'selected': emailStatus,
          'value': emailStatus,
          'text': emailStatus,
          'items': shareholderCommunicationEmailStatusDropdownItems,
          'width': 144,
          'hasChip':true,
          'hasHeaderColor':true,
          'onSelected': (itemId, selectedValue) => emailStatusChangeHandler(itemId, selectedValue),
          'isReadOnly': isViewOnly,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': false
        },
        { 
          'id': `${rowId}#customize-button`,
          'type': 'icon-button',
          'icon': 'edit',
          'stretch': false,
          'onClick': () => proxyAdvisorCustomizeClickHandler(proxyAdvisorId)
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [],
        'items': [
          {
            'id': 'client-engagements-shareholder-communication-proxy-advisor-list-header-middle',
            'columns': [
              {
                'text': 'PROXY ADVISOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('name'),
                'orderBy': sortConfig['by'].includes('name') ? sortConfig['order'][sortConfig['by'].indexOf('name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CUSTOMIZED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CUSTOMIZED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'EMAIL STATUS',
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'stretch': false
              },
              {
                'text': 'CUSTOMIZE',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            248, 160, 128, 144, 128, 70
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            248, 160, 128, 144, 128, 70
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 24}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  shareholderCommunicationProxyAdvisorItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createShareholderCommunicationProxyAdvisorRow(item)
    );
  });

  return tableItems;
}












/* Client - Update Shareholder Communication Investor List */
export const generateUpdatedShareholderCommunicationInvestorsTableItems = (
  clientType, shareholderCommunicationInvestorItems, addInvestorClickHandler, 
  investorClickHandler, investorDeleteClickHandler, sortConfig, sortFunction
) => {
  const createShareholderCommunicationInvestorRow = (item) => {
    const investorId = item['client_investor_id'];
    const investorName = item['name'];
    const investorIsc = item['isc'].toFixed(2);
    const investorIsCustom = item['is_custom'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');
    const updatedBy = item['updated_by'] === null ? '-' : item['updated_by'];
    const updatedAt = item['updated_at'] === null ? '-' : new Date(item['updated_at']).toLocaleDateString('en-GB');
    const rowId = `client-shareholder-communication-investor-${investorId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#investor-name`,
          'type': 'link-button',
          'text': investorName,
          'has_custom_chip': investorIsCustom,
          'stretch': true,
          'onClick': () => investorClickHandler(investorId)
        }
      ].concat((clientType === 'Corporate') ? [
        {
          'id': `${rowId}#investor-isc`,
          'type': 'default',
          'text': investorIsc,
          'stretch': false
        }
      ] : []).concat([
        {
          'id': `${rowId}#customized-by`,
          'type': 'default',
          'text': updatedBy,
          'stretch': false
        },
        {
          'id': `${rowId}#customized-at`,
          'type': 'default',
          'text': updatedAt,
          'stretch': false
        },
        {
          'id': `${rowId}#email-status`,
          'type': 'default',
          'text': emailStatus,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': false
        },
        { 
          'id': `${rowId}#delete-button`,
          'type': 'icon-button',
          'icon': 'trash',
          'onClick': () => investorDeleteClickHandler(investorId)
        }
      ])
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-shareholder-communication-add-investor-button',
            'text': 'Add Investor',
            'onClick': addInvestorClickHandler,
            'leftIcon': 'plus'
          }
        ],
        'items': [
          {
            'id': 'client-shareholder-communication-investor-list-header-middle',
            'columns': [
              {
                'text': 'INVESTOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('name'),
                'orderBy': sortConfig['by'].includes('name') ? sortConfig['order'][sortConfig['by'].indexOf('name')] : 'asc',
                'stretch': true
              }
            ].concat((clientType === 'Corporate') ? [
              {
                'text': 'ISC %',
                'isSortable': true,
                'onSortClick': () => sortFunction('isc'),
                'orderBy': sortConfig['by'].includes('isc') ? sortConfig['order'][sortConfig['by'].indexOf('isc')] : 'asc',
                'stretch': false
              }
            ] : []).concat([
              {
                'text': 'CUSTOMIZED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CUSTOMIZED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'EMAIL STATUS',
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'stretch': false
              },
              {
                'text': 'DELETE',
                'stretch': false
              }
            ])
          }
        ],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            192, 48
          ] : [
            192
          ]).concat([
            120, 120, 144, 112, 52
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 0}
          ])
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': ((clientType === 'Corporate') ? [
            192, 48
          ] : [
            192
          ]).concat([
            120, 120, 144, 112, 52
          ]),
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ].concat((clientType === 'Corporate') ? [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24}
          ] : []).concat([
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 0}
          ])
        }
      }
    };
    return middleItems;
    
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  shareholderCommunicationInvestorItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createShareholderCommunicationInvestorRow(item)
    );
  });

  return tableItems;
}


/* Client - Update Shareholder Communication Proxy Advisor List */
export const generateUpdatedShareholderCommunicationProxyAdvisorsTableItems = (
  shareholderCommunicationProxyAdvisorItems, addProxyAdvisorClickHandler, 
  proxyAdvisorClickHandler, proxyAdvisorDeleteClickHandler, sortConfig, sortFunction
) => {
  const createShareholderCommunicationProxyAdvisorRow = (item) => {
    const proxyAdvisorId = item['client_proxy_advisor_id'];
    const proxyAdvisorName = item['name'];
    const emailStatus = item['email_status'] === null ? 'Not Sent' : item['email_status'];
    const emailSentDate = item['email_sent_date'] === null ? '-' : new Date(item['email_sent_date']).toLocaleDateString('en-GB');
    const updatedBy = item['updated_by'] === null ? '-' : item['updated_by'];
    const updatedAt = item['updated_at'] === null ? '-' : new Date(item['updated_at']).toLocaleDateString('en-GB');
    const rowId = `client-shareholder-communication-proxy-advisor-${proxyAdvisorId}`;
    
    return {
      'id': rowId,
      'columns': [
        {
          'id': `${rowId}#proxy-advisor-name`,
          'type': 'link-button',
          'text': proxyAdvisorName,
          'stretch': true,
          'onClick': () => proxyAdvisorClickHandler(proxyAdvisorId)
        },
        {
          'id': `${rowId}#customized-by`,
          'type': 'default',
          'text': updatedBy,
          'stretch': false
        },
        {
          'id': `${rowId}#customized-at`,
          'type': 'default',
          'text': updatedAt,
          'stretch': false
        },
        {
          'id': `${rowId}#email-status`,
          'type': 'default',
          'text': emailStatus,
          'stretch': false
        },
        {
          'id': `${rowId}#sent-date`,
          'type': 'default',
          'text': emailSentDate,
          'stretch': false
        },
        { 
          'id': `${rowId}#delete-button`,
          'type': 'icon-button',
          'icon': 'trash',
          'onClick': () => proxyAdvisorDeleteClickHandler(proxyAdvisorId)
        }
      ]
    };
  }

  const createTableMiddleItems = () => {
    const middleItems = {
      'header': {
        'buttons': [
          {
            'id': 'client-shareholder-communication-add-proxy-advisor-button',
            'text': 'Add Proxy Advisor',
            'onClick': addProxyAdvisorClickHandler,
            'leftIcon': 'plus'
          }
        ],
        'items': [
          {
            'id': 'client-engagements-shareholder-communication-proxy-advisor-list-header-middle',
            'columns': [
              {
                'text': 'PROXY ADVISOR NAME',
                'isSortable': true,
                'onSortClick': () => sortFunction('name'),
                'orderBy': sortConfig['by'].includes('name') ? sortConfig['order'][sortConfig['by'].indexOf('name')] : 'asc',
                'stretch': true
              },
              {
                'text': 'CUSTOMIZED BY',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'CUSTOMIZED ON',
                'isSortable': true,
                'onSortClick': () => sortFunction(''),
                'orderBy': sortConfig['by'].includes('') ? sortConfig['order'][sortConfig['by'].indexOf('')] : 'asc',
                'stretch': false
              },
              {
                'text': 'EMAIL STATUS',
                'stretch': false
              },
              {
                'text': 'SENT DATE',
                'stretch': false
              },
              {
                'text': 'DELETE',
                'stretch': false
              }
            ]
          }
        ],
        'config': {
          'columnWidths': [
            248, 120, 120, 144, 112, 52
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 0}
          ]
        }
      },
      'body': {
        'items': [],
        'config': {
          'columnWidths': [
            248, 120, 120, 144, 112, 52
          ],
          'columnPaddings': [
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 12, 'right': 24, 'bottom': 12, 'left': 24},
            {'top': 16, 'right': 24, 'bottom': 16, 'left': 0}
          ]
        }
      }
    };
    return middleItems;
  }

  const tableItems = {
    'middle': createTableMiddleItems()
  };

  shareholderCommunicationProxyAdvisorItems.forEach(item => {
    tableItems['middle']['body']['items'].push(
      createShareholderCommunicationProxyAdvisorRow(item)
    );
  });

  return tableItems;
}